import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react'
import { useStore } from '../hooks/useStore';
import Article from './Article';
import FourOhFour from './FourOhFour';
import Home from './Home';
import throttle from 'lodash/throttle'
import { TransitionEl } from '../components/transition';

const ActiveAppPage = observer(() => {
    const {uiStore: {isMobile, header__height}, audioStore: {audio__activeDetails}, routerStore: {initialLoad, routeName, routeTransitioning}, uiStore: {header__isOpen}} = useStore();
    if (isMobile) return <ActiveAppPageMobile/>
    return <ActiveAppPageSlide/>

    let page;
    // put 
    switch(routeName) {
        case "home":
            page = <Home key="abc"/>;
            break;
        case "shop":
            page = <Home key="abc"/>;
            break;
        case "article.view":
            page = <Article/>;
            break;
        case "acrticleOld.view":
            page = <Article/>;
            break;
        default:
            page = <FourOhFour/>;
            break;
    }


    return (
        <div className={`page ${header__isOpen ? "slide" : ""} ${routeTransitioning ? "tr" : ""}`} >
            {page}

            <style jsx>{`
                .slide {
                    transform: translate3d(0, ${header__height - 70}px, 0);
                }
            `}</style>
            
            <style jsx>{`
                .page {
                    background: white;
                    padding-top: 70px;
                    padding-bottom: 50px;
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1) .3s, opacity .4s ease;
                }
            `}</style>
        </div>
    )
});

const ActiveAppPageSlide = observer(() => {
    const {uiStore: {updateArticleScroll, registerPages, footer__showFilters, window, header__isOpen, header__height, homePreviousArticleColor}, routerStore: {initialLoad, movingTo, routeName, current}} = useStore();
    const homePage = useRef(null);
    const articlePage = useRef(null);
    
    useEffect(() => {
        registerPages(homePage, articlePage)
    }, [])

    // console.log(initialLoad);


    return (
        <div className={`app-page-container ${header__isOpen ? "slide" : ""}  ${initialLoad ? "initial" : ''}`}>
            
            <div className={`app-page-container__inner ${(routeName === "article.view") && (movingTo.name !== "home") ? "article" : "home"}`}>
                <TransitionEl/>
                <div 
                    onScroll={throttle(updateArticleScroll, 100)}
                    ref={homePage} 
                    className="sheet-container">
                    <Home/>
                    {/* {routeName === "home" || movingTo.name === "home" ? <Home/> : null} */}
                </div>

                <div 
                    onScroll={throttle(updateArticleScroll, 100)}
                    ref={articlePage} 
                    className="sheet-container">
                    {routeName === "article.view" ? <Article key={current.params.id}/> : null}
                </div>
            </div>


            <style jsx>{`
                .app-page-container {
                    width: ${window.width}px;
                    height: ${window.height}px;                
                }

                .app-page-container__inner {
                    height: ${window.height * 2}px;
                    background: ${footer__showFilters ? "none" : homePreviousArticleColor};
                }

                .slide {
                    transform: translate3d(0, ${header__height - 70}px, 0);
                }
                .article {
                    transform: translateY(-${window.height}px);
                }
            `}</style>

            <style jsx>{`
                .app-page-container {
                    position: fixed;
                    overflow: hidden;
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1) .3s;
                }

                .initial {
                    transition: none;
                }

                .app-page-container__inner {
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1);
                }

                .app-page-container__inner > div {
                    position: relative;
                    height: 50%;
                    overflow: scroll;
                    width: 100%;
                    contain: strict;
                    padding-top: 70px;
                    padding-bottom: 50px;
                }
            `}</style>
        </div>
    )
})



const ActiveAppPageMobile = observer(() => {
    const {uiStore: {registerPages, window}, routerStore: {routeName}, audioStore: {audio__activeDetails}} = useStore();

    const homePage = useRef(null);
    const articlePage = useRef(null);

    useEffect(() => {
        registerPages(homePage, articlePage)
    }, []);

    return (
        <div className="mobile-app-page-container">
            <div className={`mobile-app-page-container__inner flex ${routeName === "article.view" ? "article" : "home"}`}>
                <div 
                    className="sheet-container"
                    ref={homePage} 
                >
                    <Home/>
                </div>

                <div 
                    className="sheet-container"
                    ref={articlePage} 
                >
                    {routeName === "article.view" ? <Article/> : null}
                </div>
            </div>

            <style jsx>{`
                .mobile-app-page-container {
                    width: ${window.width}px;
                    overflow: hidden;
                }

                .mobile-app-page-container__inner {
                    width: 200%;
                    height: ${window.height}px;
                    transition: transform .6s ease;
                }

                .article {
                    transform: translateX(-${window.width}px);
                }

                .mobile-app-page-container__inner > div {
                    height: 100%;
                    overflow: scroll;
                    width: 50%;
                    padding-top: 100px;
                    padding-bottom: ${audio__activeDetails ? "80px" : "50px"};
                }
            `}</style>
        </div>
    )
})

export default ActiveAppPage