import {action, observable,computed, makeObservable} from 'mobx'
import { observer } from 'mobx-react-lite';
import { checkIsMobile } from '../utils/general';
import debounce from 'lodash/debounce'

class UIStore {
    constructor(rootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
        window.addEventListener("resize", debounce(this.handleResize.bind(this), 300))
        document.fonts.ready.then(this.setFontsReady);
    }

    @observable window = {width: window.innerWidth, height: window.innerHeight};
    @observable isMobile = checkIsMobile();
    @observable fontsReady = false;
    @observable scroll__pageY = 0;
    @observable scroll_diff = 0;
    // hasStartedScrollingPage = false;

    @observable showPopUpAd = false;

    headerRef = null;
    @observable header__isOpen = false;
    @observable header__activeView = "info";
    @observable header__filter__inactiveFilters = {};
    @observable header__team__activeMember = null;
    @observable header__height = null;
    header__count = 0;

    @observable footer__showFilters = false;
    @observable footer__showSearch = false;
    @observable footer__showVolumeButtons = false;
    @observable footer__audioVolumeButtons = [
        true,
        true,
        true,
        true,
        true,
    ];

    @observable search__activeFilters = {
        content: true,
        author: true,
        title: true
    };

    @observable search__searchInput = null;
    @observable search__query = "";

    @observable imageModal__isOpen = false;
    @observable imageModal__carousel__activeIdx = 0;

    @observable homeArticleTransitionColor = null;
    @observable homePreviousArticleColor = null;

    @observable email__isSubmitted = false;
    

    homePage = null;
    articlePage = null;
    
    // @computed get homeArticleTransitionColor() {
    //     const {routerStore, dataStore} = this.rootStore;
        
    //     console.log("find false: ",routerStore.routeTransitioning, routerStore.movingTo, routerStore.current)
    //     if (routerStore.routeTransitioning && routerStore.movingTo && routerStore.current && routerStore.current.name === "home" && routerStore.movingTo.name === "article.view" && routerStore.movingTo.params.id !== "404") {
    //         console.log('not going')
    //         const articleData = dataStore.articleListDataBySlug[routerStore.movingTo.params.id];
            
    //         return articleData.category.color;
    //     }

    //     return undefined
    // }



    @computed get hideScrollElements() {
        return false
        // if (this.scroll__pageY > 68) {
        //     console.log(this.scroll__diff)
        //     if (Math.abs(this.scroll__diff > 68)) {
        //         this.scroll__diff = 0;
        //         return true
        //     }
        // }

        // return false
        return this.scroll__pageY > 68 && this.scroll__diff > 0;
    }

    @computed get readButtonActive() {
        const {routerStore, audioStore} = this.rootStore;

        return audioStore.audio__activeDetails && routerStore.current && routerStore.current.name === "article.view" && audioStore.audio__activeDetails.slug === routerStore.current.params.id;
    }

    @computed get imagesButtonActive() {
        return false
    }

    @action.bound setFontsReady() {
        this.fontsReady = true;
    }

    @action.bound updateArticleScroll(e) {
        // if (this.rootStore.routerStore.routeName !== "article.view") return 
        // console.log("updating scroll", e)
        // const scrollY = window.scrollY;
        const scrollY = e.target.scrollTop;
        


        this.scroll__diff = (scrollY - this.scroll__pageY);
        this.scroll__pageY = scrollY;

        // this.checkOpenPopup();
        this.checkCloseHeader();


    }

    // @action.bound checkOpenPopup() {
    //     if (!this.hasStartedScrollingPage) {
    //         this.hasStartedScrollingPage = true;
    //         this.triggerPopUpAd();
    //     }
    // }
    
    @action.bound checkCloseHeader() {
        if (this.header__isOpen) {
            this.header__count += Math.abs(this.scroll__diff);

            if (this.header__count >= 100) {
                
                this.closeHeader();
                this.header__count = 0;
            }
        }
    }

    @action.bound setHeaderRef(headerRef) {
        this.headerRef = headerRef;
    }

    @action.bound setHeaderHeight() {
        if (!this.headerRef) return 
        const rect = this.headerRef.current.getBoundingClientRect();

        this.header__height = rect.height;
    }

    @action.bound toggleHeader() {
        this.header__isOpen = !this.header__isOpen;
        this.hidePopUpAd();

        if (this.header__isOpen) {
            this.rootStore.audioStore.playSound("click-on")
        } else {
            this.rootStore.audioStore.playSound("click-off")
        }
    }

    @action.bound closeHeader() {
        this.header__isOpen = false;
    }

    @action.bound setActiveHeaderView(view) {
        this.header__activeView = view;
    }

    @action.bound setActiveTeamMember(member) {
        this.header__team__activeMember = member;
    }

    @action.bound toggleHeaderFilter(key, state) {
        this.header__filter__inactiveFilters[key] = state;

        if (this.header__filter__inactiveFilters[key]) {
            this.rootStore.audioStore.playSound("filter-on")
        } else {
            this.rootStore.audioStore.playSound("filter-off")
        }
    }
    
    @action.bound toggleFooterFilters() {
        this.footer__showFilters = !this.footer__showFilters;
        this.hideSearchBar();
        this.hidePopUpAd();
        if (this.footer__showFilters) {
            this.rootStore.audioStore.playSound("show-filters")
        } else {
            this.rootStore.audioStore.playSound("hide-filters")
        }
    }

    @action.bound hideFooterFilters() {
        this.footer__showFilters = false;

        // this.rootStore.audioStore.playSound("hide-filters")
    }

    @action.bound showVolumeButtons() {
        this.footer__showVolumeButtons = true;
    }

    @action.bound hideVolumeButtons() {
        this.footer__showVolumeButtons = false;
    }
    
    @action.bound updateVolumeButtons(volumeIndex) {
        for (var i = 0; i < this.footer__audioVolumeButtons.length; i++) {
            this.footer__audioVolumeButtons[i] = i <= volumeIndex ? true : false
        }
    }


    @action getWindowDimensions() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    }

    @action checkIsMobile() {
        this.isMobile = checkIsMobile();
    }

    @action.bound openImageModal(startIndex) {
        // const {slug} = articleData;

        // this.imageModal__activeArticle = slug;
        this.imageModal__isOpen = true;
        this.imageModal__carousel__activeIdx = startIndex;
        // this.rootStore.dataStore.fetchArticleImages(slug);
    }
    
    @action.bound setImageModalIdx(idx) {
        this.imageModal__carousel__activeIdx = idx;
    }

    @action.bound closeImageModal() {
        this.imageModal__isOpen = false;
    }

    @action.bound incrementImageModalCarousel() {
        this.imageModal__carousel__activeIdx++;
    }

    @action.bound decrementImageModalCarousel() {
        this.imageModal__carousel__activeIdx--;
    }

    handleResize() {
        this.getWindowDimensions();
        this.checkIsMobile();
    }

    @action.bound clearRouteVariables() {
        this.closeHeader();
        this.hideFooterFilters();
        this.hideSearchBar();
        this.closeImageModal();
        this.hidePopUpAd();
        this.scroll__diff = 0;
        this.scroll__pageY = 0;
        this.header__count = 0;

        this.rootStore.dataStore.clearArticleList();
    }

    @action.bound registerPages(homePage, articlePage) {
        this.homePage = homePage;
        this.articlePage = articlePage;
    }

    @action.bound setTransitionColor(color) {
        this.homePreviousArticleColor = this.homeArticleTransitionColor;
        this.homeArticleTransitionColor = color;
    }

    @action.bound scrollToTop() {
        if (!this.homePage || !this.articlePage) return
        // console.log('running scroll to top');
        // window.scrollTo(0, 0); // values are x,y-offset
        // console.log(this.homePage)
        this.homePage.current.scrollTop = 0;
        this.articlePage.current.scrollTop = 0;
        // console.log("after")
    }

    @action.bound scrollHomeTopSmooth() {
        if (!this.homePage || !this.articlePage) return
        // console.log('running scroll to top');
        // window.scrollTo(0, 0); // values are x,y-offset
        // console.log(this.homePage)
        this.homePage.current.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        // console.log("after")
    }

    @action.bound setEmailSubmitted() {
        this.email__isSubmitted = true;
    }

    @action.bound initPopUpAdCountdown() {
        setTimeout(this.triggerPopUpAd, 1200);
    }

    @action.bound triggerPopUpAd(){
        this.showPopUpAd = true;
    }

    @action.bound hidePopUpAd() {
        if (this.showPopUpAd) {
            this.rootStore.audioStore.playSound("filter-off")
        }
        this.showPopUpAd = false;
    }

    @action.bound toggleSearchBar() {
        this.footer__showSearch = !this.footer__showSearch;
        this.hidePopUpAd();

        if (this.footer__showSearch) {
            this.rootStore.audioStore.playSound("show-filters")
        } else {
            this.rootStore.audioStore.playSound("hide-filters")
        }


        this.hideFooterFilters();
        this.closeHeader()
    }


    @action.bound hideSearchBar() {
        this.footer__showSearch = false;
    }
    
    @action.bound registerSearchInput(el) {
        this.search__searchInput = el;
    }

    @action.bound focusSearchInput() {
        // alert("foc")
        this.search__searchInput.focus();
    }

    @action.bound blurSearchInput() {
        this.search__searchInput.blur();
    }

    @action.bound clearSearchQuery() {
        this.search__query = "";
        this.rootStore.dataStore.clearSearchResults();
    }

    @computed get canSearch() {
        return this.search__query && this.search__query.length > 2;
    }

    @action.bound updateSearchQuery(e) {
        const val = e.target.value;
        this.search__query = val;
    }   

    @action.bound toggleSearchFilter(filter) {
        this.search__activeFilters[filter] = !this.search__activeFilters[filter];
   
        if (this.search__activeFilters[filter]) {
            this.rootStore.audioStore.playSound("filter-on")
        } else {
            this.rootStore.audioStore.playSound("filter-off")
        }
    }

    @action.bound activateAllSeachFilters() {
        let that = this;
        Object.keys(this.search__activeFilters).forEach(key => {
            that.search__activeFilters[key] = true;
        })
    }

}

export default UIStore;