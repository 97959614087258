import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '../../../hooks/useStore'
import SearchCloseIcon from './SearchCloseIcon';
import SearchFilters from './SearchFilters';

const SearchBar = observer(() => {
    
    return (
        <div>
            <div className="flex">
                <SearchStr/>
                <SearchButton/>
            </div>


            <style jsx>{`
                div {
                    height: 50px;
                    background: #fff;
                    z-index: 10000;
                }

                @media (max-width: 769px), (pointer: coarse) {
                    div {
                        height: 50px;
                    }
                }
            `}</style>
        </div>
    )
});

const SearchInput = observer(() => {
    const {uiStore: {updateSearchQuery, footer__showSearch, search__query, search__searchInput, registerSearchInput}, dataStore: {dataReady: {searchResults}}} = useStore();
    const ref = useRef(null);
    useEffect(() => {   
        if (!search__searchInput) {
            registerSearchInput(ref.current);
        }
        if (footer__showSearch || search__query === "") {
            ref.current.focus();
        }
    }, [search__query, footer__showSearch]);

    const canType = searchResults && (search__query === searchResults)


    return (
        <div className={`search-input ${canType ? "frozen" : ""}`}>
            <input 
                ref={ref} 
                onChange={updateSearchQuery} 
                value={search__query} type="text" 
                placeholder='Start searching for...' 
            
            />
            <div className="shim"></div>
            <style jsx>{`
                .search-input, input {
                    background: transparent;
                    width: 100%;
                    position: absolute;
                    top: 0px;
                    height: 50px;
                    padding: 0 14px;

                    margin: 0;
                    display: block;
                    left: 0;
                    z-index: 10;
                    outline: none;
                    border: none;
                }

                div, input {
                    background: #feff21;
                    // background: all .18s ease;
                }


                .frozen div, .frozen input {
                    background: var(--color--active-gray);
                }

                .frozen * {
                    color: white;
                }

                input {
                    // padding-left: 14px;
                    padding-right:0;
                }
                .shim {
                    position: absolute;
                    height: 100%;
                    width: 8px;
                    top: 0;
                    right: -8px;
                }

                .search-input, input {
                    // font-size: var(--font-size-s);
                    font-size: var(--font-size-m);

                }

                @media (max-width: 769px), (pointer: coarse) {
                    .search-input, input {
                        height: 50px;
                    }
                }

            `}</style>
        </div>
    )
});

const SearchStr = observer(() => {
    const {uiStore: {search__query}} = useStore();
    return (
        <div className={search__query ? "" : "empty"}>
            <SearchInput/>
            
            <span>
                {search__query} 
            </span>


            <style jsx>{`
                div {
                    display: inline-block;
                    // min-width: 230px;
                    padding-left: 8px;
                    padding-right: 12px;
                    position: relative;
                    color: black;
                    font-size: var(--font-size-m);
                }

                .empty {
                    min-width: 230px;
                }
                span {
                    opacity: 0;
                }

                @media (max-width: 769px), (pointer: coarse) {
                    div {
                        min-width: 50px;
                        width: auto;
                        flex: 1;
                    }
                }
            `}</style>
        </div>
    )
})



const SearchButton = observer(() => {
    const {uiStore: {clearSearchQuery, search__query, canSearch}, dataStore: {performSearch, dataReady: {searchResults}}} = useStore();
    const canType = searchResults && (search__query === searchResults)
    return (
        <div className={`${canSearch ? '' : 'disabled'} flex--center ${canType ? 'clear' : 'perform'}`} onClick={canType ? clearSearchQuery : performSearch}>
            {canType ? <SearchCloseIcon/> : <SearchArrowIcon/>}
            <style jsx>{`
                div {
                    // display: inline-block;
                    background: black;
                    height: 50px;
                    width: 50px;
                    cursor: pointer;
                    position: relative;
                    z-index: 100;
                    margin-left: 8px;
                    // transition: background .18s ease;
                }

                .disabled {
                    pointer-events: none;
                }

                .disabled :global(svg) {
                    opacity: .2;
                }

                .perform {
                    background: var(--color--gray-light-hover);
                    
                }

                .clear {
                    background: black;
                }

                @media (max-width: 769px), (pointer: coarse) {
                    div {
                        height: 50px;
                        width: 50px;
                    }
                }
            `}</style>
        </div>
    )
});

const SearchArrowIcon = () => {
    return (
        <svg  viewBox="0 0 12.81 10.54">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_2-2" data-name="Layer 2">
                    <path class="cls-1" d="M6.91,9.05,9.74,6.16H.88A.88.88,0,0,1,0,5.28v0a.88.88,0,0,1,.88-.88H9.74L6.91,1.49a.89.89,0,0,1,0-1.23h0a.88.88,0,0,1,1.25,0l4.39,4.39a.86.86,0,0,1,0,1.24L8.16,10.28a.88.88,0,0,1-1.25,0h0A.89.89,0,0,1,6.91,9.05Z"/>
                </g>
                </g>

                <style jsx>{`
                    svg {
                        height: 14px;
                        transition: opacity .18s ease;
                    }
                    path {
                        fill: var(--color--gray);
                    }
                `}</style>
        </svg>
    )
}


export default SearchBar