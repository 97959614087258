const API_URL = "https://hii-magazine-kiosk.myshopify.com/api/graphql.json";
const STOREFRONT_ACCESS_TOKEN = "97ece57ef602fcbe758534134213e387";

export const graphqlApiCall = async (query) => {
    try {

        const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/graphql',
              'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN
            },
            "body": query
          });

        const json = await res.json();

        return json
    } catch(e) {
        console.error(e);
    }
}