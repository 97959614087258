import { has } from 'lodash';
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../../hooks/useStore'

const AudioPlayerPlayPauseButton = observer(({data}) => {
    const {audioStore: {playArticle, togglePausePlay, audio__activeDetails, audio__isPlaying}} = useStore()
    let isPlayingThis = false;
    const hasData = !!data.audioFile;

    if (audio__activeDetails && data) {
        isPlayingThis = audio__activeDetails.title === data.title;
    }
    
    const onClick = isPlayingThis ?  togglePausePlay : () => playArticle(data);

    return (
        <div 
            className={`btn play-pause flex--center ${!hasData ? "disabled" : ""}`}
            onClick={!hasData ? null : onClick}>
            {audio__isPlaying && isPlayingThis ? <PauseIcon/> : <PlayIcon/>}
            <style jsx>{`
                .btn {
                    position: relative;
                    height: 50%;
                    width: 100%;
                    transition: background .18s ease, opacity .18s ease;
                    cursor: pointer;
                }
                
                .btn:hover {
                    background: var(--color--active-gray);
                }

                .btn.play-pause:hover :global(path), .btn.play-pause:hover :global(rect) {
                    fill: white;
                }

                .no-audio-file {

                }

                .disabled {
                    opacity: .15;
                    pointer-events: none;
                }

                .play-icon {
                    height: 14px;
                }

                @media (max-width: 786px), (pointer: coarse) {
                    .btn:hover {
                        background: none;
                    }

                    .btn.play-pause:hover :global(path), .btn.play-pause:hover :global(rect) {
                        fill: var(--color--gray);
                    }
                }
            `}</style>
        </div>
    )
});

const PlayIcon = React.memo(() => {
    return (
        <svg viewBox="0 0 14.08 15.91">
                <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                <path className="cls-1" d="M0,8V1.14a1.14,1.14,0,0,1,1.71-1l5.9,3.41L13.52,7a1.14,1.14,0,0,1,0,2L7.61,12.35l-5.9,3.41a1.14,1.14,0,0,1-1.71-1Z"/>
                </g>
                </g>
                
                <style jsx>{`
                    svg {
                        height: 14px;
                    }
                    .cls-1 {
                        fill: var(--color--gray);
                    }
                `}</style>
            </svg>
    )
})

const PauseIcon = React.memo(() => {
    return (
        <svg viewBox="0 0 20 26">
            <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
            <rect className="cls-1" width="7" height="26" rx="1.72"/>
            <rect className="cls-1" x="13" width="7" height="26" rx="1.72"/>
            </g>
            </g>
            <style jsx>{`
                svg {
                    height: 14px;
                }
                .cls-1 {
                    fill: var(--color--gray);
                }
            `}</style>
        </svg>
    )
})

export default AudioPlayerPlayPauseButton