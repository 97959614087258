import { useStore } from "../../../hooks/useStore";



const Link = (props) => {
	const {to, params, options, external} = props;
	const {routerStore} = useStore();
	// return null
    const href = props.external ? to : routerStore.router.buildPath(to, params);
	if (href === null) {
		console.error("<Link> Couldn't make URL for", to, params);
    }
    
	const navigate = (e) => {
		e.preventDefault();
        routerStore.navigate(to, params, options);   
	}

	if (external) return <a href={href} target="_blank" rel="noreferrer" className={props.className}>{props.children}</a>
	return (
		<a 
			href={href} 
			className={props.className} 
			onClick={navigate}>
			{props.children}
		</a>
	)
}

export default Link