import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { useStore } from '../../../hooks/useStore'
import HeaderAboutView from './HeaderAboutView';
import HeaderTeamView from './HeaderTeamView';

const HeaderActiveView = observer(() => {
    let {uiStore: {header__activeView, setHeaderHeight}} = useStore();
    useEffect(setHeaderHeight);
    
    let view = null;
    switch(header__activeView) {
        case "info":
            view = <HeaderAboutView/>;
            break;
        case "masthead":
            view = <HeaderTeamView/>;
            break;
        default:
            break;
        
    }


    return (
        <div>
            {view}
            <style jsx>{`
                div {
                    width: 500px;
                    contain: content;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        width: 100%;
                        min-height: 314px;
                    }
                }
            `}</style>
        </div>
    )
})


export default HeaderActiveView