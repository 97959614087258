import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../hooks/useStore';

const ImageModalCarouselControls = observer(({data}) => {
    return (
        <div className="image-modal-controls flex">
            <CloseButton/>
            <ImageIndexButtons data={data}/>
            {/* <DecrementButton data={data}/>
            <IncrementButton data={data}/> */}

            <style jsx>{`
                .image-modal-controls {
                    position: absolute;
                    z-index: 100;
                    top: 0;
                    left: 0;
                    transform: translate3d(0, -50px, 0);
                    animation: slide-in .6s cubic-bezier(0.77, 0, 0.175, 1);
                    animation-delay: .25s;
                    animation-fill-mode: forwards;

                    user-select: none;
                }

                @keyframes slide-in {
                    from {
                        transform: translate3d(0, -50px, 0);
                    }

                    to {
                        transform: translate3d(0, 0px, 0);
                    }
                }
            `}</style>
        </div>
    )
});

const ImageIndexButtons = observer(({data}) => {
    const {uiStore: {imageModal__carousel__activeIdx}} = useStore();
    if (!data.images || !data.images.length) return null;

    return (
        <div className="flex">

            {data.images.map((image, idx) => {
                return <IndexButton active={idx === imageModal__carousel__activeIdx} idx={idx} />
            })}
        </div>
    )
});

const IndexButton = React.memo(({active, idx}) => {
    const {uiStore: {setImageModalIdx}} = useStore();

    return(
        <div 
            onClick={() => setImageModalIdx(idx)}
            className={active ? "button flex--center active" : "button flex--center"}
        >
            <div className="circle"></div>
            <style jsx>{`
                .button {
                    background: white;
                    cursor: pointer;
                    width: 50px;
                    height: 50px;
                }

                .circle {
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                    border: 2px solid rgba(0,0,0, .5);
                    cursor: pointer;
                }

                .active {
                    background: var(--color--active-gray);
                }

                .active .circle {
                    background: rgba(0,0,0,.5);
                    border: 2px solid transparent;
                }
            `}</style>
        </div>
    )
})

const CloseButton = observer(() => {
    const {uiStore: {closeImageModal}, routerStore: {current}, dataStore: {data: {articleView}, dataReady: {articleView: dataReady}}} = useStore();
    let color, secondaryColor;

    if (!current ||  current.name !== "article.view") {
        color = "var(--color--active-gray)";
        
    } else {
        const slug = current.params.id;
        const project = articleView[slug];
        color = project.category.color;
        secondaryColor = project.category.secondaryColor;
    }


    return (
        <div 
            onClick={closeImageModal}
            className={"btn flex--center"}
        >

            <svg  viewBox="0 0 22.13 22.13">
                <title>x</title>
                <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_2-2" data-name="Layer 2">
                <line className="cls-1" x1="1.07" y1="1.07" x2="21.07" y2="21.07"/>
                <line className="cls-1" x1="21.07" y1="1.07" x2="1.07" y2="21.07"/>
                </g>
                </g>
            </svg>


            <style jsx>{`
                    .btn {
                        position: relative;
                        height: 50px;
                        width: 50px;
                        background: ${color};
                        transition: background .18s ease, opacity .18s ease;
                        cursor: pointer;
                    }
                    
                    .btn:hover {
                        background: ${secondaryColor || color};
                    }

                    .cls-1{
                        fill: white; 
                        stroke: white;
                        fill:none;stroke:white;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.13px;
                        stroke: rgba(0,0,0,.5);
                        // stroke: var(--color--active-gray);
                    }

                    .cls-2{fill: white;stroke: white;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.03px;}

                    .disabled {
                        pointer-events: none;
                    }

                    .active{
                        background: var(--color--gray);
                    }



                    svg {
                        height: 18px;
                    }

            `}</style>
        </div>
    )
})


// const IncrementButton = observer(({data}) => {
//     const {uiStore: {incrementImageModalCarousel, imageModal__carousel__activeIdx}} = useStore()
//     if (!data.images || !data.images.length) return null;

//     const disabled = imageModal__carousel__activeIdx >= data.images.length - 1;

//     return (
//         <div 
//             className={disabled ? "disabled flex--center" : "flex--center"}
//             onClick={incrementImageModalCarousel}>
            
//             <img src="/images/arrow-right.svg" alt="" />
            
//             <style jsx>{`
//                 div {
//                     cursor: pointer;
//                     transition: background .18s ease, opacity .18s ease;
//                     height: 50px;
//                     width: 50px;
//                     background: var(--color--gray-light);
//                 }

//                 div:hover {
//                     background: var(--color--gray-light-hover);
//                 }

//                 img {
//                     height: 15px;
//                 }

//                 .disabled {
//                     opacity: .3;
//                     pointer-events: none;
//                 }
//             `}</style>
//         </div>
//     )
// })

// const DecrementButton = observer(({data}) => {
//     const {uiStore: {decrementImageModalCarousel, imageModal__carousel__activeIdx}} = useStore();
//     if (!data.images || !data.images.length) return null;

//     const disabled = imageModal__carousel__activeIdx <= 0;
//     return (
//         <div 
//             className={disabled ? "disabled flex--center" : " flex--center"}
//             onClick={decrementImageModalCarousel}>
//             <img src="/images/arrow-left.svg" alt="" />


//             <style jsx>{`
//                 div {
//                     cursor: pointer;
//                     transition: background .18s ease, opacity .18s ease;
//                     height: 50px;
//                     width: 50px;
//                     background: var(--color--gray-light);
//                 }


//                 div:hover {
//                     background: var(--color--gray-light-hover);
//                 }


//                 img {
//                     height: 15px;
//                 }


//                 .disabled {
//                     opacity: .3;
//                     pointer-events: none;
//                 }
//             `}</style>
//         </div>
//     )
// })
export default ImageModalCarouselControls