import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../../hooks/useStore';


const AudioPlayerVolumeInterface = observer(() => {
    const {uiStore: {showVolumeButtons, hideVolumeButtons, isMobile,  footer__audioVolumeButtons, footer__showVolumeButtons}} = useStore();
    if (isMobile) return null
    
    return (
        <div 
            onMouseEnter={showVolumeButtons}
            onMouseLeave={hideVolumeButtons}
            className={footer__showVolumeButtons ? "active flex" : "flex"}>
            {footer__audioVolumeButtons.map((state, idx) => {
                return <VolumeToggleButton active={state} idx={idx}/>
            })}

            <style jsx>{`
                div {
                    height: 250px;
                    width: 50px;
                    flex-direction: column-reverse;

                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 10;
                    transform: translateY(-250px) translateX(50px);
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1);
                    transition-delay: .3s;
                }

                .active {
                    transform: translateY(-250px);
                }


            `}</style>
        </div>
    )
});

const VolumeToggleButton = observer(({active, idx}) => {
    const {audioStore: {updateVolume}} = useStore();

    return (
        <div 
            onClick={() => updateVolume(idx)}
            className={active ? "button flex--center active" : "button flex--center"}
        >
            <div className="circle"></div>

            <style jsx>{`
                .button {
                    flex: 1;
                    background: white;
                    cursor: pointer;
                }

                .circle {
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                    border: 2px solid rgba(0,0,0, .5);
                    cursor: pointer;
                }

                .active {
                    background: var(--color--active-gray);
                }

                .active .circle {
                    background: rgba(0,0,0,.5);
                    border: 2px solid transparent;
                }
            `}</style>
        </div>
    )
})

export default AudioPlayerVolumeInterface