import React from 'react'
import { Article } from '../../../pages'
import { Link } from '../../general/link'
import ArticlePageHeader from './ArticlePageHeader'

const ArticleNextLink = ({data}) => {
    if (!data.nextArticle) return null
    
    return (
        <Link to="article.view" params={{id: data.nextArticle.slug}}> 
            <ArticlePageHeader data={data.nextArticle}/>
        </Link>
    )
}

export default ArticleNextLink