import { observer } from 'mobx-react-lite'
import React from 'react'
import { ArticleAuxContentImages } from '.';
import { useStore } from '../../../hooks/useStore';
const ArticleAuxContent = observer(({data}) => {
    const {uiStore: {isMobile}} = useStore();
    if (isMobile && !data.pinnedContent) return null
     let pinnedContents;
     if (data.pinnedContent) {
        pinnedContents = data.pinnedContent.map((item, idx) => {
            if (item._type === "file") return <AudioEl key={`a-el${idx}`}data={item}/>

            return <EmbeddedContent data={item}/>
        });
     } 


    return (
        <div className="aux-content">
            <div className="aux-content__inner">     
                {pinnedContents}
                <ArticleAuxContentImages data={data}/>
                
            </div>
        
            <style jsx>{`
                .aux-content {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 360px;
                    // width: 25%;
                    // background: red;
                    height: 100%;
                    padding: 40px 0px;
                }


                .aux-content__inner {
                    position: sticky;
                    text-align: center;
                    top: 110px;
                    top: 40px;
                    padding: 0 20px;
                }

                .aux-content__inner :global(> *) {
                    margin-bottom: 20px;
                }

                .aux-content__inner :global(iframe) {
                    max-width: 100%;
                    display: block;
                    margin: 0 auto;
                }




                @media (pointer: coarse) {
                    .aux-content {
                        position: relative;
                        width: 100%;
                    }

                    .aux-content__inner {
                        position: static;
                        padding: 0;
                    }

                    .aux-content__inner :global(audio) {
                        display: block;
                        width: 100%;
                    }
                }
            `}</style>
        </div>
    )
});

const AudioEl = ({data}) => {
    return (
        <audio src={data.asset.url} controls></audio>
    )
}


const EmbeddedContent = ({data}) => {
    return (
        <div dangerouslySetInnerHTML={{__html: data.content}}>     


        </div>
    )
}

export default ArticleAuxContent