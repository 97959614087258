import { observer } from 'mobx-react-lite';
import React from 'react'
import { ArticleSubHeading, ArticleAuxContent, ArticleNextLink, ArticlePageBody, ArticlePageHeader, ArticleInfoSection } from '../components/_pages/article'
import { useStore } from '../hooks/useStore'

const Article = observer(() => {
    const {uiStore: {isMobile}, routerStore: {current, showLoader}, dataStore: {data: {articleView}, dataReady: {articleView: dataReady}}} = useStore();
    const slug = current.params.id;

    if (!dataReady[slug] || (isMobile && showLoader) || current.params.id === "404") return null;
    const project = articleView[slug];
    
    return (
        <div className="article">
            <ArticlePageHeader data={project}/>
            
            <div className="article__contents">
                <ArticleInfoSection data={project}/>
                <ArticleAuxContent data={project}/>
                <ArticleSubHeading data={project}/>
                <ArticlePageBody data={project}/>
            </div>

            {true ? <ArticleNextLink data={project}/> : null}

            

            <style jsx>{`
                .article {
                    background: #f5f4ee;
                }

                .article__contents {
                    position: relative;
                    padding: 40px 0;
                    min-height: 100vh;

                    animation: fade-in .4s ease;
                    opacity: 0;
                    animation-fill-mode: forwards;
                    animation-delay: .05s;
                }

                @media (pointer: coarse) {
                    .article__contents {
                        padding: 20px;
                    }

                    .article {

                    }
                }

                @keyframes fade-in {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }

            `}</style>
        </div>
    )
})

export default Article