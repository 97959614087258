import { observer } from 'mobx-react-lite'
import React from 'react'
import { CartSubtotal } from '.'
import { useStore } from '../../../hooks/useStore'
import CheckoutButton from './CheckoutButton'
import get from 'lodash/get'

const CartBase = observer(() => {
    const {cartStore: {showCartContents, checkout}, uiStore: {isMobile}} = useStore();
    const lineItems = get(checkout, "lineItems.edges", []);

    const dir = isMobile ? -2 : 1;

    const transformStyles = {
        transform: `translate3d(0, ${lineItems.length * 25 * dir}px, 0)`
    };

    return (
        <div
            className={`${showCartContents ? "" : "closed"}`}
            style={transformStyles}
        >
            <CartSubtotal/>
            <CheckoutButton/>

            <style jsx>{`
                div {
                    position: absolute;
                    top: 0;
                    z-index: 1;
                    width: 100%;
                    height: 50px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1) .2s;
                    cursor: pointer;
                    user-select: none;
                }

                .closed {
                    transform: translate3d(0,0,0) !important;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        top: auto;
                        bottom: 0;
                    }
                }

            `}</style>
        </div>
    )
})

export default CartBase