import React from 'react'

const SearchCloseIcon = ({height = 14}) => {
    return (
        <svg viewBox="0 0 12 12">
                    <line className="cls-1" x1="1" y1="1" x2="11" y2="11"/>
                    <line className="cls-1" x1="11" y1="1" x2="1" y2="11"/>
            <style jsx>{`
                svg {
                    height: ${height}px;
                }
                .cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;}
            `}</style>
        </svg>
    )
}

export default SearchCloseIcon