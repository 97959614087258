import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { useStore } from '../../../hooks/useStore'
import { HomeArticleColorBlock } from '../home';

const ShopRelatedArticlesSection = observer(() => {
    const {uiStore: {isMobile}, dataStore: {data: {articleListPagination}, dataReady: {articleListPagination: dataReady}}} = useStore();
    if (!dataReady || isMobile) return null
    const blocks = articleListPagination[0].slice(0,3).map((article, idx) => {
        return <HomeArticleColorBlock data={article} size={1}/>
    });


    return (
        <div className="related-articles-section">
            <div className="header flex--center-y">
                <h6 className="text-s">Latest Articles</h6>
            </div>
            {blocks}

            
            <style jsx>{`
                .related-articles-section :global(.block) {
                    display: block;
                }

                .header {
                    height: 50px;
                    padding: 0 12px;
                }
            `}</style>
        </div>
    )
})


export default ShopRelatedArticlesSection