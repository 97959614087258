import SanityBlockContent from '@sanity/block-content-to-react';
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import { textSerializers } from '../../../utils/sanity/text-serializer';
import { Link } from '../link';

const HeaderTeamView = observer(() => {
    const {dataStore: {dataReady: {general: dataReady}}} = useStore();
    if (!dataReady) return null;


    return <Masthead/>
    
});

const Masthead = observer(() => {
    const {uiStore: {isMobile}, dataStore: {data: {general}}} = useStore();
    return (
        <div className={`member-list ${isMobile ? "mobile" : ""}`}>
            {general.masthead.map((item, idx) => {
                return <MastheadItem data={item} key={`mh-${idx}`}/>
            })}

            {/* <style jsx>{`
                div {
                    flex: 1;
                }
                
                .member-list {
                    width: 50%;
                    
                }
                
                .section {
                    padding: 0 12px;
                }


                div > h3 {
                    margin: 4px 0;
                }

                .mobile {
                    display: block;
                    width: 100%;
                }

                .mobile .section {
                    padding: 0;
                }
            `}</style> */}
        </div>
    )
})

const MastheadItem = ({data}) => {
    return (
        <div className='masthead-item flex'>
            {data.url ? <a href={data.url} target="_blank"><h6 className="text-s">{data.name} ↗</h6></a>  : <h6 className="text-s">{data.name}</h6>}
            <h6 className="text-s">{data.credit}</h6>
            
            <style jsx>{`
                .masthead-item {
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom 12px;
                }

                a {
                    color: var(--color--gray);
                    transition: transform .12s ease;
                    text-decoration: underline;
                }

                h6 {
                    color: var(--color--gray);
                }
                
                a:hover {
                    transform: translateX(2px);
                }
            `}</style>
        </div>
    )
}

const TeamMemberLink = observer(({member}) => {
    return (
        <h5 className="link  text-s">
            {
                member.website ? 
                <a href={member.website} target="_blank">
                    {member.name}
                </a>
                :
                <span>{member.name}</span>
            }

               

                <style jsx>{`
                .link {
                    margin-bottom: 12px;
                    cursor: pointer;
                    transition: color .15s ease, transform .12s ease;

                }

                .link:last-of-type {
                    margin-bottom: 0;
                }

                .link:hover {
                    transform: translateX(2px);
                }

                h5 {
                    color: var(--color--gray);
                }
            `}</style>
            </h5>

    )
})


const TeamMemberNameAndBio = ({member}) => {
    return (
        <div>
            <h5 className="text-s">{member.name}</h5>

            <style jsx>{`
                div {
                    margin: 8px 0;
                }
                h5 {
                    margin-bottom: 2px;
                }
            `}</style>

        </div>
    )
}

export default HeaderTeamView