import React from 'react'
import { Cart } from '../components/cart';
import { ShopListItems } from '../components/_pages/shop';
import { useStore } from '../hooks/useStore'
import throttle from 'lodash/throttle'
import { observer } from 'mobx-react-lite';

const Shop = observer(() => {
    const {cartStore: {closeCart}, uiStore: {window: {height}}} = useStore();

    return (
        <div onScroll={throttle(closeCart, 100)}>
            <Cart/>
            <ShopListItems/>
            <style jsx>{`
                div {
                    height: ${height - 50 - 70}px;
                }
            `}</style>

            <style jsx>{`
                div {
                    position: relative;
                    background: var(--color--gray-lightest);
                    overflow: scroll;
                    width: 100%;
                }
                
                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        padding-bottom: 50px;
                    }
                }
             `}</style>
        </div>
    )
})

export default Shop