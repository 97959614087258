import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import { Link } from '../link'


const HeaderTitleSection = observer(() => {
    const {dataStore: {data: {general}, dataReady: {general: dataReady}}} = useStore();

    return (
        <div className="title-section">
            <Link to="home">
                <h6 className="text-s">Hii Magazine</h6>
            </Link>

            <div className="aux-links">
                <Link to={`mailto:hii@hii-mag.com`} external>
                    <h6 className="text-s">
                        hii@hii-mag.com
                    </h6>
                </Link>
                <Link to={`https://instagram.com/${general.instagramHandle}`} external>
                    <h6 className="text-s ig">
                        @{general.instagramHandle}
                    </h6>
                </Link>
            </div>



            <style jsx>{`
                .title-section {
                    width: 250px;
                    position: relative;
                    contain: strict;
                }
                
                .aux-links {
                    position: absolute;
                    bottom: 20px;
                }

                h6 {
                    transition: transform .12s ease;
                    margin-bottom: 4px;
                    user-select: none;
                }

                .ig {
                    margin-bottom: 0;
                }

                h6:hover {
                    transform: translateX(2px);
                }

                @media (pointer: coarse), (max-width: 769px) {
                    .aux-links {
                        position: static;
                    }
                }
            `}</style>
        </div>
    )
})

export default HeaderTitleSection