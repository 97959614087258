import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../hooks/useStore'
import { CartBase, CartLineItems } from './subcomponents';

const Cart = observer(() => {
    const {routerStore: {onShopPage}, cartStore: {checkout}, dataStore: {dataReady: {products: productDataReady}}} = useStore();
    if (!checkout || !productDataReady || !onShopPage) return null

    return (
        <div className="cart">
            <CartLineItems/>
            <CartBase/>


            <style jsx>{`
                div {
                    position: fixed;
                    z-index: 10;
                    top: 100px;
                    right: 0;
                    width: 290px;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        // top: 280px;
                        // top: 310px;
                        top: auto;
                        bottom: 0;
                        width: 100%;
                    }
                }
            `}</style>
        </div>
    )
})



export default Cart