import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../../hooks/useStore';
import { Link } from '../../link';
import get from 'lodash/get'

const AudioPlayerLinkButtons = React.memo(() => {
    return (
        <div className="audio-player__links flex--center-y">
            <FilterHomeButtonSlider/>
            <ShopButton/>
            <ReadButton/>
            <SearchButton/>

            <style jsx>{`
                .audio-player__links {
                    height: 100%;
                    width: 200px;
                }

                .audio-player__links > :global(div) {
                    height: 100%;
                    flex: 1;
                }

                .audio-player__links > :global(a) {
                    height: 100%;
                    flex: 1;
                }
            `}</style>
        </div>
    )
});

const ShopButton = observer(() => {
    const {routerStore: {routeName}} = useStore();

    return (
        // <Link to="shop">
        <a href="https://hii-magazine-kiosk.myshopify.com" target='_blank'>
            <div className={`btn flex--center ${routeName === "shop" ? "active" : ""}`}>
                <ActiveShopCartHighlight/>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.22 27.22">
                    <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                    <path className="cls-1" d="M14.71,12.38a13.33,13.33,0,0,1-1.32-.66,2.47,2.47,0,0,1-.72-.64,1.34,1.34,0,0,1-.26-.82,1.07,1.07,0,0,1,.33-.79,1.19,1.19,0,0,1,.89-.32,1.57,1.57,0,0,1,1.54,1.13.35.35,0,0,0,.23.21.36.36,0,0,0,.32,0L17,9.67a.39.39,0,0,0,.14-.47,3.37,3.37,0,0,0-2.31-2.07V5.93a.38.38,0,0,0-.38-.38H13a.38.38,0,0,0-.38.38V7.11a3.25,3.25,0,0,0-2.46,3.23A3.27,3.27,0,0,0,11,12.58,6.4,6.4,0,0,0,13,14a15.17,15.17,0,0,1,1.45.75,2.69,2.69,0,0,1,.77.69,1.51,1.51,0,0,1,.27.91A1.65,1.65,0,0,1,15,17.64a1.63,1.63,0,0,1-1.19.43c-1.19,0-2-.79-2.34-2.41a.36.36,0,0,0-.22-.26.37.37,0,0,0-.34,0l-1.26.79a.36.36,0,0,0-.17.38,4.35,4.35,0,0,0,1.16,2.32,4.23,4.23,0,0,0,2,1.18v1.2a.38.38,0,0,0,.38.38h1.39a.38.38,0,0,0,.38-.38V20.14a3.65,3.65,0,0,0,2.05-1.21,3.92,3.92,0,0,0,.9-2.63,3.48,3.48,0,0,0-.83-2.41A6.46,6.46,0,0,0,14.71,12.38Z"/>
                    <path className="cls-1" d="M13.61,0A13.61,13.61,0,1,0,27.22,13.61,13.63,13.63,0,0,0,13.61,0Zm0,25.09A11.48,11.48,0,1,1,25.09,13.61,11.49,11.49,0,0,1,13.61,25.09Z"/>
                    </g>
                    </g>
                </svg>
                <style jsx>{`
                    .btn {
                        position: relative;
                        height: 100%;
                        background: var(--color--gray-lightest);
                        transition: background .18s ease, opacity .18s ease;
                        cursor: pointer;
                    }
                    svg {
                        height: 22px;
                        position: relative;
                    }

                    .cls-1{
                        fill: var(--color--active-gray);
                    }
                    
                    .active .cls-1{
                        fill: var(--color--gray-lightest);
                    }

                    .btn:hover {
                        background: var(--color--gray-light-hover);
                    }

                    .active, .active:hover {
                        background: var(--color--active-gray);
                    }

                `}</style>
            </div>
        </a>
        // </Link>

    )
});

const ActiveShopCartHighlight = observer(() => {
    const {cartStore: {numCartProducts}} = useStore();

    return (
        <div className={numCartProducts > 0 ? "active" : ""}>
            <style jsx>{`
                div {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    background: #38d430;    
                    transform: scale(.001);
                    transition: transform .3s ease;
                    transition-delay: .15s;
                }
                .active {
                    transform: scale(1);
                }
            `}</style>
        </div>
    )
})

const FilterHomeButtonSlider = observer(() => {
    const {routerStore: {routeName}} = useStore();

    return (
        <div className="button-slider">

            <div className={`button-slider__inner flex ${routeName === "home" ? "show-filters" : "show-back"}`}>
                <FilterToggleButton/>
                <HomeButton/>
            </div>
            
            <style jsx>{`
                div {
                    flex: 1;
                    width: 50px;
                    overflow: hidden;
                }

                .button-slider__inner {
                    width: 100px;
                    height: 100%;
                    transition: transform .2s ease-out;
                    transition-delay: .1s;
                }

                .show-back {
                    transform: translate3d(-50px, 0 , 0);
                }

                .button-slider__inner > :global(div) {
                    height: 100%;
                    flex: 1;
                }

                .button-slider__inner > :global(a) {
                    height: 100%;
                    flex: 1;
                }
            `}</style>
        </div>
    )
});



const HomeButton = observer(() => {

    return (
        <Link to="home">
            <div 
                className="btn flex--center"
            >

                <div className="mini-logo">
                    <div className="h-1"></div>
                    <div className="h-2"></div>
                    <div className="h-3"></div>

                    <div className="i-1-1"></div>
                    <div className="i-1-2"></div>

                    <div className="i-2-1"></div>
                    <div className="i-2-2"></div>
                </div>

                <style jsx>{`
                    .btn {
                        position: relative;
                        height: 100%;
                        background: var(--color--active-gray);
                        cursor: pointer;
                    }

                    .mini-logo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: red;
                    }

                    .h-1 {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 70%;
                        height: 100%;
                        background: #29abe2;
                    }

                    .h-2 {
                        position: absolute;
                        top: 0;
                        left: 22.5%;
                        width: 25%;
                        height: 25%;
                        background: rgb(255,89,90);
                    }
                    
                    .h-3 {
                        position: absolute;
                        bottom: 0;
                        left: 22.5%;
                        width: 25%;
                        height: 25%;
                        background: #9FDECE;
                    }

                    .i-1-1 {
                        position: absolute;
                        top:0;
                        left: 70%;
                        width: 15%;
                        height: 30%;
                        background: #ff40b4;
                    }

                    .i-1-2 {
                        position: absolute;
                        top:30%;
                        left: 70%;
                        width: 15%;
                        height: 70%;
                        background: #38d430;
                    }

                    .i-2-1 {
                        position: absolute;
                        top:0;
                        left: 85%;
                        width: 15%;
                        height: 20%;
                        background: #ffff00;
                    }

                    .i-2-2 {
                        position: absolute;
                        top:20%;
                        left: 85%;
                        width: 15%;
                        height: 80%;
                        background: #fbb03b;
                    }
                `}</style>
            </div>
        </Link>

    )
})

const ReadButton = observer(() => {
    const {uiStore: {readButtonActive}, audioStore: {audio__activeDetails}} = useStore();
    const slug = audio__activeDetails ? audio__activeDetails.slug : "404";
    const color = get(audio__activeDetails, "category.color", "var(--color--gray-lightest)")
    const secondaryColor = get(audio__activeDetails, "category.secondaryColor", "var(--color--gray-lightest)")

    return (
        <Link to="article.view" params={{id: slug}}>
            <div 
                className={`btn flex--center ${readButtonActive ? "active" : ""} ${!audio__activeDetails ? "disabled" : ""}`}
            >

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.62 25.01">

                    <title>read</title>
                    <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                    <g className="cls-1">
                    <rect className="cls-2" x="1.07" y="1.07" width="19.48" height="22.87" rx="2.01"/>
                    <line className="cls-2" x1="4.88" y1="5.73" x2="15.89" y2="5.73"/>
                    <line className="cls-2" x1="4.88" y1="9.96" x2="15.89" y2="9.96"/>
                    <line className="cls-2" x1="4.88" y1="14.2" x2="15.89" y2="14.2"/>
                    <line className="cls-2" x1="4.88" y1="18.43" x2="15.89" y2="18.43"/>
                    </g>
                    </g>
                    </g>
                </svg>

                <style jsx>{`
                    .btn {
                        background: ${color};
                    }

                    .active {
                        background: ${secondaryColor};
                    }

                    .btn:hover {
                        background: ${secondaryColor};
                    }
                `}</style>

                <style jsx>{`


                    .btn {
                        position: relative;
                        height: 100%;
                        background: var(--color--gray-light);
                        transition: background .18s ease, opacity .18s ease;
                        cursor: pointer;
                    }

                    .disabled {
                        cursor: default;
                    }

                    .disabled svg {                        
                        opacity: .3;
                    }

                    svg {
                        height: 20px;
                    }

                    .cls-2{
                        transition: stroke .18s ease;
                        fill:none;
                        stroke: var(--color--gray);
                        stroke-linecap:round;
                        stroke-miterlimit:10;
                        stroke-width:2.12px;
                    }
                `}</style>
            </div>
        </Link>
    )
})


const FilterToggleButton = observer(() => {
    const {uiStore: {toggleFooterFilters, footer__showFilters}} = useStore();

    return (
        <div className={`btn flex--center ${footer__showFilters ? "active" : ""}`} onClick={toggleFooterFilters}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.48 24.22">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                        <path class="cls-1" d="M1.21,4.17h15.3a2.62,2.62,0,0,0,4.83,0h.93a1.25,1.25,0,0,0,1.21-1.29A1.24,1.24,0,0,0,22.27,1.6h-.92a2.63,2.63,0,0,0-4.84,0H1.21A1.24,1.24,0,0,0,0,2.88,1.25,1.25,0,0,0,1.21,4.17Z"/><path class="cls-1" d="M22.27,10.46H9.13A2.7,2.7,0,0,0,6.71,8.87a2.7,2.7,0,0,0-2.42,1.59H1.21a1.29,1.29,0,0,0,0,2.58H4.29a2.7,2.7,0,0,0,2.42,1.59A2.7,2.7,0,0,0,9.13,13H22.27a1.29,1.29,0,0,0,0-2.58Z"/><path class="cls-1" d="M22.27,20.05H20a2.64,2.64,0,0,0-4.84,0H1.21a1.29,1.29,0,0,0,0,2.58H15.15a2.64,2.64,0,0,0,4.84,0h2.28a1.29,1.29,0,0,0,0-2.58Z"/>
                    </g>
                </g>
            </svg>

            <style jsx>{`
                .btn {
                    position: relative;
                    height: 100%;
                    background: var(--color--gray-lightest);
                    transition: background .18s ease;
                    cursor: pointer;
                }

                .btn:hover {
                    background: var(--color--gray-light-hover);
                }

                .active {
                    background: var(--color--active-gray);
                }

                .active:hover {
                    background: var(--color--active-gray);
                }

                svg {
                    height: 15px;
                }

                path {
                    fill: var(--color--gray);
                }

                .active path {
                    fill: var(--color--gray-lightest);
                }

                @media (max-width: 768px), (pointer: coarse) {
                    .btn:hover {
                        background: var(--color--gray-lightest);
                    }
                    .active:hover {
                        background: var(--color--active-gray);
                    }
                }

            `}</style>
        </div>
    )
});

const SearchButton = observer(() => {
    const {uiStore: {toggleSearchBar, footer__showSearch}} = useStore();

    return (
        <div className={`btn flex--center ${footer__showSearch ? "active" : ""}`} onClick={toggleSearchBar}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">

            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_2-2" data-name="Layer 2">
                    <path className="cls-1" d="M13,7a6,6,0,0,1-6,6,6,6,0,1,1,6-6Z"/>
                    <line className="cls-2" x1="16" y1="16" x2="11.34" y2="11.34"/>
                </g>
            </g>
        </svg>
        
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.48 24.22">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_2-2" data-name="Layer 2">
                    <path class="cls-1" d="M1.21,4.17h15.3a2.62,2.62,0,0,0,4.83,0h.93a1.25,1.25,0,0,0,1.21-1.29A1.24,1.24,0,0,0,22.27,1.6h-.92a2.63,2.63,0,0,0-4.84,0H1.21A1.24,1.24,0,0,0,0,2.88,1.25,1.25,0,0,0,1.21,4.17Z"/><path class="cls-1" d="M22.27,10.46H9.13A2.7,2.7,0,0,0,6.71,8.87a2.7,2.7,0,0,0-2.42,1.59H1.21a1.29,1.29,0,0,0,0,2.58H4.29a2.7,2.7,0,0,0,2.42,1.59A2.7,2.7,0,0,0,9.13,13H22.27a1.29,1.29,0,0,0,0-2.58Z"/><path class="cls-1" d="M22.27,20.05H20a2.64,2.64,0,0,0-4.84,0H1.21a1.29,1.29,0,0,0,0,2.58H15.15a2.64,2.64,0,0,0,4.84,0h2.28a1.29,1.29,0,0,0,0-2.58Z"/>
                </g>
            </g>
        </svg> */}

        <style jsx>{`
            .btn {
                position: relative;
                height: 100%;
                background: var(--color--gray-lightest);
                transition: background .18s ease;
                cursor: pointer;
            }

            .btn:hover {
                background: var(--color--gray-light-hover);
            }

            .active {
                background: var(--color--active-gray);
            }

            .active:hover {
                background: var(--color--active-gray);
            }

            svg {
                height: 15px;
            }


            .cls-1,
            .cls-2{fill:none;stroke: var(--color--gray);stroke-miterlimit:10;stroke-width:2px;}
            .cls-2{stroke-linecap:round;}


            .active .cls-1, .active .cls-2 {
                stroke: var(--color--gray-lightest);
            }

            @media (max-width: 768px), (pointer: coarse) {
                .btn:hover {
                    background: var(--color--gray-lightest);
                }
                .active:hover {
                    background: var(--color--active-gray);
                }
            }

        `}</style>
    </div>
    )
})



export default AudioPlayerLinkButtons