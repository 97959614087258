import get from 'lodash/get'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import { Shop } from '../../../pages'
import { FooterCategoryFilters } from '../filters'
import { SearchConsole } from '../search'
import { AudioPlayerLinkButtons, AudioPlayerControls, AudioProgressBar, AudioPlayer, AudioPlayerVolumeInterface } from './subcomponents'

const AudioPlayerFooter= observer(() => {
    const {routerStore: {onShopPage}, audioStore: {audio__activeDetails}, uiStore: {hideScrollElements, header__height, header__isOpen, footer__showSearch, footer__showFilters, window: {height: windowHeight}}} = useStore();
   
    return (
        <div className={`footer ${onShopPage ? "shop-open" : ""} ${header__isOpen ? "header-open" : ""} ${hideScrollElements ? "hide" : ""} ${audio__activeDetails ? "active" : ""} ${footer__showFilters ? "filters-open" : ""} ${footer__showSearch ? "search-open" : ""}`}>
            <AudioPlayerVolumeInterface/>
            <AudioPlayer/>
            <AudioPlayerHead/>
            <FooterSlideInnerLayer/>

            <style jsx global>{`
                body {
                    overflow: ${onShopPage ? "hidden" : "auto"};
                }
            `}</style>

            <style jsx>{`
                .shop-open {
                    transform: translate3d(0 , -${windowHeight - 50 - 70}px, 0);
                }

                .header-open.shop-open {
                    transform: translate3d(0 , -${windowHeight - 50 - 70 - (header__height - 70)}px, 0);
                }

                @media (pointer: coarse), (max-width: 768px) {
                    .shop-open {
                        transform: translate3d(0 , -${windowHeight - 100 - 50}px, 0);
                    }
                }
            `}</style>

            <style jsx>{`
                .footer {
                    position: fixed;
                    bottom: 0;
                    z-index: 10;
                    width: 100%;
                    
                    background: var(--color--gray-lightest);
                    background: transparent;
                    transform: translate3d(0, 0px, 0); 
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1);
                    transition-delay: .3s;
                }

                .active {
                    transform: translate3d(0, 0px, 0); 
                }

                .filters-open {
                    transform: translate3d(0, -100px, 0); 
                }

                .search-open {
                    transform: translate3d(0, -100px, 0); 
                }

                .hide {
                    transform: translate3d(0, 150px, 0); 
                }
                
                @media (pointer: coarse), (max-width: 768px) {
                    .footer {
                        transform: translate3d(0, 280px, 0); 
                        transform: translate3d(0, 0px, 0); 
                    }
                    .active {
                        transform: translate3d(0, -30px, 0); 
                    }

                    .filters-open {
                        transform: translate3d(0, -250px, 0); 
                    }

                    .search-open {
                        transform: translate3d(0, -100px, 0); 
                    }

                    .search-open.active {
                        transform: translate3d(0, -130px, 0); 
                    }

                    .filters-open.active {
                        transform: translate3d(0, -280px, 0); 
                    }

                    .hide {
                        transform: translate3d(0, 300px, 0); 
                        transform: translate3d(0, 0px, 0); 
                    }
                }
            `}</style>
        </div>  
    )
});

const FooterSlideInnerLayer = observer(() => {
    const {routerStore: {onShopPage}, uiStore: {isMobile}, audioStore: {audio__activeDetails}} = useStore();

    return (
        <div className={`slide ${onShopPage ? "on-shop" : ""} ${isMobile && audio__activeDetails ? "show-player" : ""}`}>
            <div className="slide__inner">
                <MobileAudioProgress/>
                <FooterFilterSlider/>
                <Shop/>
            </div>

            <style jsx>{`
                .slide {
                    width: 100%;
                    position: absolute;
                    top: 50px;
                    overflow: hidden;
                }


                .slide__inner {
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1);
                    transition-delay: .3s;
                }

                .on-shop .slide__inner{
                    transform: translateY(-100px);
                }

                @media (pointer: coarse) {
                    .slide__inner {
                        transform: translateY(-30px);
                    }

                    .show-player .slide__inner{
                        transform: translateY(0px);
                    }

                    .on-shop .slide__inner{
                        transform: translateY(-280px);
                        transform: translateY(-310px);
                    }
                }

            `}</style>
        </div>
    )
});

const FooterFilterSlider = observer(() => {
    const {uiStore: {footer__showFilters}}  = useStore();
    return (
        <div className={"footer__filter-slider"}>
            <div className={footer__showFilters ? "footer__filter-slider__inner filters" : "footer__filter-slider__inner"}>
                <SearchConsole/>
                <FooterCategoryFilters/>
            </div>


            <style jsx>{`
                .footer__filter-slider {
                    height: 100px;
                    overflow:hidden;
                }

                .footer__filter-slider__inner {
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1);
                    transition-delay: .3s;
                }

                .footer__filter-slider__inner.filters {
                    transform: translate3d(0, -100px ,0);
                }

                @media (max-width: 769px), (pointer: coarse) {
                    .footer__filter-slider {
                        height: 250px;
                        overflow:hidden;
                    }
                }

            `}</style>
        </div>

    )
});

const MobileAudioProgress = observer(() => {
    const {uiStore: {isMobile}} = useStore();
    if (!isMobile) return null
    return (
        <div>
            <AudioProgressBar/>
            <style jsx>{`
                div {
                    height: 30px;
                }
            `}</style>
        </div>
    )
})

const AudioPlayerHead = observer(() => {
    const {uiStore: {isMobile}} = useStore();

    return (
        <div className="audio-player__head flex">
            <AudioPlayerLinkButtons/>
            {!isMobile ? <AudioProgressBar/> : null}
            <AudioPlayerControls/>

            <style jsx>{`
                .audio-player__head {
                    height: 50px;
                }
            `}</style>
        </div>
    )
})

export default AudioPlayerFooter