import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../hooks/useStore'

const HeaderToggleButton = () => {
    const {uiStore: {toggleHeader, header__isOpen}} = useStore();
    
    return (
        <div 
            className={`${header__isOpen ? "open" : ""} toggle-btn flex--center`}
            onClick={toggleHeader}>
            
            <div className='dot-wrapper'>
                <div className="circle"></div>
                <span className="text-xs label">Info</span>

            </div>
            <style jsx>{`
                .toggle-btn {
                    background: white;
                    height: 70px;
                    width: 70px;
                    cursor: pointer;
                    transition: background .2s ease;
                }

                .circle {
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    border: 2px solid rgba(0,0,0,.5);
                }

                .toggle-btn:hover {
                    background: var(--color--gray-light);
                }

                .open {
                    background: var(--color--active-gray);
                }

                .open .circle {
                    background: rgba(0,0,0,.5);
                    border: 2px solid transparent;
                }

                .open:hover {
                    background: var(--color--gray);
                }

                .label {
                    color: rgba(0,0,0,.5);
                    text-align: center;
                    margin-left: 1px;
                    // display: block;
                    // height: 3px;
                    // background: var(--color--gray);
                    // width: 50px;
                }
                .dot-wrapper {
                    position: relative;
                    top: 5px;
                }

                @media (pointer: coarse) {
                    .toggle-btn {
                        grid-row: 1;
                        height: 40px;
                    
                        width: 100%;
                    }
                    .dot-wrapper {
                        position: relative;
                        top: 0px;
                    }
                    div:hover {
                        background: white;
                    }
                    .label {
                        display: none;
                    }
                }
            `}</style>
        </div>
    )
}




export default HeaderToggleButton