import React from 'react'
import get from 'lodash/get'
import { imageBuilder } from '../../../utils/sanity/image-builder'
import { convertDateString } from '../../../utils/general'

const ArticleInfoSection = React.memo((props) => {
    return (
        <div className="article-info-section">
            <ArticleByLine {...props}/>
            <ArticleCategory {...props}/>
            <ArticleDate {...props}/>

            <style jsx>{`
                .article-info-section {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    position: absolute;
                    padding-left: 50px;
                    width: 600px;
                    // width: calc(100% - 360px - 500px);
                }

                @media(max-width: 1375px) {
                    .article-info-section {
                        display: block;
                        position: static;
                    }
                    .article-info-section :global(> div) {
                        margin-bottom: 30px;
                    }
                }
                @media (pointer: coarse), (max-width: 769px) {
                    .article-info-section {
                        position: static;
                        width: auto;
                        display: block;
                        padding: 20px 0 60px;
                    }


                }
            `}</style>
        </div>
    )
})

const ArticleByLine = ({data}) => {
    if (!data.author) return null;
    const sourceUrl = get(data.author, "image.url", "");
    const imageUrl = sourceUrl ? imageBuilder(sourceUrl).width(60).quality(85).auto("format").url() : ""


    return (
        <div className="flex--center-y">
            <div className="pic"></div>

            <div>
                <ArticleAuthor data={data}/> 
                {data.author.isContributor ? <h6 className="text-s">- Contributor</h6> : null}
            </div>

            <style jsx>{`
                .pic {
                    height: 42px;
                    width: 42px;
                    margin-right: 8px;
                    background: url(${imageUrl});
                    background-position: center center;
                    background-size: cover;
                }

                h6 {
                    margin-top: 3px;
                }
            `}</style>
        </div>
    )
}

const ArticleAuthor = ({data}) => {
    if (data.author.website) {
        return (
            <a href={data.author.website} target="_blank">
                <h5 className="text-s">
                    {data.author.name} ↗
                </h5>

                <style jsx>{`
                    a {
                        text-decoration: underline;
                    }
                `}</style>
            </a>
        )
    } else {
        return (
            <h5 className="text-s">
                {data.author.name}
            </h5>
        )
    }

}

const ArticleCategory = ({data}) => {

    return (
        <div>
            <h5 className="text-s">{data.category.title}</h5>
            {data.subCategory? <h6 className="text-s">- {data.subCategory.title}</h6> : null}

            <style jsx>{`
                h5 {
                    background: ${data.category.color};
                    display: inline-block;
                    padding: 3px 5px;
                }
            `}</style>
        </div>
    )
}

const ArticleDate = ({data}) => {
    if (!data.publishedAt) return null

    return (
        <div>
            <h4 className="text-s">{convertDateString(data.publishedAt)}</h4>
        </div>
    )
}
export default ArticleInfoSection