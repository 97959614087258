import imageUrlBuilder from '@sanity/image-url'
import { client } from './sanity-client'

const builder = imageUrlBuilder(client)

export function imageBuilder(sourceID) {  
  if (!sourceID) return ""

  return builder.image(sourceID);
}

