import AudioStore from "./AudioStore";
import CartStore from "./CartStore";
import DataStore from "./DataStore";
import RouterStore from "./RouterStore"
import UIStore from "./UIStore";

class RootStore {

    constructor(rootStore) {
        this.rootStore = rootStore;

        this.routerStore = new RouterStore(this);
        this.uiStore = new UIStore(this);
        this.dataStore = new DataStore(this);
        this.audioStore = new AudioStore(this);
        this.cartStore = new CartStore(this);
    }

}

export default RootStore