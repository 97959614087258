import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import { Link } from '../link';

const SiteLogoPlayer = observer(() => {
    const {routerStore, audioStore: {audio__activeDetails, audio__isPlaying}, dataStore: {data: {logoAudioConfig}, dataReady: {logoAudioConfig: dataReady}}, uiStore: {isMobile}} = useStore();
    if (!dataReady) return null
    
    return (
        <div className="logo flex">
            <H isPlayingAudio={!!audio__activeDetails && audio__isPlaying} isMobile={isMobile} routerStore={routerStore} audioSrc={[logoAudioConfig.letters.HOne.audio.asset.url, logoAudioConfig.letters.HTwo.audio.asset.url, logoAudioConfig.letters.HThree.audio.asset.url]}/>
            <IOne isPlayingAudio={!!audio__activeDetails && audio__isPlaying} isMobile={isMobile} routerStore={routerStore}  audioSrc={[logoAudioConfig.letters.IOneOne.audio.asset.url, logoAudioConfig.letters.IOneTwo.audio.asset.url]}/>
            <ITwo isPlayingAudio={!!audio__activeDetails && audio__isPlaying} isMobile={isMobile} routerStore={routerStore}  audioSrc={[logoAudioConfig.letters.ITwoOne.audio.asset.url, logoAudioConfig.letters.ITwoTwo.audio.asset.url]}/>

            <style jsx>{`
                .logo {
                    height: 70px;
                    background: black;
                    flex: 1;
                    cursor: pointer;
                }
                
                .logo :global(.active){
                    animation: highlight .55s ease;
                }

                @keyframes highlight {
                    from {
                        opacity: .5;
                    }

                    to {
                        opacity: 1;
                    }
                }

                @media (max-width: 769px) {
                    .logo {
                        height: 60px;
                    }
                }
            `}</style>
        </div>
    )
})

class AudioPlayerEl extends React.PureComponent {
    constructor(props) {
        super(props);
    
        this.audio = null;
        this.ready = false;

        this.state = {
            animate: false
        }
    }


    animate() {
        this.setState({
            animate: true
        });

        setTimeout(this.stopAnimate.bind(this), 1000)
    }

    stopAnimate() {
        this.setState({
            animate: false
        })
    }

    componentDidMount() {
        this.audio = new Audio(this.props.audioSrc);
        this.audio.oncanplaythrough =  this.setReady;
    }
    

    setReady = () => {
        this.ready = true;
    }

    playAudio = () => {
        if (this.props.isPlayingAudio) return
        // return
        
        if (!this.ready ) return
        if (this.state.animate) return
        if (this.props.isMobile) return 

        this.resetAudio();
        this.animate();
        this.audio.volume = .15
        this.audio.play();
        
    }

    navigateHome = () => {
        this.props.routerStore.navigate("home");
    }

    resetAudio = () => {
        this.audio.pause();
        this.audio.currentTime = 0;
    }
}

const H = React.memo(({isPlayingAudio, audioSrc, routerStore, isMobile}) => {
    return (
        <div>
            <HOne isPlayingAudio={isPlayingAudio} isMobile={isMobile} routerStore={routerStore} audioSrc={audioSrc[0]}/>
            <HTwo isPlayingAudio={isPlayingAudio} isMobile={isMobile} routerStore={routerStore} audioSrc={audioSrc[1]}/>
            <HThree isPlayingAudio={isPlayingAudio} isMobile={isMobile} routerStore={routerStore} audioSrc={audioSrc[2]}/>
            <style jsx>{`
                div {
                    position: relative;
                    flex: 5;
                    
                }
            `}</style>
        </div>
    )
})

class HOne extends AudioPlayerEl {
    render() {
        return (
            <div onClick={this.navigateHome} className={this.state.animate ? "active" : ""} onMouseEnter={this.playAudio}>
                <style jsx>{`
                    div {
                        background: #29abe2;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        contain: strict;
                    }
                `}</style>
            </div>
        )
    }
}

class HTwo extends AudioPlayerEl {
    render() {
        return (
            <div onClick={this.navigateHome} onMouseEnter={this.playAudio} className={this.state.animate ? "active h-2__1" : "h-2__1"}>

                <style jsx>{`
                    div {
                        background: #fc5d42;
                        
                        position: absolute;
                        top: 0;
                        height: 20px;
                        left: 30%;
                        right: 30%;
                        contain: strict;
                    }
                `}</style>
            </div>
        )
    }
}


class HThree extends AudioPlayerEl {
    render() {
        return (
            <div onClick={this.navigateHome} onMouseEnter={this.playAudio} className={this.state.animate ? "active h-2__2" : "h-2__2"}>

                <style jsx>{`
                    div {
                        // background: rgb(204, 204, 204);
                        background: #9FDECE;
                        position: absolute;
                        bottom: 0;
                        height: 20px;
                        left: 30%;
                        right: 30%;
                        contain: strict;
                    }
                `}</style>
            </div>
        )
    }
}

// class HTwoThree extends AudioPlayerEl {
//     render() {
//         return (
//             <div onMouseEnter={this.playAudio} className={this.state.animate ? "active h-2__3" : "h-2__3"}>
//             </div>
//         )
//     }
// }

// const HOne = () => {
//     return (
//         <div>

//             <style jsx>{`
//                 div {
//                     background: #28ABE2;
//                     flex: 2;
//                 }
//             `}</style>
//         </div>
//     )
// }


// const HTwo = ({audioSrc, routerStore}) => {
//     return (
//         <div className="h-2">

//             <HTwoOne routerStore={routerStore} audioSrc={audioSrc[0]}/>
//             <HTwoTwo routerStore={routerStore} audioSrc={audioSrc[1]}/>
//             <HTwoThree routerStore={routerStore} audioSrc={audioSrc[2]}/>

//             <style jsx>{`
//                 div {
//                     flex: 1;
//                 }

//                 .h-2 {
//                     display: flex;
//                     flex-direction: column;
//                 }

//                 .h-2 :global(.h-2__1) {
//                     background: #ff595a;
//                     flex: 2;
//                 }

//                 .h-2 :global(.h-2__2) {
//                     background: #29abe2;
//                     flex: 6;
//                 }

//                 .h-2 :global(.h-2__3) {
//                     background: #cccccc;
//                     flex: 2;
//                 }
//             `}</style>
//         </div>
//     )
// }

// class HThree extends AudioPlayerEl{
//     render() {
//         return (
//             <div  className={this.state.animate ? "active" : ""} onMouseEnter={this.playAudio}>
    
//                 <style jsx>{`
//                     div {
//                         background: #29abe2;
//                         flex: 2;
//                     }
//                 `}</style>
//             </div>
//         )
//     }

// }

class IOneOne extends AudioPlayerEl {
    render() {
        return <div onClick={this.navigateHome} className={this.state.animate ? "active i-1__1" : "i-1__1"} onMouseEnter={this.playAudio}></div>
    }
}

class IOneTwo extends AudioPlayerEl {
    render() {
        return <div onClick={this.navigateHome} className={this.state.animate ? "active i-1__2" : "i-1__2"}  onMouseEnter={this.playAudio}></div>
    }
}

const IOne = React.memo(({isPlayingAudio, audioSrc, routerStore, isMobile}) => {

    return (
        <div className="i-1">

            <IOneOne isPlayingAudio={isPlayingAudio} isMobile={isMobile}routerStore={routerStore} audioSrc={audioSrc[0]}/>
            <IOneTwo isPlayingAudio={isPlayingAudio} isMobile={isMobile} routerStore={routerStore} audioSrc={audioSrc[1]}/>

            <style jsx>{`

                .i-1 {
                    display: flex;
                    flex-direction: column;
                    flex: 2.5;
                    contain: strict;
                }

                .i-1 :global(.i-1__1) {
                    background: #ff40b4;
                    flex: 2;

                }

                .i-1 :global(.i-1__2) {
                    background: #6fe324;
                    flex: 1;
                }

            `}</style>
        </div>
    )
})


class ITwoOne extends AudioPlayerEl {
    render() {
        return <div className={this.state.animate ? "active i-2__1" : "i-2__1"}  onMouseEnter={this.playAudio}></div>
    }
}

class ITwoTwo extends AudioPlayerEl {
    render() {
        return <div className={this.state.animate ? "active i-2__2" : "i-2__2"}  onMouseEnter={this.playAudio}></div>
    }
}

const ITwo = React.memo(({isPlayingAudio, audioSrc, routerStore, isMobile}) => {
    return (
        <div className="i-2">
            <ITwoOne isPlayingAudio={isPlayingAudio} isMobile={isMobile} routerStore={routerStore} audioSrc={audioSrc[0]}/>
            <ITwoTwo isPlayingAudio={isPlayingAudio} isMobile={isMobile} routerStore={routerStore} audioSrc={audioSrc[1]}/>
            
            <style jsx>{`

                .i-2 {
                    display: flex;
                    flex-direction: column;
                    flex: 2.5;
                }

                .i-2 :global(.i-2__1) {
                    background: #feff21;
                    flex: 1;
                }

                .i-2 :global(.i-2__2) {
                    background: #fbb03b;
                    flex: 3;
                }

            `}</style>
        </div>
    )
})

export default SiteLogoPlayer