import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../hooks/useStore';

const Loading = observer(() => {
    const {uiStore: {window}} = useStore();

    return (
        <div className="loading">
            <LoadingGrid/>
                
            <style jsx>{`
                .loading {
                    height: ${window.height - 70}px;
                    overflow: hidden;
                    width: ${window.width}px;
                    position: relative;
                    z-index: 1;
                }
            `}</style>
        </div>
    )
});

const LoadingGrid = () => {
    return (
        <div className="grid">
            <LoadingRow/>
            <LoadingRow/>
            <LoadingRow/>
            <LoadingRow/>
            <LoadingRow/>
            <LoadingRow/>
            <LoadingRow/>
            <LoadingRow/>
        </div>
    )
}

const LoadingRow = () => {
    return (
        <div className="row flex">
            <LoadingBlock/>
            <LoadingBlock/>
            <LoadingBlock/>
        </div>
    )
}

const LoadingBlock = () => {
    const c = Math.floor(Math.random() * 255);
    const flex = Math.floor((Math.random() * 10 + 1));

    return (
        <div>

            <style jsx>{`
                div {
                    background: rgba(${c}, ${c}, ${c}, 1);
                    height: 100px;
                    flex: ${flex};
                }
            `}</style>
        </div>
    )
}

export default Loading