import React from 'react'
import { textSerializers } from '../../../utils/sanity/text-serializer'
import Image from '../general/Image'

const BlockContent = require('@sanity/block-content-to-react')

const ArticlePageBody = ({data}) => {
    let blocks =  data.body.map((item, idx) => {
        if (item._type === "block") return <ArticleCopy data={item}/>
        if (item._type === "captionedImage") return <ArticleCaptionedImage data={item}/>
        if (item._type === "image") return <ArticleImage data={item}/>
        if (item._type === "file") return <ArticleAudioPlayer data={item}/>
        if (item._type === "embeddedContent") return <ArticleEmbeddedContent data={item} />

        return null
    })


    return (
        <div>
            {blocks}
        </div>
    )
}

const ArticleAudioPlayer = ({data}) => {
    return (
        <div className='center'>
            <audio src={data.asset.url} controls></audio>

            <style jsx>{`


                .center {
                    max-width: 500px;
                    margin: 40px 360px 40px auto;
                }

                audio {
                    width: 100%;
                }

                @media (pointer: coarse) {
                    .full, .center {
                        padding: 0;
                        margin: 20px 0;
                    }

                    audio {
                        display: block;
                        width: 100%;
                    }
                }
            `}</style>
        </div>
    )
}

const ArticleEmbeddedContent = ({data}) => {
    return (
        <div className="embedded-content">
            <div 
                className="embedded-content__inner"
                dangerouslySetInnerHTML={{__html: data.content}}>            
            </div>

            <style jsx>{`
                .embedded-content {
                    max-width: 500px;
                    width: 100%;
                    margin: 40px 360px 40px auto;
                    overflow: hidden;
                }

                .embedded-content__inner :global(iframe) {
                    width: 100%;
                    max-width: 100% !important;
                    display: block;
                    margin: 0 auto;
                }
            `}</style>
        </div>
    )
}

const ArticleCaptionedImage = ({data}) => {
    const alignment = data.alignment && data.alignment === "center" ? "center" : "full";
    return (
        <div className={`captioned-image ${alignment}`}>
            <Image src={data.image.asset.url} width={1200} aspectRatio={data.image.asset.aspectRatio}/>
            <BlockContent blocks={data.caption} serializers={textSerializers} />

            <style jsx>{`

                .full {
                    padding: 30px 360px 30px 50px;
                }

                .center {
                    max-width: 500px;
                    margin: 40px 360px 40px auto;
                }

                .captioned-image :global(p) {
                    font-size: 12px;
                    margin-top: 5px;
                    margin-bottom: 0;
                }

                img {
                    width: 100%;
                    
                }

                @media (max-width: 769px) {
                    .full, .center {
                        padding: 0;
                        margin: 20px 0;
                    }
                }
            `}</style>
        </div>
    )
}
const ArticleImage = ({data}) => {
    const alignment = data.alignment && data.alignment === "center" ? "center" : "full";

    return (
        <div className={`image ${alignment}`}>
            <Image src={data.asset.url} width={1200} aspectRatio={data.asset.aspectRatio}/>
            {/* <img src={imageBuilder(data.asset.url).width(1000).quality(80)} /> */}

            <style jsx>{`
                .full {
                    padding: 30px 360px 30px 50px;
                }

                .center {
                    max-width: 500px;
                    margin: 40px 360px 40px auto;
                }

                img {
                    width: 100%;
                }

                @media (max-width: 769px) {
                    .full, .center {
                        padding: 0;
                        margin: 20px 0;
                    }
                }
            `}</style>
        </div>
    )
}

const ArticleCopy = ({data}) => {
    return (
        <div>

            <BlockContent blocks={data} serializers={textSerializers} />
            
            <style jsx>{`
                div {
                    max-width: 500px;
                    margin: 0px 360px 12px auto;
                    text-align: justify;
                }

                @media (max-width: 769px), (pointer: coarse) {
                    div {
                        width: 100%;
                        margin: 0 auto 12px;
                    }
                }
            `}</style>
        </div>
    )
}

export default ArticlePageBody