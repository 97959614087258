import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import { Link } from '../link'

const HeaderViewLinks = () => {
    return (
        <div className="header-view-links">  
            <HeaderViewLink>Info</HeaderViewLink>
            <HeaderViewLink>Masthead</HeaderViewLink>
            <div className="shop-link">
                {/* <Link to="shop">Shop</Link> */}
                <a href="https://hii-magazine-kiosk.myshopify.com" target='_blank'>
                    Shop
                </a>
            </div>


            <style jsx>{`
                .header-view-links {
                    width: 125px;
                }

                .header-view-links :global(a){
                    color: var(--color--gray);
                }

                .shop-link {
                    transition: transform .12s ease;
                }

                .shop-link:hover {
                    transform: translateX(2px);
                }



            `}</style>
        </div>
    )
}

const HeaderViewLink = observer(({children}) => {
    const {uiStore: {setActiveHeaderView, header__activeView}} = useStore()

    return (
        <h5 
            className={header__activeView === children.toLowerCase() ? "link active text-s" : "link text-s"}
            onClick={() => setActiveHeaderView(children.toLowerCase())}>
                {children}

                <style jsx>{`
                .link {
                    margin-bottom: 12px;
                    cursor: pointer;
                    transition: color .15s ease, transform .12s ease;
                    user-select: none;
                }

                .link:hover {
                    transform: translateX(2px);
                }

                .active {
                    color: black;
                    transform: translateX(4px) !important;
                }

                h5 {
                    color: var(--color--gray);
                }
            `}</style>
            </h5>

    )
})

export default HeaderViewLinks