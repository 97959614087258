export const textSerializers = {
  marks: {
    // internalLink: ({mark, children}: {mark: object, children: ReactChild | ReactChild[]}) => {
    //   // const {slug = {}} = mark
    //   // const href = `/${slug.current}`
    //   // return <a href={href}>{children}</a>
    //   return children
    // },
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const { href } = mark
      return <a href={href} target="_blank" rel="noopener">{children}</a>
    }
  }
}
