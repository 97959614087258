export function sendAnalyticsPageView(pageState) {
    const gtag = window.gtag;
    if (!window.gtag) return
    let title = pageState.name === "article.view" ? pageState.params.id : pageState.name;
    // let title = pageState.name === "home" ? pageState.params.id : pageState.name;

    // console.log(pageState)
    gtag('event', 'page_view', {
        page_title: title,
        page_path: pageState.path,
    });
}

export function sendAnalyticsArticlePlay(articleData) {
    const gtag = window.gtag;
    if (!window.gtag) return

    // console.log(articleData.title)
    // return 

    gtag('event', 'audio_play', {
        'event_category': 'audio_engagement',
        'event_label': articleData.title,
        'article_title': articleData.title,
        'value': 1
    });
}