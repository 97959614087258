import {action, observable, computed, makeObservable} from "mobx";
import {createRouter} from 'router5';
import browserPlugin from 'router5-plugin-browser';

import routes from '../router/routes.js'
import {mobxRouterPlugin} from '../router/mobxPlugin.js';
import get from 'lodash/get'

import {
	executeAtLeast
} from '../utils/general'

class RouterStore {
	constructor(rootStore) {
		makeObservable(this);

		this.rootStore = rootStore;

		const router = createRouter(routes, {
		  // trailingSlashMode: 'always',
		  trailingSlashMode: 'never',
		  queryParamsMode: 'loose',
		  strictTrailingSlash: false
  		});		


  		router.usePlugin(browserPlugin(), mobxRouterPlugin);
	
  		this.router = router;
	}


	@observable current;
	@observable previousRoute;
	@observable routeTransitioning = false;
	
	@observable showLoader = false;
	// @observable fadeOutLoader = false;
	@observable movingTo = {};
	

	transitionIdx = 0;
	@observable initialLoad = true;


	@action.bound navigate(to, params = {}, options = {}) {
        // console.log(to, params)
        // return
		const canPass = this.validateNavigation(to, params);
		if (!canPass) return
		
		this.router.navigate(to, params, options);
	}

	validateNavigation(to, params) {

		if (to === "article.view" && params.id === "404") {
			return false
		}
		
		if (to === this.routeName) {
			if (params.id) {
				if (params.id === this.current.params.id) {
					return false
				}
			} else if (to === "home" || to === "shop")  {
				return false
			}
		}

		return true
	}

	@computed get onShopPage() {
		return this.current && this.current.name === "shop"
	}

	@computed get headTitle() {
		const {viewingArticle} = this;
		const baseTitle = 'Hii Magazine';

		if (viewingArticle) {
			const slug = this.current.params.id;

			if (!this.rootStore.dataStore.dataReady.articleView[slug]) {
				return baseTitle
			}
			const article = this.rootStore.dataStore.data.articleView[slug]
			return `${article.title} | ${baseTitle}`
		}

		return baseTitle
	}

	@computed get headDescription() {
		// const {viewingArticle} = this;
		const defaultText = "Hii Magazine is an online destination for sound culture and the listening-obsessed.";

		// if (viewingArticle) {
		// 	const slug = this.current.params.id;

		// 	if (!this.rootStore.dataStore.dataReady.articleView[slug]) {
		// 		return `Hii Magazine`
		// 	}
		// 	const article = this.rootStore.dataStore.data.articleView[slug]
		// 	return `${article.title} | Hii Magazine`
		// }

		return defaultText;
	}

	@computed get headImage() {
		const {viewingArticle} = this;
		let image = "https://hii-mag.com/images/hiimag-seo.png";

		if (viewingArticle) {
			// const workImage = get(this.rootStore.contentfulStore.pageData, "fields.seoImage.fields.file.url");
			// if (workImage) {
			// 	image = `${workImage}?w=1200&fm=png`
			// }
		}

		// console.log("image: ", "https:" + image)
		return image
	}

	@computed get routeName() {
		return get(this.current, "name", "404")
	}

	@computed get viewingArticle() {
		return this.routeName === "article.view"
	}



	@action setCurrent(state) { 
		this.previousRoute = this.current;
		// this.current = state;
		// this.rootStore.updatePageControls(state);

		// this.rootStore.uiStore.resetUIColor();

		this.startTransition(state).then((e) => {
			if (!this.initialLoad) {
				this.rootStore.uiStore.clearRouteVariables() 
			}

			this.endTransition();

			this.current = state;

			if ((this.previousRoute && this.previousRoute.name === "home" && state.name === "shop") || (this.previousRoute && this.previousRoute.name === "shop" && state.name === "home")) return
			// console.log(state.name)
			if (this.rootStore.uiStore.isMobile && ((this.previousRoute && this.previousRoute.name === "home") || (this.previousRoute && this.previousRoute.name === "article.view" && state.name === "home"))) return
			this.rootStore.uiStore.scrollToTop();
		})
	}

	@action.bound startTransition(toState) {

		if (!this.initialLoad) {
			this.routeTransitioning = true;
			this.setTransitionColor(toState); 
			this.startLoader(toState);
			if (toState.name !== 'article.view')  {
				this.rootStore.audioStore.playLogoAudio();
			}
		}

		let TIME;

		if (toState.name === "shop" || (this.current && this.current.name === "shop" && toState.name === "home") || (toState.name === "article.view" && this.current && this.current.name === "article.view")) {
			TIME = 10;
			return executeAtLeast(TIME, this.fetchRouteData, [toState])
		} 

		if (!this.initialLoad) {
			TIME = this.rootStore.uiStore.isMobile ? 500 : 1500
		} else {
			TIME = 10;
		}


		return executeAtLeast(TIME, this.fetchRouteData, [toState])

	}

	@action.bound setTransitionColor(toState) {
		
		if (toState.name === "article.view") {
            const articleData = this.rootStore.dataStore.articleListDataBySlug[toState.params.id];
			if (!articleData) return
			this.rootStore.uiStore.setTransitionColor(articleData.category.color);
		} else {
			this.rootStore.uiStore.setTransitionColor(null);
		}
	};

	@action.bound async fetchRouteData(toState) {

		// if (this.initialLoad) {
		// 	this.initialLoad = false;
		// 	return 
		// }
		let func = null;
		switch(toState.name) {
			case "home":
				// func = this.rootStore.contentfulStore.fetchHomeView();
				// this.rootStore.contentfulStore.fetchCaptchaImages();
				// this.rootStore.contentfulStore.fetchWorkList();

				break;
			
			case "shop":
				func = this.rootStore.dataStore.fetchProducts();
				break;

			case "article.view":
				// console.log("fetchin")
				func = this.rootStore.dataStore.fetchArticleView(toState.params.id);
				// this.rootStore.dataStore.fetchArticleImages(toState.params.id);

				break;

			default:
				func = null;

		}

		return func
	}



	@action.bound endTransition() {

		this.routeTransitioning = false;
		this.fadeOutLoader = true;
		setTimeout(this.endLoader, this.rootStore.uiStore.isMobile ? 610 : 900);

	}

	@action.bound startLoader(toState) {
		// const {name} = toState;
		
		this.movingTo = toState;
		// this.fadeOutLoader = false;
		
		// console.log("starting")
		// this.showLoader = true;
		// this.transitionIdx++
	}

	@action.bound endLoader() {
		this.showLoader = false;

		if (this.initialLoad) {
			this.initialLoad = false;
		}
	}

}


export default RouterStore