import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { useStore } from '../../../hooks/useStore'
import { ShopSubscribeSection } from '../../_pages/shop'
import { SiteLogoPlayer } from '../audio'
import HeaderActiveView from './HeaderActiveView'
import HeaderTitleSection from './HeaderTitleSection'
import HeaderToggleButton from './HeaderToggleButton'
import HeaderViewLinks from './HeaderViewLinks'


const Header = observer(() => {
    const {uiStore: {fontsReady, header__isOpen, window, setHeaderRef, setHeaderHeight}} = useStore();
    const headerRef = useRef(null);

    useEffect(() => {
        setHeaderRef(headerRef);


        setTimeout(setHeaderHeight, 40)
    }, [header__isOpen, window, fontsReady]);

    return (
        <div 
            ref={headerRef}
            className={header__isOpen ? "header open" : "header"}>
            
            <div className="flex header--top">
                <HeaderTitleSection/>
                <HeaderViewLinks/>
                <HeaderActiveView/>
                <ShopSubscribeSection/>
            </div>

            <div className="flex header--base">
                <SiteLogoPlayer/>
                <HeaderToggleButton/>
            </div>


            <style jsx>{`
                .header--top :global(.subscribe-section) {
                    width: ${(window.width - 875)}px;
                }

                @media (max-width: 769px), (pointer: coarse) {
                    .header--top {
                        height: ${window.height - 100}px;
                    }
                }
                
            `}</style>


            <style jsx>{`
                .header {
                    position: fixed;
                    top: 0;
                    contain: content;
                    z-index: 10;
                    background: white;
                    width: 100%;
                    transform: translate3d(0,calc(-100% + var(--header--height)),0);
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1);
                    transition-delay: .3s;
                }

                .header--top {
                    position: relative;
                    min-height: 250px;
                }

                .header--top :global(> div){
                    padding: 20px;
                }

                .header--top :global(.subscribe-section) {
                    height: 100%;
                    position: absolute;
                    right: 0;
                    padding: 0;
                    background: #f2f2f2;
                }

                .open {
                    transform: translate3d(0,0,0);
                }

                @media (max-width: 769px), (pointer: coarse) {
                    .header {
                        z-index: 11;
                        transform: translate3d(0,calc(-100% + 100px),0);
                    }

                    .open {
                        transform: translate3d(0,0,0);
                    }

                    .header--top :global(> div){
                        padding: 20px 30px;
                    }
    

                    .header--top {
                        overflow: scroll;
                        overscroll-behavior: none; 
                        display: block;
                    }

                    .header--base {
                        display: grid;
                    }

                    .header--top :global(.subscribe-section) {
                        width: 100%;
                        height: 350px;
                        padding: 0;
                    }
                }
                
            `}</style>
        </div>
    )
})




export default Header