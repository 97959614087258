import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore';
import { imageBuilder } from '../../../utils/sanity/image-builder';
import Image from '../general/Image';

const ArticleAuxContentImages = observer(({data}) => {
    if (!data.images || !data.images.length) return null
    const {uiStore: {openImageModal, isMobile}} = useStore();
    if (isMobile) return null

    let additionalImages;
    if (data.images.length >= 8) {
        additionalImages = data.images.length - 7;
    }

    return (
        <div className="article-images">
            {data.images.map((item, idx) => {
                if (idx > 6) return null

                if (item.image) {
                    return (
                        <div className="image-wrap">
                            <img 
                                onClick={() => openImageModal(idx)}
                                src={imageBuilder(item.image.asset.url).width(100).quality(85).auto("format")}  
                                alt=""
                            />
                        </div>
                    )
                } else if (item.asset) {
                    return (
                        <div className="image-wrap">
                            <img 
                                onClick={() => openImageModal(idx)}
                                src={imageBuilder(item.asset.url).width(100).quality(85).auto("format")}  
                                alt=""
                            />
                        </div>
                    )
                }
            })}
            {additionalImages ? <AdditionalImagesBox numImages={additionalImages} /> : null}

            <style jsx>{`
                .article-images {
                    text-align: left;
                }

                .image-wrap {
                    display: inline-block;
                    padding: 0 10px;
                    line-height: 0;
                    margin-bottom: 10px;
                    vertical-align: top;
                }

                img {
                    width: 60px;
                    cursor: pointer;
                }
            `}</style>
        </div>
    )
});

const AdditionalImagesBox = React.memo(({numImages}) => {
    const {uiStore: {openImageModal}} = useStore();

    return (
        <div onClick={() => openImageModal(7)}>
            <span >+{numImages}</span>
            <style jsx>{`
                div {
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    height: 60px;
                    width: 60px;
                    margin-left: 12px;
                    background: var(--color--gray-light);
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) translateY(1px);
                    color: rgba(0,0,0,.5);
                }
            `}</style>
        </div>
    )
})

export default ArticleAuxContentImages