import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { AudioPlayerFooter } from './components/general/audio'
import Header from './components/general/header/Header'
import { ImageModal } from './components/general/image-modal'
import { PopUpAd } from './components/general/pop-up-ad'
import { SearchResults } from './components/general/search'
import { Head } from './components/head'
import { useStore } from './hooks/useStore'
import { ActiveAppPage, Loading } from './pages'
// import { submitEmailToMailchimpList } from './utils/mailchimp/submitEmailToMailchimpList'

const App = observer(() => {
    const {dataStore: {initialDataReady}} = useStore();

    const pageContent = !initialDataReady  ? 
                        null
                        :

                        <div className="outer-wrap">

                            <div className="app__contents">
                                <Header/>
                                <PopUpAd/>
                                <ActiveAppPage/>
                                <ImageModal/>
                                <SearchResults/>
                                

                                <AudioPlayerFooter/>
                            </div>

                            <style jsx>{`
                                    .outer-wrap {
                                        position: relative;
                                    }
                                    .app__contents {
                                        animation: fade-in .45s ease;
                                        opacity: 0;
                                        animation-delay: .05s;
                                        animation-fill-mode: forwards;
                                        // transform: translateY(600px);
                                    }
                                    
                                    @keyframes fade-in {
                                        from {
                                            opacity: 0;
                                        }
                                        to {
                                            opacity: 1;
                                        }
                                    }
                                `}</style>
                        </div>


    return (
        <div>
            <Head/>
              
            {pageContent}

            <style jsx global>{`

                :root {
                    --color--black: #000;
                    --color--gray: rgba(0,0,0,.5);
                    --color--gray-lightest: #fcfcfc;
                    --color--gray-lightest: #fff;
                    --color--gray-light: #F2F2F2;
                    --color--gray-light-hover: #e2e2e2;
                    --color--active-gray: #808080;

                    --font-size-s: 15px;
                    --font-size-m: 24px;
                    --font-size-xl: 38px;

                    --header--height: 70px;
                }

                html, body {
                    margin: 0;
                    padding: 0;
                    background: var(--color--gray-light);
                    touch-action: manipulation;
                    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 

                }

                * {
                    box-sizing: border-box;
                    font-weight: 400;
                    line-height: 1.2;
                    font-family: "Apercu";
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

                strong {
                    font-weight: 700;
                }

                a {
                    text-decoration: none;
                    color: inherit;
                }

                p a {
                    text-decoration: underline;
                }

                h1,h2,h3,h4,h5,h6, p, li, ul {
                    margin: 0;
                }

                p {
                    line-height: 1.6;
                    margin-bottom: 18px;
                    font-size: var(--font-size-s);
                }

                .flex {
                    display: flex;
                }

                .flex--center {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .flex--center-y {
                    display: flex;
                    align-items: center;
                }

                .flex--space-between {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .text-xs {
                    font-size: 11px;
                }

                .text-s {
                    font-size: var(--font-size-s);
                }

                .text-m {
                    font-size: var(--font-size-m);
                }

                .text-xl {
                    font-size: var(--font-size-xl);
                }

                @font-face {
                    font-family: "Apercu";
                    src: url("/fonts/apercu-regular-pro.ttf");
                }

            `}</style>
        </div>
    )
})



export default App