import React from 'react'

const ArticleSubHeading = ({data}) => {
    if (!data.subheading) return null

    return (
        <div>

            <h3 className="text-m">
                {data.subheading}
            </h3>

            <style jsx>{`
                div {
                    max-width: 500px;
                    margin: 0px 360px 40px auto;
                    text-align: justify;
                }

                h3 {
                    line-height: 1.5;
                }

                @media (max-width: 769px), (pointer: coarse) {
                    div {
                        width: 100%;
                        margin: 0 auto 12px;
                        text-align: left;
                    }
                }
            `}</style>
        </div>

    )
}

export default ArticleSubHeading