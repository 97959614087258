import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import { convertDateString } from '../../../utils/general';
import { AudioPlayerReadLink } from '../../general/audio/subcomponents';


const HomeArticleColorBlock = observer(({data, size}) => {
    const {audioStore: {audio__activeDetails, playArticle}, uiStore: {header__filter__inactiveFilters, footer__showFilters}, routerStore: {routeTransitioning}} = useStore();


    const hasData = !!data.audioFile;

   
    const isInactive = footer__showFilters && !!!header__filter__inactiveFilters[data.category.title];
    let isPlayingThis = false;

    if (audio__activeDetails && data) {
        isPlayingThis = audio__activeDetails.title === data.title;
    }

    let onClick;
    if (!hasData) {
        onClick = null;
    } else if (isPlayingThis) {
        onClick = null;
    } else {
        onClick = () => playArticle(data);
    }

    const delay = (Math.random()).toFixed(3)

    return (
        <div 
            onClick={onClick}
            className={`${routeTransitioning ? "tr" : "block"} ${isInactive ? "inactive" : "block"}`}
        >

            <div className="block__interior">
                <div className="block__interior_text">
                    <ArticleTitle data={data}/>
                    <ArticleCategoryInfo data={data}/>
                    <Date date={data.publishedAt}/>
                </div>

                <ArticleButtonControls data={data}/>
                <AudioPlayingBars data={data}/>
                <BlockBackground data={data}/>
            </div>


            <style jsx>{`
                .block {
                    flex: ${size};
                    cursor: pointer;
                }

                .tr :global(.bl-bg) {
                    transition: opacity .4s ease, background .4s ease-out;
                    transition-delay: ${delay}s;
                }
            `}</style>

            <style jsx>{`
                .block {
                    position: relative;
                    height: 100px;
                    display: inline-block;
                    overflow: hidden;
                    contain: strict;
                    margin: 0 -1px;
                }


                .long-layout .block__interior_text{
                    display: grid;
                    grid-template-columns: 1fr 300px 100px;
                }

                .block__interior {
                    height: 100%;
                    width: 100%;
                    position: relative;
                    transition: transform .2s ease;
                    transition-delay: .2s;
                    padding: 12px 12px;

                }


                .block:hover .block__interior, .playing .block__interior {
                    transform: translateX(70px);
                }

                .inactive {
                    background: var(--color--gray-light);
                    pointer-events: none;
                    opacity: .1
                }

                .title, .category-items, :global(.article-button-controls){
                    transition: opacity .4s ease-out;
                }
                
                .tr .title, .tr .category-items, .tr :global(.article-button-controls){
                    opacity: 0;
                }

                .block.tr .block__interior {
                    transform: translateX(0px);
                }



                @media (max-width: 769px), (pointer: coarse) {
                    .block {
                        display: block;
                        opacity: 1 !important;
                        margin: 0;
                    }

                    .tr .title, .tr .category-items, .tr :global(.article-button-controls){
                        opacity: 1;
                    }

                    .tr :global(.bl-bg) {
                        opacity: 1;
                    }
                }
            `}</style>
        </div>
    )
});

const ArticleTitle = React.memo(({data}) => {
    return (
        <h3 className="title text-s">
            {data.title}
            <style jsx>{`
                .title {
                    position: relative;
                    z-index: 10;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    margin-right: 60px;
                }
            `}</style>
        </h3>
    )
})

const ArticleCategoryInfo = React.memo(({data}) => {
    return (
        <div className="category-items">
            <h4 className="category text-xs">{data.category.title}{data.subCategory ? " - " + data.subCategory.title : null} </h4>
        

            <style jsx>{`
                .category-items {
                    z-index: 1;
                    
                    position: absolute;
                    bottom: 12px;
                    color: rgba(0,0,0,.5);
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    transition: transform .3s ease;
                }

                .category {
                    margin-right: 10px;
                }              
            `}</style>
        </div>
    )
})
const ArticleButtonControls = (({data}) => {
    return (
        <div onClick={e => e.stopPropagation()} className="article-button-controls">
            {/* <AudioPlayerPlayPauseButton data={data}/> */}
            <AudioPlayerReadLink data={data}/>
            <style jsx>{`
                .article-button-controls {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    height: 100%;
                    width: 70px;
                    background: ${data.category.secondaryColor};
                    position: absolute;
                    top: 0;
                    left: -70px;
                }

                .article-button-controls > :global(a) {
                    height: 50%;
                    height: 100%;
                    width: 100%;
                }
            `}</style>
        </div>
    )
})


const AudioPlayingBars = observer(({data}) => {
    const {audioStore: {audio__activeDetails, audio__isPlaying}} = useStore();
    if (!audio__activeDetails  || !audio__isPlaying) return null
    if (audio__activeDetails.title !== data.title) return null

    return (
        <div className="bars">
            
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>

            <style jsx>{`
                .bar {
                    background: ${data.category.secondaryColor};
                }
            `}</style>  

            <style jsx>{`
                .bars {
                    height: 16px;
                    position: absolute;
                    bottom: 8px;
                    right: 14px;
                    width: 32px;
                    contain: strict;
                    transform: rotateZ(0);
                    overflow: hidden;
                    z-index: 1;
                }
                
                .bar {
                    bottom: 1px;
                    height: 14px;
                    position: absolute;
                    width: 10px;      
                    animation: sound 0ms -800ms linear infinite alternate;
                }
                
                @keyframes sound {
                    0% {
                        transform: translateY(13px);
                    }
                    100% {
                        transform: translateY(1px);
                    }
                }
                
                .bar:nth-child(1)  { 
                    left: 1px; 
                    animation-duration: 474ms;
       
                }
                .bar:nth-child(2)  { 
                    left: 11px; 
                    animation-duration: 433ms; 

                }

                .bar:nth-child(3)  { 
                    left: 21px; 
                    animation-duration: 407ms; 
                }

            `}</style>
        </div>
    )
})


const Date = React.memo(({date}) => {
    if (!date) return null

    return (
        <h4 className="date text-xs">
            {convertDateString(date)}
            <style jsx>{`
                h4 {
                    position: absolute;
                    top: 16px;
                    right: 12px;
                    z-index: 10;
                    color: rgba(0,0,0,.5);

                }
            `}</style>
        </h4>
    )
})



const BlockBackground = observer(({data}) => {
    const {uiStore: {homeArticleTransitionColor}} = useStore();

    return (
        <div className="bl-bg">
            <style jsx>{`
                div {
                    background: ${homeArticleTransitionColor || data.category.color};
                }

                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        background: ${data.category.color};
                    }
                }
            `}</style>

            <style jsx>{`
                div {
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            `}</style>
        </div>
    )
});



export default HomeArticleColorBlock