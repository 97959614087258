import chunk from 'lodash/chunk'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Loading } from '.'
import { ArticlePaginationLink, HomeArticleColorBlock, ScrollToTopButton } from '../components/_pages/home'
import { useStore } from '../hooks/useStore'

const Home = observer(() => {
    const {uiStore: {isMobile}, dataStore: {data: {articleListPagination}, dataReady: {articleListPagination: dataReady}}} = useStore();
    if (!dataReady) return <Loading/>

    return (
        <div>
            {articleListPagination.map((groupData, idx) => {
                return <ColorBlockGroup key={`cbg-${idx}`} data={groupData} isMobile={isMobile}/>
            })}
            
            <ArticlePaginationLink/>
            <ScrollToTopButton/>
            <style jsx>{`
                div {
                    line-height: 0;
                }
            `}</style>
        </div>
    )
});


const ColorBlockGroup = observer(({data, isMobile}) => {

    const groups = processGroup(data);
    return groups.map((group, groupIdx) => {
        let totalLength = 0;
        group.forEach((item) => {
            totalLength += item.title.length;
        });




        return (
            <div key={`grc-${groupIdx}`}className={isMobile ? "" : "flex"}>
                {group.map((article, idx) => {
                    let pct = article.title.length / totalLength;

                    if (pct < .23) {
                        pct = .23
                    } else if (pct > .75) {
                        pct = .75
                    }

                    let length = Math.round(pct * 100);

                    return <HomeArticleColorBlock size={length} data={article} key={`art-${article.title}-${idx}`}/>
                })}
            </div>
        );
    });
})

function processGroup(group) {
    let groups = chunk(group, 4)

    return groups
}

export default Home