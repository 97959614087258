import {action, makeObservable, observable, computed} from 'mobx'
import { sendAnalyticsArticlePlay } from '../utils/analytics';

class AudioStore {
    constructor(rootStore) {
        makeObservable(this);

        this.addKeyPressEventListeners();
        this.loadAudioSounds();
        this.rootStore = rootStore;

        window.AudioStore = this;
    }

    @observable audioEl = null;
    @observable audio__ready = false;
    @observable audio__time = 0;

    @observable audio__volume = 1;

    @observable audio__activeFile = null;
    @observable audio__activeDetails = null;
    @observable audio__isPlaying = false;
    @observable audio__progress = 0;

    // audioFiles = {
    //     // "click-on": "/audio/on-sound.mp3",
    //     // "click-off": '/audio/off-sound.mp3'
    //     "click-on": "/audio/fx/synth/HII_UI_synth_showheader.wav",
    //     "click-off": '/audio/fx/synth/HII_UI_synth_hideheader.wav',
    //     "filter-on": "/audio/fx/synth/HII_UI_synth_filteron.wav",
    //     "filter-off": "/audio/fx/synth/HII_UI_synth_filteroff.wav",
    //     "show-filters": "/audio/fx/synth/HII_UI_synth_showfilter.wav",
    //     "hide-filters": "/audio/fx/synth/HII_UI_synth_hidefilter.wav",

    //     "play": "/audio/fx/synth/HII_UI_synth_play.wav",
    //     "pause": "/audio/fx/synth/HII_UI_synth_pause.wav",
    //     "fast-forward": "/audio/fx/synth/HII_UI_synth_fastforward.wav",
    //     "rewind": "/audio/fx/synth/HII_UI_synth_rewind.wav",

    //     "logo-playthrough": ""
    // };

    audioFiles = {
        "click-on": "/audio/fx/real/HII_UI_realsounds_showheader.wav",
        "click-off": '/audio/fx/real/HII_UI_realsounds_hideheader.wav',
        "filter-on": "/audio/fx/real/HII_UI_realsounds_filteron.wav",
        "filter-off": "/audio/fx/real/HII_UI_realsounds_filteroff.wav",
        "show-filters": "/audio/fx/real/HII_UI_realsounds_showfilter.wav",
        "hide-filters": "/audio/fx/real/HII_UI_realsounds_hidefilter.wav",

        "play": "/audio/fx/real/HII_UI_realsounds_play.wav",
        "pause": "/audio/fx/real/HII_UI_realsounds_pause.wav",
        "fast-forward": "/audio/fx/real/HII_UI_realsounds_fastforward.wav",
        "rewind": "/audio/fx/real/HII_UI_realsounds_rewind.wav",

        "logo-playthrough": ""
    };

    // audioFiles = {
    //     "click-on": "/audio/fx/synth/HII_UI_synth_showheader.wav",
    //     "click-off": '/audio/fx/synth/HII_UI_synth_hideheader.wav',
    //     "filter-on": "/audio/fx/synth/HII_UI_synth_filteron.wav",
    //     "filter-off": "/audio/fx/synth/HII_UI_synth_filteroff.wav",
    //     "show-filters": "/audio/fx/synth/HII_UI_synth_showfilter.wav",
    //     "hide-filters": "/audio/fx/synth/HII_UI_synth_hidefilter.wav",

    //     "play": "/audio/fx/synth/HII_UI_synth_play.wav",
    //     "pause": "/audio/fx/synth/HII_UI_synth_pause.wav",
    //     "fast-forward": "/audio/fx/synth/HII_UI_synth_fastforward.wav",
    //     "rewind": "/audio/fx/synth/HII_UI_synth_rewind.wav"
    // }

    audioSources = {
        "click-on": null,
        "click-off": null,
        "filter-on": null,
        "filter-off": null,
        "show-filters": null,
        "hide-filters": null,

        "play": null,
        "pause": null,
        "fast-forward": null,
        "rewind": null,

        "logo-playthrough": null
    }
    // @observable audio__activeClosedCaption = null;

    @action.bound updateVolume(volumeIndex) {
        if (!this.audioEl) return null
        
        this.audio__volume = (volumeIndex + 1) * .2;
        this.audioEl.volume = this.audio__volume;
        this.rootStore.uiStore.updateVolumeButtons(volumeIndex);
    }

    @action.bound playSound(name) {
        if (this.rootStore.uiStore.isMobile) return 

        this.audioSources[name].pause()
        this.audioSources[name].currentTime = 0;

        this.audioSources[name].play()
    }

    @action.bound setAndLoadLogoPlaythrough(audioSrc) {
        const audio = new Audio();
        audio.src = audioSrc;
        audio.volume = .1
        this.audioSources['logo-playthrough'] = audio
    }

    @action.bound playLogoAudio() {
        this.playSound("logo-playthrough");
    }


    loadAudioSounds() {
        Object.keys(this.audioFiles).forEach((key) => {
            const audio = new Audio();
            audio.src = this.audioFiles[key];
            audio.volume = .1
            this.audioSources[key] = audio
        })
    }

    @computed get totalTime() {
		if (!this.audio__ready) return

		// console.log(this.audio.duration)
		const time = this.audioEl.duration

		// Hours, minutes and seconds
		var hrs = ~~(time / 3600);
        hrs = hrs < 10 ? "0"+hrs : hrs;
        
		var mins = ~~((time % 3600) / 60);
        mins = mins < 10 ? "0"+mins : mins;

		var secs = ~~(time % 60);
        secs = secs < 10 ? "0"+secs : secs;



		// Output like "1:01" or "4:03:59" or "123:03:59"
		var ret = "";

		if (hrs > 0) {
		    ret += "" + hrs + ":" 
		}

		ret += "" + mins + ":" 
		ret += "" + secs + "";

		return ret
	}

	@computed get currentTime() {
		if (!this.audio__ready) return null
		// if (!this.time) return  "0m 0s"

		const time = this.audio__time

        // console.log("yeah: ", time)
		var hrs = ~~(time / 3600);
        hrs = hrs < 10 ? "0"+hrs : hrs;
        
		var mins = ~~((time % 3600) / 60);
        mins = mins < 10 ? "0"+mins : mins;

		var secs = ~~(time % 60);
        secs = secs < 10 ? "0"+secs : secs;


		var ret = "";

		if (hrs > 0) {
		    ret += "" + hrs + ":" 
		}

		ret += "" + mins + ":" 
		ret += "" + secs + "";

		return ret
	}




	@action.bound computeTime() {
        if (!this.audioEl) return
        this.audio__time = this.audioEl.currentTime;
	}

    initMeasureTimeInterval() {

		this.interval = setInterval(this.computeTime, 100);

	}

    @action.bound registerAudioElement(el) {
        this.audioEl = el;
        this.audioEl.onloadedmetadata = function() {
			this.audio__ready = true;
		}.bind(this);
    }

    @action.bound setAudioFile(file) {
        this.audio__progress = 0;
        this.audio__activeFile = file;
    }

    @action.bound setActiveAudioDetails(data) {
        this.audio__activeDetails = data;
    }

    @action.bound playArticle(articleData, playingNext) {
        // console.log("playing article.......", articleData)

        const {audioFile} = articleData;

        if (this.audio__isPlaying && articleData.title === this.audio__activeDetails.title) {
            this.pauseAudio();
            return
        }
        this.playSound("play");

        this.audio__ready = false;
        this.audio__isPlaying = true;

        this.setAudioFile(audioFile);
        this.setActiveAudioDetails(articleData)
        this.initMeasureTimeInterval();
        sendAnalyticsArticlePlay(articleData);

    }

    @action.bound updateAudioProgress(e) {
        let pct = e.target.currentTime / e.target.duration;

        this.audio__progress = pct;
        // this.updateAudioTime()
    }

    @action.bound togglePausePlay() {
        // console.log("toggling...")
        if (this.audio__activeDetails && !this.audioEl) return

        if (!this.audioEl) {
            if (this.rootStore.routerStore.current.name === "article.view") {
                this.playPageArticle();
            } else {
                this.playFirstArticle();
            }
            return
        }
        // console.log("doing this")

        if (!!this.audioEl.paused) {
            // console.log("a")

            this.playAudio()
        } else {

            // console.log("b")


            this.pauseAudio();
        }
    }

    @action.bound playAudio() {
        if (!this.audioEl) return
        this.playSound("play");

        this.audioEl.play()
        this.audio__isPlaying = true;
    }


    @action.bound pauseAudio() {
        if (!this.audioEl) return
        this.playSound("pause");

        this.audioEl.pause();
        this.audio__isPlaying = false;
    }

    @action.bound playFirstArticle() {
        if (!this.rootStore.dataStore.dataReady.articleListPagination) return

        this.playArticle(this.rootStore.dataStore.data.articleListPagination[0][0]);
    }  
    
    @action.bound playPageArticle() {
        const {routerStore, dataStore} = this.rootStore;
        const slug = routerStore.current.params.id;
    
        const article = dataStore.data.articleView[slug];

        this.playArticle(article);
    }

    @action.bound playNextArticle(nextArticle) {
        const currentAudio = nextArticle || this.audio__activeDetails;
        
        if (!currentAudio.nextArticle) {
            console.error("No next article data found");
            this.audio__activeDetails = null;
            this.audio__isPlaying = false;
            return 
        };
        const {slug} = currentAudio.nextArticle

        const articleData = this.rootStore.dataStore.articleListDataBySlug[slug];
        // console.log(articleData, this.rootStore.dataStore.data.articleList, slug)
        // return

        if (articleData.audioFile) {
            this.playArticle(articleData)
        } else {
            this.playNextArticle(articleData)
        }
        // console.log(this.audio__activeDetails)
    }
    
    @action.bound seekAudio(e) {
        if (!this.audioEl) return

        let x, offsetPct;
        if (this.rootStore.uiStore.isMobile) {
            x = e.clientX;
            offsetPct = x / this.rootStore.uiStore.window.width
        } else {
          x = e.clientX - 150; // subtract padding left
          offsetPct = x / (this.rootStore.uiStore.window.width - 440);
        }
        
        this.audioEl.currentTime = offsetPct * this.audioEl.duration;
        this.computeTime()
    }

    @action.bound fastForwardAudioTrack() {
        if (!this.audioEl || !this.audio__activeFile) return
        this.playSound("fast-forward")

        this.audioEl.currentTime += 10;
        this.computeTime()
            // audio_track.currentTime += 10 // ff 10 sec
    }

    // @action.bound updateAudioTime() {
    //     // this.audio__time = this.audioEl.currentTime;
    // }

    @action.bound rewindAudioTrack() {
        if (!this.audioEl || !this.audio__activeFile) return
        this.playSound("rewind")
        // alert("rw");
        this.audioEl.currentTime -= 10;
        this.computeTime();


            // audio_track.currentTime -= 10 // ff 10 sec
    }

    addKeyPressEventListeners() {
        document.addEventListener("keydown", this.handleKeypress)
    }


    @action.bound handleKeypress(e) {
        
        if (this.rootStore.uiStore.footer__showSearch) {
            if (e.keyCode === 13) {
                this.rootStore.dataStore.performSearch();
            }
            return 
        }

        switch(e.keyCode) {
            case 37: 
                // left arrow
                this.rewindAudioTrack();
                break;
            case 39:
                //right arrow
                this.fastForwardAudioTrack();

                break;
            case 32:
                // space bar
                
                e.preventDefault();
                if (this.audio__activeFile) {
                    this.togglePausePlay()
                }
                break;

            default: 
                return
        } 
    }
}

export default AudioStore