import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../../hooks/useStore';
import { Link } from '../../link';

const AudioPlayerReadLink = observer(({data}) => {
    const {uiStore: {readButtonActive}, audioStore: {audio__activeDetails}} = useStore();
    const slug = data.slug

    return (
        <Link to="article.view" params={{id: slug}}>
            <div 
                className={`btn read flex--center ${readButtonActive ? "active" : ""}`}
            >

                <div className="btn__inner">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.62 25.01">
                        <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_2-2" data-name="Layer 2">
                        <g className="cls-1">
                        <rect className="cls-2" x="1.07" y="1.07" width="19.48" height="22.87" rx="2.01"/>
                        <line className="cls-2" x1="4.88" y1="5.73" x2="15.89" y2="5.73"/>
                        <line className="cls-2" x1="4.88" y1="9.96" x2="15.89" y2="9.96"/>
                        <line className="cls-2" x1="4.88" y1="14.2" x2="15.89" y2="14.2"/>
                        <line className="cls-2" x1="4.88" y1="18.43" x2="15.89" y2="18.43"/>
                        </g>
                        </g>
                        </g>
                    </svg>
                    <h6 className="read-txt text-s">Read</h6>
                </div>



                <style jsx>{`
                    .btn {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        transition: background .18s ease, opacity .18s ease;
                        cursor: pointer;
                    }

                    .disabled {
                        opacity: .3;
                        pointer-events: none;
                    }

                    .active {
                        background: var(--color--gray);
                    }

                    .btn__inner {
                        text-align: center;
                        position: relative;
                        top: 5px;
                    }

                    .read-txt {
                        margin-top: 4px;
                        color: rgba(0,0,0,.5);    
                    }



                    .active .cls-2{
                        stroke: var(--color--gray-lightest);
                    }
                    
                    .btn:hover {
                        background: var(--color--active-gray);
                    }


                    .btn:hover .cls-2 {
                        stroke: white;
                    }


                    svg {
                        height: 18px;
                    }
                    .cls-2{
                        transition: stroke .18s ease;
                        fill:none;
                        stroke: var(--color--gray);
                        stroke-linecap:round;
                        stroke-miterlimit:10;
                        stroke-width:2.52px;
                    }

                    // @media(pointer:coarse) {
                    //     .btn {
                    //         background: var(--color--gray-light) !important;
                    //     }

                    //     .active {
                    //         background: var(--color--gray) !important;
                    //     }
                    // }
                `}</style>
            </div>
        </Link>
    )
})

export default AudioPlayerReadLink