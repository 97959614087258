import {action, observable,computed, makeObservable} from "mobx"
import { client } from "../utils/sanity/sanity-client";

class DataStore {
    constructor(rootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
        this.fetchData();
    }

    @observable dataReady = {
        logoAudioConfig: false,
        general: false,
        articleList: false,
        articleListPagination: false,
        allListsLoaded: false,
        articleCategories: false,
        articleView: {},
        articleImages: {},
        products: false,
        searchResults: false
        // teamMembers: false
    }

    data = {
        logoAudioConfig: {},
        general: {},
        articleList: {},
        articleListPagination: [],
        articleCategories: {},
        articleView: {},
        articleImages: {},
        products: [],
        searchResults: []
        // teamMembers: {}
    }

    @action.bound fetchData() {
        this.fetchLogoAudioConfig();
        this.fetchGeneralData();
        this.fetchArticleList();
        this.fetchArticleCategories();
        // this.fetchTeamMembers();
    }

    @computed get initialDataReady() {
        return this.dataReady.general && this.dataReady.articleListPagination && this.dataReady.logoAudioConfig;
    }

    @computed get searchResultsTotals() {
        const totals = {'title': 0, 'author': 0, 'content': 0};
        if (!this.dataReady.searchResults) return totals;
        totals['title'] = this.data.searchResults?.byTitle.length;
        totals['author'] = this.data.searchResults?.byAuthor.length;
        totals['content'] = this.data.searchResults?.byContent.length;
        // const {uiStore: {search__activeFilters},dataStore: {data: {searchResults}}} = useStore();
        return totals;
    }


    // @computed get splitTeamMembers() {
    //     if (!this.dataReady.teamMembers) return [[], []];
    //     const half = Math.ceil(this.data.teamMembers.length / 2);    

    //     const firstHalf = this.data.teamMembers.slice(0, half);
    //     const secondHalf = this.data.teamMembers.slice(-half);
        
    //     return [firstHalf, secondHalf];
    // }

    @computed get articleListDataBySlug() {
        if (!this.dataReady.articleListPagination) return {};
        const data = {};

        this.data.articleListPagination.forEach(group => {
            group.forEach(item => {
                data[item.slug] = item;
            })
        });

        return data;
    }

    @computed get productDataByProductID() {
        if (!this.dataReady.products) return {}
        const data = {};

        this.data.products.forEach(product => {
            data[product.shopifyProductID] = product;
        })

        return data;
    }

    @action.bound setData(key, data) {
        if (!key || !data) return

        this.data[key] = data;
        this.dataReady[key] = true;
    }

    @action.bound setArticleList(data) {
        this.dataReady.articleListPagination = data[0].slug;
        this.data.articleListPagination.push(data);

        if (!data[data.length - 1].nextArticle) {
            this.dataReady.allListsLoaded = true;
        }
    }

    @action.bound setArticleView(key, data) {
        if (!key || !data) return

        this.data.articleView[key] = data;
        this.dataReady.articleView[key] = data;
    }

    @action.bound setArticleImages(key, data) {
        if (!key || !data) return

        this.data.articleImages[key] = data;
        this.dataReady.articleImages[key] = data;
    }

    async fetchLogoAudioConfig() {
        const query = `*[_type == "audioConfig"][0]{
            letters {
                logoPlaythrough{
                    ...,
                    audio {
                        asset -> {
                            url
                        }
                    }
                },
                HOne {
                    ...,
                    audio {
                        asset -> {
                            url
                        }
                    }
                },
                HTwo {
                    ...,
                    audio {
                        asset -> {
                            url
                        }
                    }
                },
                HThree {
                    ...,
                    audio {
                        asset -> {
                            url
                        }
                    }
                },
                IOneOne {
                    ...,
                    audio {
                        asset -> {
                            url
                        }
                    }
                },
                IOneTwo {
                    ...,
                    audio {
                        asset -> {
                            url
                        }
                    }
                },
                ITwoOne {
                    ...,
                    audio {
                        asset -> {
                            url
                        }
                    }
                },
                ITwoTwo {
                    ...,
                    audio {
                        asset -> {
                            url
                        }
                    }
                }
            }
        }`
        const params = {};
        try {
            const data = await client.fetch(query, params);
            this.setData("logoAudioConfig", data);
            
            this.rootStore.audioStore.setAndLoadLogoPlaythrough(data.letters.logoPlaythrough.audio.asset.url);

        } catch (e) {
            console.error(e);
        } 
    }

    async fetchGeneralData() {
        const query = `*[_type == "general"][0] {
            ...,
            popUp {
                ...,
                "image": image.asset -> {
                    url,
                    "aspectRatio": metadata.dimensions.aspectRatio
                },                      
                cta {
                    ...,
                    link[] {  
                        _type == 'internalLink' => @-> {
                            slug,
                            link,
                            _type
                        },
                        _type != 'internalLink' => @     
                    }
                }
            }
        }`
        const params = {};
        try {
            const data = await client.fetch(query, params);
            this.setData("general", data);
        } catch (e) {
            console.error(e);
        } 
    }

    @action.bound clearArticleList() {
        if (!this.dataReady.articleListPagination) return
        
        this.data.articleListPagination = [this.data.articleListPagination[0]];
        this.dataReady.articleListPagination = this.data.articleListPagination[0][0].slug
        this.dataReady.allListsLoaded = false;
    }

    async fetchArticleCategories() {
        const query = `*[_type == "articleCategory"]{
            title,
            'color': color.hex,
            'secondaryColor': secondaryColor.hex

        }`
        const params = {};
        try {
            const data = await client.fetch(query, params);
            this.setData("articleCategories", data);
        } catch (e) {
            console.error(e);
        } 
    }

    // async fetchTeamMembers() {
    //     const query = `*[_type == "teamMember" && isContributor != true]{
    //         name,
    //         website
    //     }`
    //     const params = {};
    //     try {
    //         const data = await client.fetch(query, params);
    //         this.setData("teamMembers", data)
    //     } catch (e) {
    //         console.error(e);
    //     } 
    // }

    @action.bound async fetchArticleList(page = 0, amount = 44) {
        const offset = page * amount;

        // console.log('fetching!!', offset, offset + amount)
        const query = `*[_type == "article" && isUnlisted != true] | order(publishedAt desc) {
            title,
            'slug': slug.current,
            "audioFile": audioFile.asset -> {
                url
            },
            category -> {
                title, 
                'color': color.hex,
                'secondaryColor': secondaryColor.hex
            },
            subCategory -> {
                title
            },
            publishedAt,
            'nextArticle': *[_type=="article" && publishedAt < ^.publishedAt && isUnlisted != true] | order(publishedAt desc)[0] {
                'slug': slug.current
            }
        }[${offset}...${offset + amount}]`

        const params = {};

        try {
            const data = await client.fetch(query, params);
            this.setData("articleList", data);
            // console.log("fetching data!!", data)
            this.setArticleList(data);
        } catch (e) {
            // console.log("error")
            console.error(e);
        } 
    }

    @action.bound async fetchProducts() {
        const query = `*[_type == "product"] | order(_createdAt desc) {
            title,
            'slug': slug.current,
            price, 
            shopifyVariantID,
            shopifyProductID,
            description,
            specs,
            "images": images[].asset -> {
                url,
                "aspectRatio": metadata.dimensions.aspectRatio
            }
        }`

        const params = {};

        try {
            const data = await client.fetch(query, params);
            this.setData("products", data);
        } catch (e) {
            console.error(e)
        }
    }

    async fetchArticleImages(slug) {
        if (!slug) return

        const query = `*[_type == "article" && slug.current == $slug][0] {
            'images': body[_type == 'image']{
                'asset': asset -> {
                    url,
                    "aspectRatio": metadata.dimensions.aspectRatio
                }
            }
        }`
        const params = {slug};

        try {
            const data = await client.fetch(query, params);

            this.setArticleImages(slug, data);
            // console.log('got article view: ', data, query, params);

        } catch (e) {
            console.error(e);
        } 
    }
    
    async fetchArticleView(slug) {
        if (!slug) return

        const query = `*[_type == "article" && slug.current == $slug][0]{
            ...,
            'images': body[_type == 'image' || _type == 'captionedImage']{
                'asset': asset -> {
                    url,
                    "aspectRatio": metadata.dimensions.aspectRatio
                },
                caption, 
                image {
                    'asset': asset -> {
                        url,
                        "aspectRatio": metadata.dimensions.aspectRatio
                    }
                }
            },
            body[]{
                ...,
                _type == 'image' => {
                'asset': asset -> {
                        url,
                        "aspectRatio": metadata.dimensions.aspectRatio

                    }
                },
                _type == 'captionedImage' => {
                    image {
                        'asset': asset -> {
                            url,
                            "aspectRatio": metadata.dimensions.aspectRatio
                        }
                    }
                    
                },
                _type == 'file' => {
                    'asset': asset -> {
                            url    
                        }
                    }
            },
            "audioFile": audioFile.asset -> {
                url
            },
            pinnedContent[]{
                ...,
                _type == 'file' => {
                    'asset': asset -> {
                            url    
                        }
                    }
            },
            'author': author -> {
                name, 
                bio,
                website,
                isContributor,
                'image': image.asset -> {
                    url,
                    "aspectRatio": metadata.dimensions.aspectRatio
                }
            },
            'category': category -> {
                title,
                'color': color.hex,
                'secondaryColor': secondaryColor.hex
            },
            subCategory -> {
                title
            },
            'nextArticle': *[_type=="article" && publishedAt < ^.publishedAt && isUnlisted != true] | order(publishedAt desc)[0] {
                title,
                'slug': slug.current,
                'category': category -> {
                    color,
                    'color': color.hex
                }
            }
        }`
        const params = {slug};

        try {
            const data = await client.fetch(query, params);
            this.setArticleView(slug, data);

        } catch (e) {
            console.error(e);
        } 
    }


    @action.bound async performSearch() {
        const searchStr = this.rootStore.uiStore.search__query;

        if (!this.rootStore.uiStore.canSearch || searchStr === this.dataReady.searchResults) return 
        this.rootStore.audioStore.playSound("filter-on");

        const params = {};

        const queryParts = `
            title,
            'slug': slug.current,
            'category': category -> {
                title,
                color,
                'color': color.hex,
                'secondaryColor': secondaryColor.hex
            },
            "audioFile": audioFile.asset -> {
                url
            },
            publishedAt,
            'author': author -> {
                name
            }
        `
        const splitQuery = `
        
            {
                "byTitle": *[_type=="article" && title match "**${searchStr}**" && isUnlisted != true] {
                    ${queryParts}
                },
                "byAuthor": *[_type=="article" && author._ref in *[_type=="teamMember" && name match "**${searchStr}**" && isUnlisted != true]._id] {
                    ${queryParts}
                },
                "byContent": *[_type=="article" && body[].children[].text match "${searchStr}" && isUnlisted != true] {
                    ${queryParts}
                }[0...5]
            }
        `


        try {
            const data = await client.fetch(splitQuery, params);
            this.rootStore.uiStore.blurSearchInput();
            this.rootStore.uiStore.activateAllSeachFilters();
            this.setSearchResults(searchStr, data);
        } catch (e) {
            console.error(e);
        }
    }

    @action.bound setSearchResults(searchStr, data) {
        this.dataReady.searchResults = searchStr;
        this.data.searchResults = data;
    }

    @action.bound clearSearchResults() {
        this.dataReady.searchResults = false;
        this.rootStore.audioStore.playSound("filter-off");

        this.data.searchResults = [];
    }
}

export default DataStore