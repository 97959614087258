import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import { textSerializers } from '../../../utils/sanity/text-serializer'

const BlockContent = require('@sanity/block-content-to-react')


const HeaderAboutView = observer(() => {
    const {dataStore: {data: {general: {hiiInfo, otbInfo}}, dataReady: {general: dataReady}}} = useStore();
    if (!dataReady) return null;
    
    return (
        <div className="about-view flex">
            <div className="hii-info">
                <BlockContent blocks={hiiInfo} serializers={textSerializers}/>
            </div>


            <style jsx>{`
                .flex > div {
                    flex: 1;
                    padding: 0 12px;
                }

                .hii-info :global(p:last-of-type) {
                    margin-bottom: 0;
                }
                @media (pointer: coarse) {
                    .about-view {
                        display: block;
                    }

                    .flex > div {
                        padding: 0;
                    }

                    .hii-info {
                        margin-bottom: 40px;
                    }
                }

            `}</style>
        </div>
    )
})

export default HeaderAboutView