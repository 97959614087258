import React, {useState} from 'react'
import { imageBuilder } from '../../../utils/sanity/image-builder'
import { textSerializers } from '../../../utils/sanity/text-serializer'
import { ShopItemImageCarouselButtons, ShopItemImageCarouselDots } from './shop-item-ui'

const BlockContent = require('@sanity/block-content-to-react')

const ShopItemImageCarousel = React.memo(({data}) => {
    const [activeIdx, setActiveIndex] = useState(0);
    
    if (!data.images || !data.images.length) return null;

    const canIncrement = activeIdx < data.images.length;
    const canDecrement = activeIdx > 0

    const increment = () => {
        if (!canIncrement) return 
        let newIndex = activeIdx + 1;
        
        setActiveIndex(newIndex)
    }

    const decrement = () => {
        if (!canDecrement) return  
        let newIndex = activeIdx - 1;
        
        setActiveIndex(newIndex)
    }

    const goToIndex = (newIndex) => {
        setActiveIndex(newIndex)
    }

    const carouselStyle = {
        transform: `translate3d(-${(activeIdx / (data.images.length + 1)) * 100}%, 0, 0)`
    };


    return (
        <div className="carousel">

            <div 
                style={carouselStyle}
                className="carousel__inner flex"
            >
                {data.images.map(image => {
                    return <CarouselSlide image={image}/> 
                })}
                <DescriptionSlide data={data}/>
            </div>

            <ShopItemImageCarouselButtons 
                increment={increment} 
                decrement={decrement}
                canIncrement={canIncrement}
                canDecrement={canDecrement}
            />

            <ShopItemImageCarouselDots goToIndex={goToIndex} activeIdx={activeIdx} images={data.images}/>

            <style jsx>{`
                .carousel {
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                }

                .carousel__inner {
                    width: ${(data.images.length + 1) * 100}%;
                    height: 100%;
                    transition: transform .5s ease;
                    transition-delay: .15s;
                }
            `}</style>
        </div>
    )
})

const CarouselSlide = React.memo(({image}) => {
    return (
        <div className="carousel-slide">
            <img src={imageBuilder(image.url).width(1200).quality(85).auto("format")} alt="" />
            <style jsx>{`
                .carousel-slide {
                    position: relative;
                    height: 100%;
                    flex: 1;
                }

                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

            `}</style>
        </div>
    )
})

const DescriptionSlide = React.memo(({data}) => {
    
    return (
        <div className="slide flex--center">
            <div className="description__outer">
                <div className="description">
                    <h6 className="label">Info:</h6>
                    <BlockContent blocks={data.description} serializers={textSerializers}/>
                </div>
                <div className="description">
                    <h6 className="label">Specs:</h6>
                    <BlockContent blocks={data.specs} serializers={textSerializers}/>
                </div>
            </div>



            <style jsx>{`
                .slide {
                    height: 100%;
                    flex: 1;
                }
                
                .description__outer {
                    width: 100%;
                }

                .description {
                    width: 70%;
                    margin: 20px auto;
                }
                .label {
                    margin-bottom: 12px;
                }
            `}</style>
        </div>
    )
})

export default ShopItemImageCarousel