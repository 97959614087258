import { observer } from 'mobx-react-lite'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useStore } from '../../../hooks/useStore'

const ArticlePageHeader = ({data}) => {

    return (
        <div className="flex">
            <ArticlePageTitle data={data}/>
            <ArticlePlayButton data={data}/>

            <style jsx>{`
                div {
                    background: ${data.category.color};
                    height: 100px;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        height: 50px;
                        position: sticky;
                        top: 0;
                        z-index: 100;
                    }
                }
            `}</style>
        </div>
    )
}
const ArticlePlayButton = observer(({data}) => {
    const {audioStore: {playArticle, togglePausePlay, audio__activeDetails, audio__isPlaying}} = useStore()
    let isPlayingThis = false;
    const hasData = !!data.audioFile;

    if (audio__activeDetails && data) {
        isPlayingThis = audio__activeDetails.title === data.title;
    }

    const onClick = isPlayingThis ?  togglePausePlay : () => playArticle(data);
    
    return (
        <div 
            className={`btn play-pause flex--center ${!hasData ? "disabled" : ""}`}
            onClick={!hasData ? null : onClick}>
        
            <div className="btn__inner">
                {audio__isPlaying && isPlayingThis ? <PauseIcon/> : <PlayIcon/>}
                <h6 className="listen text-s">Listen</h6>
            </div>

            <style jsx>{`
                .btn {
                    width: 70px;
                    height: 100%;
                    cursor: pointer;
                    background: ${data.category.secondaryColor};
                    transition: background .18s ease, opacity .18s ease;
                    cursor: pointer;
                }

                .btn__inner {
                    text-align: center;
                    position: relative;
                    top: 5px;
                }

                .listen {
                    margin-top: 4px;
                    color: rgba(0,0,0,.5);    
                }

                .disabled {
                    opacity: .15;
                    pointer-events: none;
                }
                
                .btn:hover {
                    background: var(--color--active-gray);
                }

                .btn.play-pause:hover :global(path), .btn.play-pause:hover :global(rect) {
                    fill: white !important;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    .listen {
                        display: none;
                    }
                }

            `}</style>
        </div>
    )
})

const PlayIcon = React.memo(() => {
    return (
        <svg viewBox="0 0 14.08 15.91">
                <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                <path className="cls-1" d="M0,8V1.14a1.14,1.14,0,0,1,1.71-1l5.9,3.41L13.52,7a1.14,1.14,0,0,1,0,2L7.61,12.35l-5.9,3.41a1.14,1.14,0,0,1-1.71-1Z"/>
                </g>
                </g>
                
                <style jsx>{`
                    svg {
                        height: 14px;
                    }
                    .cls-1 {
                        fill: var(--color--gray);
                    }
                `}</style>
            </svg>
    )
})

const PauseIcon = React.memo(() => {
    return (
        <svg viewBox="0 0 20 26">
            <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
            <rect className="cls-1" width="7" height="26" rx="1.72"/>
            <rect className="cls-1" x="13" width="7" height="26" rx="1.72"/>
            </g>
            </g>
            <style jsx>{`
                svg {
                    height: 14px;
                }
                .cls-1 {
                    fill: var(--color--gray);
                }
            `}</style>
        </svg>
    )
})

const ArticlePageTitle = ({data}) => {
    const [measuredWidths, setMeasured] = useState([]);

    const titleIsOversized = measuredWidths.length && (measuredWidths[1] > measuredWidths[0]);
    const shouldTick = titleIsOversized;
    const barRef = useRef(null);
    const titleRef = useRef(null);

    const setMeasurement = () => {
        const barRect = barRef.current.getBoundingClientRect();
        const titleRect= titleRef.current.getBoundingClientRect();

        setMeasured([barRect.width, titleRect.width]);
    }

    useLayoutEffect(setMeasurement, []);


    return (
        <div 
            ref={barRef}
            className="flex--center-y">
            <h2 
                ref={titleRef}
                className={shouldTick ? "ticking title text-xl" : "title text-xl"}>
                        <span>{data.title}</span>
                    {
                        titleIsOversized ?
                        <span>{data.title}</span> :
                        null
                    }
            </h2>
            {/* <h2 className="text-xl">{data.title}</h2> */}
            <style jsx>{`
                .ticking {
                    will-change: transform;
                    animation: tick ${measuredWidths[1] / 90}s linear infinite;
                    animation-delay: .45s; 
                }

                @media (pointer: coarse), (max-width: 769px) {
                    .ticking {
                        animation: tick ${measuredWidths[1] / 40}s linear infinite;
                        animation-delay: .45s; 
                    }
                }

                @keyframes tick {
                    from {
                        transform: translate3d(0, 0, 0);
                    }

                    to {
                        transform: translate3d(calc(-50% - 35px), 0, 0);
                    }
                }
            `}</style>

            <style jsx>{`
                div {
                    padding-left: 50px;
                    flex: 1;
                    height: 100%;
                    overflow: hidden;
                    contain: strict;
                }

                .title > span:nth-of-type(2) {
                    margin-left: 70px;
                }

                h2 {
                    white-space: nowrap;
                    position: relative; 
                    top: 2px;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        padding-left: 20px;
                    }

                    .title {
                        font-size: 15px;
                    }
                }
            `}</style>
        </div>
    )
}


export default ArticlePageHeader