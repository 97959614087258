import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore'

const CartSubtotal = observer(() => {
    const {cartStore: {subTotalPrice,toggleCart, showCartContents}} = useStore();    

    return (
        <div 
            className={`subtotal flex--center ${showCartContents ? "open": "closed"}`}
            onClick={toggleCart}
        >
            <div className="flex--space-between">
                <h5 className="text-s">Cart</h5>
                <h5 className="text-s">${Math.round(subTotalPrice)}</h5>
            </div>            

            <style jsx>{`
                div {
                    height: 100%;
                    width: 100%;
                    contain: strict;
                    transition: background .15s ease;
                }
                
                .closed {
                    background: var(--color--gray-lightest);
                }

                .closed:hover {
                    background: var(--color--gray-light-hover);
                }

                .open {
                    background: var(--color--active-gray);
                }

                .subtotal {
                    padding: 0 12px;
                }

                .open h5{
                    color: var(--color--gray-lightest);
                }
            `}</style>

        </div>
    )
})

export default CartSubtotal