import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react'
import { useStore } from '../../../hooks/useStore';
import { submitEmailToMailchimpList } from '../../../utils/mailchimp/submitEmailToMailchimpList';

const ShopSubscribeSection = ({fullWidth}) => {
    return (
        <div className={fullWidth ? "subscribe-section fw" : "subscribe-section"}>

            <div className="text-container flex--center">
                <div className="text-center">
                    <h5 className="text-m">Subscribe to our email list and get updates on new articles</h5>
                </div>
            </div>


            <SubscribeInputSection/>

            <style jsx>{`
                .subscribe-section {
                    position: relative;
                    contain: strict;
                    height: 400px;
                    background: #f5f4eecc;
                }

                .fw {
                    grid-column: span 2;
                }

                .text-container {
                    height: calc(100% - 100px);
                }

                .text-center {
                    position: relative;
                    top: 3%;
                    width: 70%;
                    margin: 0 auto;
                    text-align: center;
                }
            `}</style>
        </div>
    )
}

const SubscribeInputSection = observer(() => {
    const inputRef = useRef();
    const {uiStore: {setEmailSubmitted, email__isSubmitted}} = useStore();
    const handleSubmit = () => {
        const input = inputRef.current;
        if (!input) return;

        const email = input.value;
        submitEmailToMailchimpList(email);
        setEmailSubmitted();
    }

    return (
        <div className={email__isSubmitted ? "input-section submitted" : "input-section"}>
            <input 
                ref={inputRef}
                className="input text-s" 
                type="text" 
                placeholder="Your@email.com"
            />

            <div className={email__isSubmitted ? "button flex--center submitted" : "button flex--center"} onClick={handleSubmit}>
                <h6 className="text-s">{email__isSubmitted ? "Submitted" : "Subscribe"}</h6>
            </div>

            <style jsx>{`
                .input-section {
                    position: absolute;
                    bottom: 0;
                    height: 100px;
                    width: 100%;
                    cursor: pointer;
                    background: var(--color--gray-light);
                    
                }


                input {
                    outline: none;
                    border: none;
                    text-align: center;
                    border-top: 1px solid var(--color--gray-light-hover);
                    transition: background .18s ease;
                }

                .input, .button {
                    width: 100%;
                    height: 50px;
                }

                .button {
                    background: #29abe2;
                    transition: background .15s ease;
                }

                .submitted {
                    pointer-events: none;
                    cursor: default;
                }

                .submitted.button {
                    background: var(--color--active-gray);
                }

                .button:hover {
                    background: #2389ad;
                }

                h6 {
                    color: white;
                }
            `}</style>
        </div>
    )
})





export default ShopSubscribeSection