import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../hooks/useStore';

const SearchFilters = observer(() => {
    const {uiStore: {toggleSearchFilter, search__activeFilters}, dataStore: {searchResultsTotals}} = useStore();
    return (
        <div className='search-filters'>
            <div className='flex'>
                <ToggleButton active={search__activeFilters["title"] && searchResultsTotals['title']} disabled={!searchResultsTotals['title']} onClick={() => toggleSearchFilter("title")} height="50px" width="50px"/>
                <FilterLabel disabled={!searchResultsTotals['title']}>TITLE ({searchResultsTotals['title']})</FilterLabel>
            </div>
            <div className='flex'>
                <ToggleButton active={search__activeFilters["author"] && searchResultsTotals['author']} disabled={!searchResultsTotals['author']} onClick={() => toggleSearchFilter("author")} height="50px" width="50px"/>
                <FilterLabel disabled={!searchResultsTotals['author']}>AUTHOR ({searchResultsTotals['author']})</FilterLabel>
            </div>
            <div className='flex'>
                <ToggleButton active={search__activeFilters["content"] && searchResultsTotals['content']} disabled={!searchResultsTotals['content']} onClick={() => toggleSearchFilter("content")} height="50px" width="50px"/>
                <FilterLabel disabled={!searchResultsTotals['content']} >ARTICLE CONTENT ({searchResultsTotals['content']})</FilterLabel>
            </div>

            <style jsx>{`
                .search-filters {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    height: 50px;
                    background: var(--color--gray-light);
                }
            `}</style>
        </div>
    )
});


const FilterLabel = React.memo(({children, disabled}) => {
    return (
        <div className={`filter__label flex--center ${disabled ? 'disabled' : ''}`}>
            <h6 className='text-xs'>{children}</h6>
            
            <style jsx>{`
                div {
                    flex: 1;
                    text-align: center;
                }

                .disabled h6 {
                    opacity: .2;
                }
            `}</style>
        </div>
    )
})

const ToggleButton = ({active, disabled, onClick, height, width}) => {
    return (
        <div className={`${active ? "active" : "inactive"} ${disabled ? 'disabled inactive' : ''} button flex--center`} onClick={onClick}>
            <div className="circle"></div>

            <style jsx>{`

                    .button {
                        height: ${height};
                        width: ${width};
                        cursor: pointer;
                        transition: background .2s ease;

                    }

                    .disabled {
                        pointer-events: none;
                        opacity: .2;
                    }

                    .inactive.button {
                        background: var(--color--gray-light);
                    }

                    .active.button{
                        background: var(--color--active-gray);
                    }

                    .active .circle {
                        background: rgba(0,0,0,.5);
                        border: 2px solid transparent;
                    }

                    .circle {
                        height: 18px;
                        width: 18px;
                        border-radius: 50%;
                        border: 2px solid rgba(0,0,0, .5);
                    }
            `}</style>
        </div>
    )
}

export default SearchFilters