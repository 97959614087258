export function promiseAllReflect(promises = []) {  
    const reflect = promise => promise.then(
      value => ({ value, status: 'fulfilled' }),
      error => ({ error, status: 'rejected' }),
    );
  
    return Promise.all(promises.map(reflect));
  }
  
  export function executeAtLeast(time, func, funcArgs = []) {  
    return promiseAllReflect([
      new Promise(resolve => setTimeout(resolve, time)), 
      func(...funcArgs)
    ]);
  }

export function checkIsMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
}

export function convertDateString(date) {
  const d = new Date(date);

  return `${d.getMonth() + 1}.${d.getDate()}.${d.getFullYear()}`
}

export async function testSanityShopify() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      title: 'Netlify Test Product',
      handle: 'netlify-test-product',
      id: '12345678890',
      variants: [
        {
          id: '221133',
          price: 15
        }
      ]

    })
  };

  const url = 'http://localhost:8888/.netlify/functions/sanity_shopify_update';

  const resp = await fetch(url, requestOptions);
  // console.log(resp);
}