import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { AudioTime } from '.';
import { useStore } from '../../../../hooks/useStore';
import get from 'lodash/get'

const AudioProgressBar = observer(() => {
    const {audioStore: {seekAudio, audio__activeDetails}} = useStore();
    const color = get(audio__activeDetails, "category.color", "var(--color--gray-lightest)");

    return (
        <div className={`progress ${audio__activeDetails ? "playing" : ""}`} onClick={seekAudio}>
            <ProgressInner/>
            <ProgressInnerBackdrop/>
            <AudioProgressHoverIndicator/>
            <ArticleTitle/>
            <AudioTime/>

            <style jsx>{`
                .progress:hover :global(.progress__inner) {
                    background: ${color};
                }
            `}</style>
            
            <style jsx>{`
                .progress {
                    flex: 1;
                    height: 100%;
                    overflow: hidden;
                    width: 100%;
                    position: relative;
                    background: rgba(255,255,255,.45);
                    backdrop-filter: blur(8px);
                    transform: rotateZ(0);
                    transition: all .14s ease;
                    contain: strict;
                }

                .playing:hover {
                    cursor: pointer;
                }

                @-moz-document url-prefix() { 
                    .progress {
                        background: rgba(255,255,255,.85);
                    }
                }

            `}</style>
        </div>
    )
});

const AudioProgressHoverIndicator = observer(() => {
    const {uiStore: {window, isMobile}, audioStore: {audio__activeDetails}} = useStore();
    const [pos, setPos] = useState(0);
    if (isMobile || !audio__activeDetails) return null

    const updatePos = (e) => {
        let x = e.clientX;
        x = e.clientX - 150; 
        let offsetPct = (x / (window.width - 440));
        setPos(offsetPct)
    }

    const style = {
        transform: `translate3d(${pos * 100}%, 0, 0)`
    }

    return (
        <div 

            onMouseLeave={() => setPos(0)}    
            onMouseMove={e => updatePos(e)}
            className="progress-hover">
            <div 
                style={style}
                className="inner"
            ></div>

            <style jsx>{`
                .inner {
                    background: ${audio__activeDetails.category.color};
                }
            `}</style>
            <style jsx>{`
                div {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 100%;
                    height: 45px;
                }

                 
                .inner {
                    left: -100%;
                    top: 0;
                    transition: transform .12s ease .05s, opacity .2s ease;
                    transition-delay: ;
                    opacity: 0;
                }

                .progress-hover:hover .inner{
                    opacity: 1;
                }
            `}</style>
        </div>
    )
})

const ArticleTitle = observer(() => {
    const {audioStore: {audio__activeDetails}, uiStore: {window: {width}}} = useStore();
    const title = get(audio__activeDetails, "title", "");

    return (
        <div className="title-section flex--center-y">

            <h6 className="text-s">{title}</h6>

            <style jsx>{`
                h6 {
                    width: ${width - 100 - 440}px;
                }

                @media (max-width: 768px), (pointer: coarse) {
                    h6 {
                        width: ${width - 100}px;

                    }
                } 
            `}</style>
            <style jsx>{`
                div {
                    position: absolute;
                    padding: 0 12px;
                    top: 50%;
                    transform: translateY(-50%) translateY(2px);
                    pointer-events: none;
                }

                h6 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;  
                }

                @media (max-width: 768px), (pointer: coarse) {
                    h6 {
                        font-size: 11px;
                    }
                } 
            `}</style>
        </div>
    )
})

const ProgressInnerBackdrop = React.memo(() => {
    return (
        <div>
            <style jsx>{`
                div {
                    position: absolute;
                    top: 0;
                    z-index: 0;
                    width: 100%;
                    height: 5px;
                    background: white;
                }
            `}</style>
        </div>
    )
})

const ProgressInner = observer(() => {
    const {audioStore: {audio__activeDetails, audio__progress}} = useStore();
    if (!audio__activeDetails) return null;

    const style = {
        transform: `translateX(${audio__progress * 100}%)`
    }

    return(
        <div 
            className="progress__inner"
            style={style}
        >
            

            <style jsx>{`
                .progress__inner {
                    background: ${audio__activeDetails.category.color};
                }
            `}</style>

            <style jsx>{`
                .progress__inner {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: -100%;
                    width: 100%;
                    height: 5px;
                    transition: background .14s ease;
                }
            `}</style>
        </div>
    )
})


export default AudioProgressBar