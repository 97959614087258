
export const submitEmailToMailchimpList = async (email) => {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 
    //       'Content-Type': 'application/json',
    //       'Access-Control-Allow-Origin': '*', 
    //       'Access-Control-Allow-Credentials': 'true' 
    //     },
    //     body: JSON.stringify({ 
    //       email
    //     })
    //   };
    
    //   const url = '/.netlify/functions/mailchimp_email_subscribe';
    //   console.log("sending live", email, url)
    //   const resp = await fetch(url, requestOptions);
    //   console.log(resp);


      // const url = "https://Theentireworld.us17.list-manage.com/subscribe/post-json?u=375667f30b4f16f290069d326&amp;id=ee67afd8eb&c=?"
      // const url = "https://otbirds.us8.list-manage.com/subscribe/post-json?u=edbe87bb3809ac9540a17cec3&amp;id=6076e050bb&c=?";
      const url = "https://otbirds.us8.list-manage.com/subscribe/post-json?u=edbe87bb3809ac9540a17cec3&amp;id=4fc9f912f7&c=?"
      // const url = gender === "Men" ? mensList : womensList;

      
      return fetch(`${url}&EMAIL=${email}`, {
        mode: 'no-cors',
      }).then((resp) => {
        // console.log("added to mailing list...", resp)
      });


};


{/* <div id="mc_embed_signup">
<form action="https://otbirds.us8.list-manage.com/subscribe/post?u=edbe87bb3809ac9540a17cec3&amp;id=4fc9f912f7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	
<div class="mc-field-group">
	<label for="mce-EMAIL">Email Address </label>
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_edbe87bb3809ac9540a17cec3_4fc9f912f7" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);</script> */}



// _addEmailToMailingList(email) {
//   const mensList = "https://Theentireworld.us17.list-manage.com/subscribe/post-json?u=375667f30b4f16f290069d326&amp;id=ee67afd8eb&c=?"
//   const womensList = "https://Theentireworld.us17.list-manage.com/subscribe/post-json?u=375667f30b4f16f290069d326&amp;id=6fd1b00178&c=?"
  
//   const url = gender === "Men" ? mensList : womensList;

  
//   fetch(`${url}&EMAIL=${email}`, {
//     mode: 'no-cors',
//   }).then((resp) => {
//     console.log("added to mailing list...")
//   });
  
// }


// <style type="text/css">
// </style>
// <div id="mc_embed_signup">
// <form action="https://otbirds.us8.list-manage.com/subscribe/post?u=edbe87bb3809ac9540a17cec3&amp;id=6076e050bb" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
//     <div id="mc_embed_signup_scroll">
	
// <div class="mc-field-group">
// 	<label for="mce-EMAIL">Email Address </label>
// 	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
// </div>
// 	<div id="mce-responses" class="clear">
// 		<div class="response" id="mce-error-response" style="display:none"></div>
// 		<div class="response" id="mce-success-response" style="display:none"></div>
// 	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
//     <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_edbe87bb3809ac9540a17cec3_6076e050bb" tabindex="-1" value=""></div>
//     <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
//     </div>
// </form>
// </div>
