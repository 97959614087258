import React from 'react'
import { useStore } from '../../../hooks/useStore'

const CheckoutButton = () => {
    const {cartStore: {checkout}} = useStore();

    return (
        <a 
            onClick={(e) => {
                e.stopPropagation();
                // e.preventDefault();
            }}
            href={checkout.webUrl}>
            <div className="flex--center">
                <h3 className="text-s">Checkout</h3>

                <style jsx>{`
                    div {
                        background: #38d430;
                        height: 100%;
                        cursor: pointer;
                        transition: background .15s ease;
                    }

                    div:hover {
                        background: #43af0b;
                    }
                `}</style>
            </div>
        </a>
    )
}

export default CheckoutButton