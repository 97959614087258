import { observer } from 'mobx-react-lite'
import React from 'react'
import { ImageModalCarouselControls } from '.';
import { useStore } from '../../../hooks/useStore'
import ImageModalCarousel from './ImageModalCarousel';

const ImageModal = observer(() => {
    const {uiStore: {imageModal__isOpen, window}, routerStore: {current}, dataStore: {data: {articleView}, dataReady: {articleView: dataReady}}} = useStore();
    if (!imageModal__isOpen) return null

    const slug = current.params.id;
    const data = articleView[slug];

    

    return (
        <div>
            <ImageModalCarouselControls data={data}/>
            {data.images && data.images.length ? <ImageModalCarousel data={data}/> : <NoImagesError/>}

            <style jsx>{`
                div {
                    position: fixed;
                    z-index: 100;


                    left: 0;
                    width: 100%;
                    background: rgba(255,255,255, .7);
                    backdrop-filter: blur(10px);

                    top: 0;
                    bottom: 50px;    
                    animation: fade-in .4s ease;                
                }

                @keyframes fade-in {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }

                @-moz-document url-prefix() { 
                    div {
                        background: rgba(255,255,255, .93);
                    }
                }
            `}</style>
        </div>
    )
})
 
const NoImagesError = () => {
    return (
        <h5>
            No Images Found
        </h5>
    )
}


export default ImageModal 