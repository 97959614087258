import { observer } from 'mobx-react-lite';
import React from 'react'
import {ShopSubscribeSection } from '.';
import { useStore } from '../../../hooks/useStore';
import ShopItem from './ShopItem'

const ShopListItems = observer(() => {
    const {dataStore: {dataReady: {products: ready}, data: {products}}} = useStore();
    return (
        <div>
            {products.map((data) => {
                return <ShopItem data={data}/>
            })}
            
            <ShopSubscribeSection fullWidth={products.length % 2 === 0}/>
            {/* {products.length % 2 === 0 ? <ShopRelatedArticlesSection/> : null} */}
            <style jsx>{`
                div {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        grid-template-columns: 1fr;
                        display: block;
                    }
                }
            `}</style>
        </div>
    )
})

export default ShopListItems