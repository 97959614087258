import React from 'react'
import { ShopItemImageCarousel } from '.'
import { ShopItemControls } from './shop-item-ui'
const ShopItem = (props) => {
    return (
        <div>
            <ShopItemImageCarousel {...props}/>
            <ShopItemControls {...props}/>
            

            <style jsx>{`
                div {
                    height: 550px;
                    height: 650px;
                    position: relative;
                }
            `}</style>
        </div>
    )
}


export default ShopItem