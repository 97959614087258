import React from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks/useStore'

const FooterCategoryFilters = observer(() => {
    const {dataStore: {data:{articleCategories}, dataReady: {articleCategories: dataReady}}} = useStore();
    if (!dataReady) return null;

    return (
        <div className="category__filters">
            {articleCategories.map((data, idx) => {
                return <FilterSection key={`fs-${idx}`} idx={idx} data={data}/>
            })}

            <style jsx>{`
                div {
                    display: grid;
                    height: 100px;
                    grid-template-columns: repeat(4, 1fr);
                    background: var(--color--gray-lightest);
                }

                @media (pointer: coarse), (max-width: 768px) {
                    div {
                        grid-template-columns: repeat(2, 1fr);
                        height: 250px;
                    }
                }
            `}</style>
        </div>
    )
})

const FilterSection = (props) => {

    return (
        <div className="filter-section">
            <FilterToggle {...props}/>
            <FilterLabel {...props}/>

            <style jsx>{`
                div {
                    display: grid;
                    grid-template-columns: 50px 1fr;
                }

            `}</style>
        </div>
    )
}       

const FilterToggle = observer(({data, idx}) => {
    const {uiStore: {toggleHeaderFilter, header__filter__inactiveFilters}} = useStore(); 

    return (
        <div className={`filter__toggle ${idx < 5 ? "top" : "bot"} ${!!header__filter__inactiveFilters[data.title] ? "active" : "inactive"}`}>

            <div className="button flex--center" onClick={() => toggleHeaderFilter(data.title, !header__filter__inactiveFilters[data.title])}>
                <div className="circle"></div>
            </div>


            <style jsx>{`
                .button {
                    height: 100%;
                    cursor: pointer;
                    transition: background .2s ease;
                }

                .inactive .button {
                    background: var(--color--gray-light);
                }

                .active .button{
                    background: var(--color--active-gray);
                }

                .active .circle {
                    background: rgba(0,0,0,.5);
                    border: 2px solid transparent;
                }

                .circle {
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                    border: 2px solid rgba(0,0,0, .5);
                }
            `}</style>
        </div>
    )
})

const FilterLabel = ({data,idx}) => {
    return (
        <div className={`filter__label flex--center ${idx < 5 ? "top" : "bot"}`}>
            <h6>{data.title}</h6>
            
            <style jsx>{`
                div {
                    background: ${data.color};
                    text-align: center;
                }
            `}</style>
        </div>
    )
}



export default FooterCategoryFilters