import SanityBlockContent from '@sanity/block-content-to-react';
import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../hooks/useStore';
import { imageBuilder } from '../../../utils/sanity/image-builder';
import { textSerializers } from '../../../utils/sanity/text-serializer';
import Image from '../../_pages/general/Image';

const ImageModalCarousel = observer(({data}) => {
    const {uiStore: {window, imageModal__carousel__activeIdx}} = useStore();
    const images = data.images;

    const transformStyle = {
        transform: `translate3d(${imageModal__carousel__activeIdx * window.width * -1}px, 0, 0)`
    };

    
    return (
        <div className="carousel">
            <div 
                className="carousel__inner flex"
                style={transformStyle}>
                {images.map(item => {
                    if (item.image) {
                        const w = item.image.asset.aspectRatio < 1 ? 500 : 1000;
                        return (
                            <ImageModalSection>
                                <div>
                                    <img src={imageBuilder(item.image.asset.url).width(w).quality(85).auto("format")} alt="" />
                                    <SanityBlockContent blocks={item.caption} serializers={textSerializers} />
                                </div>
                            </ImageModalSection>
                        )
                    } else if (item.asset) {
                        const w = item.asset.aspectRatio < 1 ? 500 : 1000;

                        return (
                            <ImageModalSection>
                                <img src={imageBuilder(item.asset.url).width(w).quality(85).auto("format")} alt="" />
                            </ImageModalSection>
                        )
                    }
                })}
            </div>

            <style jsx>{`

                .carousel__inner :global(p) {
                    font-size: 12px;
                    margin-top: 5px;
                    margin-bottom: 0;
                }

                .carousel {
                    position: relative;
                    height: 100%;
                    width: ${window.width}px;
                    animation: slide-in .6s ease;
                    animation-delay: .45s;
                    animation-fill-mode: forwards;
                    opacity: 0;
                    transform: translate3d(0, 20px, 0);
                }

                .carousel__inner {
                    height: 100%;
                    transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1);
                    width: ${images.length * window.width}px;
                    padding-top: 50px;
                }

                img {
                    max-height: ${window.height * .7}px;
                    max-width: ${window.width * .7}px;
                }

                @keyframes slide-in {
                    from {
                        opacity: 0;
                        transform: translate3d(0, 20px, 0);
                    }
                    to {
                        opacity: 1;
                        transform: translate3d(0, 0px, 0);
                    }
                }

            `}</style>
        </div>
    )
});



const ImageModalSection = observer(({children}) => {
    const {uiStore: {window}} = useStore();

    return (
        <div className="flex--center">
            {children}

            <style jsx>{`
                div {
                    width: ${window.width}px;    
                }
            `}</style>
        </div>
    )

})



export default ImageModalCarousel