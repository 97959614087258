import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import { convertDateString } from '../../../utils/general';
import { AudioPlayerReadLink } from '../audio/subcomponents';
import { Link } from '../link';
import SearchCloseIcon from './SearchCloseIcon';


const SearchResults = observer(() => {
    const {uiStore: {footer__showSearch}, dataStore: {dataReady: {searchResults : searchResultsQueryStr}}} = useStore();
    if (!footer__showSearch) return null


    return (
        <div>
            {searchResultsQueryStr ? <ResultsList/> : <SearchPrompt/>}
            <style jsx>{`
                div {
                    position: fixed;
                    z-index: 1;


                    left: 0;
                    width: 100%;
                    background: rgba(255,255,255, .7);
                    backdrop-filter: blur(10px);

                    top: 70px;
                    bottom: 0;
                    padding-bottom: 150px;    
                    animation: fade-in .4s ease;   
                    animation-delay: .5s;
                    opacity: 0;
                    animation-fill-mode: forwards;             
                    overflow: scroll;
                }

                @keyframes fade-in {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }

                @media (max-width: 769px), (pointer: coarse) {
                    div {
                        top: 100px;
                    }
                }
            `}</style>
        </div>
    )
});

const SearchPrompt = () => {
    const {uiStore: {focusSearchInput}} = useStore();
    return (
        <div>
            <h2 className='text-xl' onClick={focusSearchInput}>What are you looking for?</h2>

            <style jsx>{`
                div {
                    padding: 12px;
                    height: 70px;
                }

                h2 {
                    color: var(--color--gray);
                    cursor: pointer;
                }
            `}</style>
        </div>
    )
}


const SearchResultLabel = React.memo(({children, idx, active, background = "white"}) => {
    return (
        <div className={`${active ? '' : 'inactive'}`}>
            <span>
                {children}
            </span>
            

            <style jsx>{`
                div {
                    top: ${idx * 43 + 70}px;
                    background: ${background};
                }
                @media (max-width: 769px), (pointer: coarse) {
                    div {
                        top: ${idx * 43}px;
                    }
                }
            `}</style>
            <style jsx>{`
                div {
                    padding 12px;
                    // background: white;
                    height: 43px;
                    position: sticky;
                    z-index: 15;
                }

                .inactive span{
                    opacity: .2;
                }

            `}</style>
        </div>
    )
});

const TotalResults = observer(({haveZeroResults}) => {
    const {uiStore: {isMobile}, dataStore: {data: {searchResults}, dataReady: {searchResults : searchQuery}}} = useStore();
    const numResults = searchResults.byTitle.length + searchResults.byAuthor.length + searchResults.byContent.length;
    if (isMobile) return null
    return (
        <div className={haveZeroResults ? 'no-results' : ''}>
            <h3 className='text-xl'>
                {numResults} results containing <QueryStringButton searchQuery={searchQuery}/>
            </h3>
            <style jsx>{`
                div {
                    background: white;
                    padding: 12px;
                    height: 70px;
                    position: sticky;
                    top: 0;
                    z-index: 10;
                }

                .no-results {
                    background: transparent;
                }

                h3 {
                    color: var(--color--gray);
                }
            `}</style>
        </div>
    )
});

const QueryStringButton = ({searchQuery}) => {
    const {uiStore: {clearSearchQuery}} = useStore();
    return (
        <span className="query">
            {searchQuery}

            <div className="close flex--center" onClick={clearSearchQuery}>
                <SearchCloseIcon height={14}/>
            </div>
            <style jsx>{`
                .query {
                    display: inline-block;
                    background: var(--color--active-gray);
                    color: white;
                    padding: 0px 8px;
                    position: relative;
                }

                .close {
                    position: absolute;
                    height: 100%;
                    width: 45px;
                    background: black;
                    right: -45px;
                    top: 0;
                    cursor: pointer;
                }
            `}</style>
        </span>
    )
}
const ResultsList = observer(() => {
    const {uiStore: {search__activeFilters},dataStore: {searchResultsTotals, data: {searchResults}}} = useStore();

    const titleResults = search__activeFilters['title'] ? searchResults.byTitle : [];
    const authorResults = search__activeFilters['author'] ? searchResults.byAuthor : [];
    const contentResults = search__activeFilters['content'] ? searchResults.byContent : [];

    const haveZeroResults  = searchResultsTotals.title + searchResultsTotals.author + searchResultsTotals.content === 0;

    // console.log(searchResultsTotals)

    return (
        <div className='search-results'>
            <TotalResults haveZeroResults={haveZeroResults} distance={70}/>
            {
                haveZeroResults ? 
                null :
                <React.Fragment>
                    <SearchResultLabel background="var(--color--gray-light)" active={search__activeFilters['title'] && searchResultsTotals['title']} idx={0}>Title ({searchResultsTotals.title})</SearchResultLabel>
                    {titleResults.map(item => {
                        return <ResultColorBlock data={item}>{item.title}</ResultColorBlock>
                    })}

                    <SearchResultLabel active={search__activeFilters['author'] && searchResultsTotals['author']} idx={1}>Author ({searchResultsTotals.author})</SearchResultLabel>
                    {authorResults.map(item => {
                        return <ResultColorBlock data={item}>{item.title}</ResultColorBlock>
                    })}

                    <SearchResultLabel background="var(--color--gray-light)" active={search__activeFilters['content'] && searchResultsTotals['content']} idx={2} >Article Content ({searchResultsTotals.content})</SearchResultLabel>
                    {contentResults.map(item => {
                        return <ResultColorBlock data={item}>{item.title}</ResultColorBlock>
                    })}
                </React.Fragment>

            }

        </div>
    )
})

const ResultColorBlock = observer(({data, children}) => {
    const {audioStore: {audio__activeDetails, playArticle}, dataStore: {dataReady: {searchResults: searchStr}}} = useStore();


    const hasData = !!data.audioFile;

   
    // const isInactive = footer__showFilters && !!!header__filter__inactiveFilters[data.category.title];
    let isPlayingThis = false;

    if (audio__activeDetails && data) {
        isPlayingThis = audio__activeDetails.title === data.title;
    }

    let onClick;
    if (!hasData) {
        onClick = null;
    } else if (isPlayingThis) {
        onClick = null;
    } else {
        onClick = () => playArticle(data);
    }

    const {category: {color}} = data;

    return (
        <div className='result'>
            <div className="article-button-controls">
                <AudioPlayerReadLink data={data}/>

            </div>
            <div className="result__inner" onClick={onClick}>
                <div className="title flex--center-y text-m">
                    <HighlightedText text={searchStr}>{children}</HighlightedText>
                </div>
                
                <div className="category flex--center-y text-s">
                    {data.category.title}
                </div>

                <div className="date flex--center-y text-s">
                    {convertDateString(data.publishedAt)}
                </div>
            </div>


            <style jsx>{`
                .result {
                    height: 100px;
                    padding: 12px 12px;
                    background: ${color};
                    cursor: pointer;
                    position: relative;
                    transition: transform .2s ease;
                    transition-delay: .2s;

                }

                .result:hover {
                    transform: translateX(70px);
                }

                
                .article-button-controls {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    height: 100%;
                    width: 70px;
                    background: ${data.category.secondaryColor};
                    position: absolute;
                    top: 0;
                    left: -70px;
                }

                .article-button-controls :global(a) {
                    width: 100%;
                    height: 100%;
                }

                .result__inner {
                    height: 100%;
                    display: grid;
                    grid-template-columns: 1fr 300px 100px;
                    position: relative;
                }

                .result :global(.highlight) {
                    background: rgba(0,0,0,.5);
                    // padding: 4px;
                }

                @media (max-width: 769px), (pointer: coarse) {
                    .title {
                        position: relative;
                        z-index: 10;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
    
                        margin-right: 60px;
                    }

                    .date {
                        position: absolute;
                        top: 16px;
                        right: 12px;
                        z-index: 10;
                        color: rgba(0,0,0,.5);
                        font-size: 11px;
                    }

                    .category {
                        z-index: 1;
                    
                        position: absolute;
                        bottom: 12px;
                        color: rgba(0,0,0,.5);
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        transition: transform .3s ease;
                        font-size: 11px;
                    }
                }


                @media (max-width: 769px), (pointer: coarse) {
                    .result, .result__inner {
                        font-size: var(--font-size-s);
                        display: block;
                    }
                    .result {
                        padding: 0;
                    }

                    .result__inner {
                        padding: 12px;
                    }

                    .result__inner :global(.title) {
                        font-size: var(--font-size-s);
                    }

                }

                
            `}</style>
        </div>

    )
});

const HighlightedText = React.memo(({children, text}) => {

    var allText = children
    var index = allText.toLowerCase().indexOf(text.toLowerCase());
    let partOne, partTwo, partThree
    // console.log(index, text)

    if (index >= 0) { 
        // allText = allText.substring(0,index) + "<span class='highlight'>" + allText.substring(index,index+text.length) + "</span>" + allText.substring(index + text.length);
    //  inputText.innerHTML = innerHTML;
        partOne = allText.substring(0,index);
        partTwo = allText.substring(index,index+text.length);
        partThree = allText.substring(index + text.length)
        return (
            <span>
                {/* {children} */}
                {partOne}
                <span className='highlight'>{partTwo}</span>
                {partThree}
            </span>
        )
    } else {
        return (
            <span>{children}</span>
        )
    }
})

export default SearchResults