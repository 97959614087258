import React from 'react'
import { useStore } from '../../../../hooks/useStore'


const AddToCartButton = ({data}) => {
    const {cartStore: {addToCart}} = useStore();

    return (

        <div 
            className="flex--center"
            onClick={() => addToCart(data.shopifyVariantID)}
        >
            <h3 className="text-s">Add to cart</h3>
            <style jsx>{`
                div {
                    background: #38d430;

                    height: 50px;
                    cursor: pointer;
                    transition: background .15s ease;
                }

                div:hover {
                    background: #43af0b;
                }
            `}</style>
        </div>
    )
}

export default AddToCartButton