import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../hooks/useStore'

const TransitionEl = observer(() => {
    const {uiStore: {isMobile, window, homeArticleTransitionColor}, routerStore: {routeTransitioning, showLoader, initialLoad}} = useStore();
    if (isMobile) return null;
    // return null
    // if (initialLoad || !showLoader) return null;
    return (
        <div className={`${routeTransitioning ? "tr s-u" : ""}`}>
            <div className="inner">

            </div>

            <style jsx>{`
                div {
                    height: ${window.height}px;
                    width: 100%;

                    position: absolute;
                    top: 70px;
                    left: 0;
                    z-index: 9;
                    pointer-events: none;
                    // transition-delay: .4s;
                }

                .inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: ${homeArticleTransitionColor};
                    // background: blue;
                    transition: opacity .4s ease;
                    transition-delay: 1s;
                    opacity: 0;
                }


                .tr .inner{
                    opacity: 1;
                }

                // div.s-u {
                //     animation: slide-up .6s cubic-bezier(0.77, 0, 0.175, 1);
                //     animation-fill-mode: forwards;
                //     animation-delay: 1s;
                // }

                // @keyframes slide-up {
                //     from {
                //         transform: translate3d(0,0,0);
                //     }

                //     to {
                //         transform: translate3d(0, ${- (window.height - 68)}px, 0); 
                //     }
                // }
            `}</style>
        </div>
    )
})

// 68 is the height of the 
export default TransitionEl