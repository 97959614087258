import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../../hooks/useStore'

const AudioPlayerControls = observer(() => {
    const {audioStore: {audio__isPlaying, togglePausePlay, fastForwardAudioTrack, rewindAudioTrack, audio__activeFile}} = useStore();

    return (
        <div className="audio-controls flex--center">
            <div className="buttons flex">
                <div 
                    className={!!audio__activeFile ? "btn flex--center" : "btn flex--center disabled"} 
                    onClick={rewindAudioTrack}>
                    <img className="skip-icon skip-icon-rw" src="/images/skip-btn.svg" alt="" />
                </div>

                <div 
                    className="btn flex--center" 
                    onClick={togglePausePlay}>
                    {audio__isPlaying ? <img className="icon play-icon pause-icon" src="/images/pause.svg" alt="" /> : <img className="icon play-icon" src="/images/play-btn.svg" alt="" />}
                </div>

                <div 
                    className={!!audio__activeFile ? "btn flex--center" : "btn flex--center disabled"} 
                    onClick={fastForwardAudioTrack}>
                    <img className="skip-icon" src="/images/skip-btn.svg" alt="" />
                </div>
            </div>

            <VolumeToggle/>


            <style jsx>{`

                .audio-controls {
                    width: 290px;                  
                    height: 100%;
                    contain: strict;
                }

                .skip-icon {
                    height: 11px;
                }

                .skip-icon-rw {
                    left: 11px;
                }

                .skip-icon-rw {
                    transform: rotate(-180deg);
                }

                .play-icon {
                    height: 14px;
                }

                .buttons {
                    flex: 1;
                    height: 100%;
                }

                .btn {
                    position: relative;
                    flex: 1;
                    height: 100%;
                    contain: strict;
                    background: var(--color--gray-lightest);
                    transition: background .18s ease, opacity .18s ease;
                    cursor: pointer;
                }
                
                .btn:hover {
                    background: var(--color--gray-light-hover);
                }

                .disabled {
                    pointer-events: none;
                }

                .disabled img {
                    opacity: .3;
                }

                @media (max-width: 768px), (pointer: coarse) {
                    .audio-controls {
                        width: auto;
                        flex: 1;
                    }

                    .btn:hover {
                        background: var(--color--gray-lightest);
                    }
                }
                
            `}</style>
        </div>
    )
});

const VolumeToggle = observer(() => {
    const {audioStore: {audio__activeFile}, uiStore: {isMobile, footer__showVolumeButtons, hideVolumeButtons, showVolumeButtons}} = useStore()
    if (isMobile) return null
    return (
        <div 
            onMouseEnter={showVolumeButtons}
            onMouseLeave={hideVolumeButtons}
            className={`${!audio__activeFile ? "disabled" : ""} ${footer__showVolumeButtons ? 'showing-buttons' : ""} volume-toggle flex--center`}>
                <svg 
                    viewBox="0 0 23.78 16.69">

                    <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                    <path className="cls-1" d="M12.21.16,6.59,3.41H1.86A1.86,1.86,0,0,0,0,5.27v6.28a1.86,1.86,0,0,0,1.86,1.86H6.8l5.41,3.12a1.19,1.19,0,0,0,1.79-1V1.2A1.2,1.2,0,0,0,12.21.16Z"/>
                    <path className="cls-1" d="M17.17.47A1.05,1.05,0,0,0,16,1.34a1.07,1.07,0,0,0,.88,1.23,5.77,5.77,0,0,1,0,11.39A1.06,1.06,0,0,0,16,15.18a1.07,1.07,0,0,0,1.05.89h.17a7.9,7.9,0,0,0,0-15.59Z"/>
                    <path className="cls-1" d="M16.56,10.39A1.06,1.06,0,0,0,16,11.8a1.08,1.08,0,0,0,1,.63,1.13,1.13,0,0,0,.44-.1,4.46,4.46,0,0,0,0-8.13A1.06,1.06,0,0,0,16,4.72a1.08,1.08,0,0,0,.53,1.42,2.33,2.33,0,0,1,0,4.25Z"/>
                    </g>
                    </g>
                </svg>
                
            <style jsx>{`
                .volume-toggle {
                    width: 50px;
                    height: 100%;
                    cursor: pointer;
                    contain: strict;

                    background: var(--color--gray-lightest);
                    transition: background .18s ease, opacity .18s ease;
                    cursor: pointer;
                }


                .disabled {
                    pointer-events: none;
                }

                .disabled svg{
                    opacity: .3;
                }
                
                .volume-toggle:hover, .showing-buttons {
                    background: var(--color--gray-light-hover);
                }

                svg {
                    height: 12px;
                }

                path {
                    fill: var(--color--gray);
                }

                .active path {
                    fill: var(--color--gray-lightest);
                }
            `}</style>
        </div>
    )
})


export default AudioPlayerControls