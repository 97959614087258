import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../../hooks/useStore';


const AudioTime= observer(() => {
    const {audioStore: {totalTime}} = useStore();
    if (!totalTime) return null

    return (

        <div>
            <h5 className="text-xs"> <CurrentTime/> / {totalTime}</h5>

            <style jsx>{`
                div {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) translateY(2px);
                    right: 12px;
                    pointer-events: none;
                }
            `}</style>
        </div>
    )
});

const CurrentTime = observer(() => {
    const {audioStore: {currentTime}} = useStore();
    return <span className="text-xs">{currentTime}</span>
})

export default AudioTime