import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer';
import { useStore } from '../../../hooks/useStore';


const ArticlePaginationLink = observer(() => {
    const {dataStore: {fetchArticleList, data: {articleListPagination}, dataReady: {allListsLoaded, articleListPagination: dataReady}}} = useStore();
    
    const {ref, inView} = useInView({
        threshold: 0,
        triggerOnce: false
    });

      useEffect(() => {
          if (!inView) return 
          fetchArticleList(articleListPagination.length)
      }, [inView])

    if (allListsLoaded) return null;

    return (
        <div ref={ref} className="flex--center-y">
            <h3 className="text-xl">
                Loading <LoadingTripleDots/>
            </h3>    

            <style jsx>{`
                div {
                    height: 100px;
                    background: var(--color--gray-light);
                    cursor: pointer;
                    padding-left: 12px;
                }
            `}</style>
        </div>
    )
})

const LoadingTripleDots = () => {
    return (
        <span className="loading">
            <span className="dot dot-1 text-xl">.</span>
            <span className="dot dot-2 text-xl">.</span>
            <span className="dot dot-3 text-xl">.</span>

            <style jsx>{`
                .dot {
                    display: inline-block;
                    margin-right: 4px;
                }

                .dot {
                    animation-name: blink;
                    /**
                     * The animation should take 1.4 seconds
                     */
                    animation-duration: 1.9s;
                    /**
                     * It will repeat itself forever
                     */
                    animation-iteration-count: infinite;
                    /**
                     * This makes sure that the starting style (opacity: .2)
                     * of the animation is applied before the animation starts.
                     * Otherwise we would see a short flash or would have
                     * to set the default styling of the dots to the same
                     * as the animation. Same applies for the ending styles.
                     */
                    animation-fill-mode: both;
                }

                .dot-2 {
                    animation-delay: .2s;
                }

                .dot-3 {
                    animation-delay: .4s;
                }
                @keyframes blink {
                    /**
                     * At the start of the animation the dot
                     * has an opacity of .2
                     */
                    0% {
                      opacity: 0;
                    }
                    /**
                     * At 20% the dot is fully visible and
                     * then fades out slowly
                     */
                    50% {
                      opacity: 1;
                    }
                    /**
                     * Until it reaches an opacity of .2 and
                     * the animation can start again
                     */
                    100% {
                      opacity: 0;
                    }
                }
            `}</style>
        </span>
    )
}
export default ArticlePaginationLink