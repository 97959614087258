import { observer } from 'mobx-react-lite';
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import get from 'lodash/get'
const CartLineItems = observer(() => {
    const {cartStore: {checkout, showCartContents}} = useStore(); 
    const lineItems = get(checkout, "lineItems.edges", []);

    return (
        <div className={`cart-line-items ${showCartContents ? 'open' : 'closed'}`}>
            {lineItems.map((item, idx) => {
                return (
                    <LineItem data={item} key={`ca-li-${idx}-${item.node.id}`}/>
                )
            })}
            <LineItem />
            <style jsx>{`
                div {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 0;
                    transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1) .2s;
                    background: var(--color--active-gray);

                }

                .closed {
                    transform: translate3d(0, -${lineItems.length * 25}px, 0);
                }

                @media (max-width: 769px), (pointer: coarse) {
                    .closed {
                        transform: translate3d(0, 0, 0);
                    }
                    .open {
                        transform: translate3d(0, -${lineItems.length * 50}px, 0);
                    }
                }
            `}</style>
        </div>
    )
})

const LineItem = React.memo(({data}) => {
    const {cartStore: {removeLineItemFromCart}, dataStore: {productDataByProductID}} = useStore();

    let product;
    if (data){
        product = productDataByProductID[data.node.variant.product.id]
    }

    return (
        <div className="line-item">
            {
                data ?
                (
                    <>
                        <div className="delete-button flex--center" onClick={() => removeLineItemFromCart(data.node.id)}>
                            <svg  viewBox="0 0 22.13 22.13">
                                <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_2-2" data-name="Layer 2">
                                <line className="cls-1" x1="1.07" y1="1.07" x2="21.07" y2="21.07"/>
                                <line className="cls-1" x1="21.07" y1="1.07" x2="1.07" y2="21.07"/>
                                </g>
                                </g>
                            </svg>
                        </div>
                        <div className="line-item__inner flex--space-between">
                            <h6 className="text-xs">{ data.node.quantity > 1 ? `(${data.node.quantity})` : null}{product.title}</h6>
                        </div>
                        <h6 className="price flex--center">
                            ${product.price}
                        </h6>
                    </>
                )
                :
                null
            }


            <style jsx>{`
                .line-item {
                    color: white;
                    height: 25px;
                    position: relative;
                    transition: background .15s ease;
                }

                .line-item:hover .line-item__inner {
                    transform: translateX(25px);
                }

                .line-item__inner {
                    height: 100%;
                    padding: 6px;
                    width: 100%;
                    transition: transform .2s ease;
                    transition-delay: .2s;
                    position: relative;
                    z-index: 1;
                    background: var(--color--active-gray);
                    cursor: default;
                }

                .price {
                    position: absolute;
                    z-index: 10;
                    right: 0px;
                    padding: 0 6px;
                    top: 50%;
                    height: 100%;
                    background: red;
                    transform: translateY(-50%);
                    background: var(--color--active-gray);
                }

                svg {
                    height: 10px;
                }

                .cls-1{
                    fill: white; 
                    stroke: white;
                    fill:none;stroke:white;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.13px;
                }


                .delete-button {
                    position: absolute;
                    left:0;
                    top: 0;
                    height: 100%;
                    z-index: 0;
                    background: rgb(255,89,90);
                    width: 25px;
                    cursor: pointer;
                    transition: background .15s ease;
                }

                .delete-button:hover {
                    background: #df413b;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    .line-item {
                        height: 50px;
                    }

                    .delete-button {
                        width: 50px;
                    }

                    svg {
                        height: 20px;
                    }

                    .cls-1{
                        fill: white; 
                        stroke: white;
                        fill:none;stroke:white;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.57px;
                    }

                    .line-item:hover .line-item__inner {
                        transform: translateX(50px);
                    }
                }
            `}</style>
        
        </div>
    )
})

export default CartLineItems