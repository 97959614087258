// import { State } from "router5";
import rootStore from "../stores/store";
import { sendAnalyticsPageView } from "../utils/analytics";

// Tell MobX which page we're on
export function mobxRouterPlugin(router) {
  return {
    onTransitionError: (toState) => {
      // TODO handle that.
      
      if (toState === null) {
         toState = {name: '404', params: {}, path: "/404"};
      } 


      rootStore.routerStore.setCurrent(toState);
    },
    onTransitionSuccess: (toState) => {
      const {routerStore, dataStore} = rootStore;
      

      
      // dataStore.fetchRouteData(toState);
      routerStore.setCurrent(toState);
      sendAnalyticsPageView(toState);
    //   sendAnalyticsPageView(toState)
    }
  };
}