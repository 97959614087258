import React from 'react'
import { imageBuilder } from '../../../utils/sanity/image-builder'

const Image = ({src, width = 800, quality = 85, aspectRatio}) => {

    return (
        <div>
            <img src={imageBuilder(src).width(width).quality(quality).auto("format")} alt="" />

            <style jsx>{`
                div {
                    height: 0;
                    padding-bottom: ${(1 / aspectRatio) * 100}%;
                    line-height: 0;
                    contain: strict;
                }

                img {
                    width: 100%;
                    line-height: 0;
                }
            `}</style>
        </div>
    )
}

export default Image