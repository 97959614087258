import BlockContent from '@sanity/block-content-to-react';
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useStore } from '../../../hooks/useStore'
import { imageBuilder } from '../../../utils/sanity/image-builder';
import { textSerializers } from '../../../utils/sanity/text-serializer';
import { Link } from '../link';


const PopUpAd = observer(() => {
    const {uiStore: {showPopUpAd, initPopUpAdCountdown}, dataStore: {data: {general: {popUp}}}} = useStore();
    const showPopup = popUp.showPopup;

    useEffect(() => {
        if (popUp.showPopup) {
            initPopUpAdCountdown()
        }
    }, []);


    if (!showPopUpAd) return null
    console.log(popUp.image);
    return (
        <div className='pop-up'>

            <div className="top-border"></div>
            <CloseButton/>


            {
                popUp.image ? 
                <div className="image-container">
                    <img src={imageBuilder(popUp.image.url).width(1000).quality(85).auto("format").url()} alt="" />
                </div>
                :
                null
            }

            {
                
                popUp.description ? 
                <div className="copy">
                    <BlockContent blocks={popUp.description} serializers={textSerializers}/>
                </div>
                : 
                null

            }


            {
                popUp.cta.showCTA ? 
                <CTALink cta={popUp.cta}/> :
                null
            }

            {/* <span className='cookies text-xs'>
            </span> */}


            <style jsx>{`
                .top-border {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 5px;
                    background: white;
                }

                .cookies {
                    position: absolute;
                    bottom: ${popUp.cta.showCTA ? "44px" : "2px"};
                    left: 2px;
                }
                .image-container {
                    height: 0;
                    width: 100%;
                    padding-bottom: ${(1 / popUp.image.aspectRatio) * 100}%;
                    overflow: hidden;
                    position: relative;
                }

                img {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .pop-up .copy :global(*) {
                    font-size: var(--font-size-m);                   
                }

                .pop-up {
                    width: 500px;
                    padding: 0;
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    box-shadow: 0px 10px 15px 10px #00000045;
                    background: white;
                    background: rgba(255,255,255,.45);
                    backdrop-filter: blur(8px);
                    z-index: 101;
                    padding-bottom: 42px;

                    animation: fade-in .6s ease-out;

                }

                .copy {
                    margin: 58px auto;
                    width: 80%;
                    text-align: center;
                }

                .close {
                    position: absolute;
                    z-index: 2;
                    top: 20px;
                    right: 20px;
                    cursor: pointer;
                }

                @keyframes fade-in {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }

                @media (max-width: 769px), (pointer: coarse) {
                    .pop-up {
                        width: 80%;
                    }
                    .copy {
                        margin: 38px auto 80px ;
                        width: 80%;
                        text-align: center;
                    }
                }
            `}</style>
        </div>  
    )
});

const CTALink = ({cta}) => {
    if (!cta.link || !cta.link[0]) return null

    const link = cta.link[0];
    const type = link._type;
    const text = (
        <div className="btn flex--center">
            <span className='text-s'>{cta.text}</span>

            <style jsx>{`
                .btn {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    padding: 12px 0;
                    text-align: center;
                    cursor: pointer;
                    background: rgba(255,255,255,.45);
                    transition: background .15s ease;
                }

                .btn:hover {
                    background: #38d430;
                }
            `}</style>
        </div>
    )

    if (type === "page") {
        return (
            <Link to={link.link}>
                {text}
            </Link>
        )
    } else if (type === "article") {
        return (
            <Link to="article.view" params={{id: link.slug.current}}>
                {text}
            </Link>
        )
    } else {
        return (
            <Link to={link.url} external>
                {text}
            </Link>
        )
    }
}

const CloseButton = () => {
    const {uiStore: {hidePopUpAd}} = useStore();

    return (
        <div 
            onClick={hidePopUpAd}
            className={true ? "button flex--center active" : "button flex--center"}
        >
            <div className="circle"></div>
            <style jsx>{`
                .button {
                    position: absolute;
                    top: 5px;
                    right: 0;
                    background: white;
                    cursor: pointer;
                    width: 50px;
                    height: 50px;
                    z-index: 1;
                }
        
                .circle {
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                    border: 2px solid rgba(0,0,0, .5);
                    cursor: pointer;
                }
        
                .active {
                    background: var(--color--active-gray);
                }
        
                .active .circle {
                    background: rgba(0,0,0,.5);
                    border: 2px solid transparent;
                }
            `}</style>
        </div>

    )

}

export default PopUpAd