import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore'

const ScrollToTopButton = observer(() => {
    const {dataStore: {dataReady: {allListsLoaded}}, uiStore: {scrollHomeTopSmooth}} = useStore();
    if (!allListsLoaded) return null

    return (
        <div 
        onClick={scrollHomeTopSmooth}
        className="flex--center">
            <h3 className="text-xl">
                Back To The Top
            </h3>


            <style jsx>{`
                div {
                    height: 100px;
                    background: var(--color--gray-light);
                    cursor: pointer;
                    padding-left: 12px;
                }

            `}</style>
        </div>
)})

export default ScrollToTopButton