import React from 'react'

const ShopItemImageCarouselButtons = ({increment, decrement, canIncrement, canDecrement}) => {
    return (
        <React.Fragment>
            <DecrementButton decrement={decrement} canDecrement={canDecrement}/>
            <IncrementButton increment={increment} canIncrement={canIncrement}/>
        </React.Fragment>
    )
}

const IncrementButton = React.memo(({increment, canIncrement}) => {
    return (
        <div 
            className={canIncrement ? "" : "disabled"}
            onClick={increment}
        >
            
            <svg viewBox="0 0 29.36 14.19">
                <path className="cls-1" d="M28.85,6.22l-5.26-3-5.27-3A1,1,0,0,0,16.8,1V5.85H1.25a1.25,1.25,0,0,0,0,2.5H16.8v4.83a1,1,0,0,0,1.52.88l5.27-3,5.26-3A1,1,0,0,0,28.85,6.22Z"/>
            </svg>

            <style jsx>{`
                div {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) translateY(-50px);
                    right: 20px;

                    cursor: pointer;
                    transition: opacity .18s ease;
                    line-height: 0;
                }

                svg {
                    width: 30px;
                }

                path {
                    fill: var(--color--gray);
                }
                
                .disabled {
                    opacity: .3;
                    pointer-events: none;
                }
            `}</style>
        </div>
    )
})

const DecrementButton = React.memo(({decrement, canDecrement}) => {
    return (
        <div 
            className={canDecrement ? "" : "disabled"}
            onClick={decrement}>
            <svg viewBox="0 0 29.36 14.19">
                <path className="cls-1" d="M28.11,5.85H12.56V1A1,1,0,0,0,11,.14l-5.27,3-5.26,3A1,1,0,0,0,.51,8l5.26,3,5.27,3a1,1,0,0,0,1.52-.88V8.35H28.11a1.25,1.25,0,0,0,0-2.5Z"/>
            </svg>

            <style jsx>{`
                div {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) translateY(-50px);
                    left: 20px;
                    // height: 20px;
                    width 20px;
                    cursor: pointer;
                    line-height: 0;
                }
                svg {
                    width: 30px;
                }

                path {
                    fill: var(--color--gray);
                }

                .disabled {
                    opacity: .3;
                    pointer-events: none;
                }
            `}</style>
        </div>
    )
})

export default ShopItemImageCarouselButtons