import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react'
import { useStore } from '../../../../hooks/useStore';


const AudioPlayer = observer(() => {
    const {audioStore: {playNextArticle, audio__activeFile, updateAudioProgress, registerAudioElement}} = useStore();
    const audioRef = useRef(null);

    useEffect(() => {
        if (audioRef && audioRef.current) {
            registerAudioElement(audioRef.current);
        } 
    })

    if (!audio__activeFile) return null;

    return (
        <audio 
            ref={audioRef}
            autoPlay
            onTimeUpdate={updateAudioProgress}
            onEnded={() => playNextArticle()}
            src={audio__activeFile.url} 
            controls>

            <style jsx>{`
                audio {
                    display: none;
                }
            `}</style>
        </audio>
    )
})

export default AudioPlayer