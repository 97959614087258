import React from 'react'
import { AddToCartButton } from '.'

const ShopItemControls = (props) => {
    return (
        <div className="shop-item-controls">  
            <ProductTitle {...props}/>
            <AddToCartButton {...props}/>

            <style jsx>{`
                div {
                    position: absolute; 
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    display: grid;
                    height: 50px;
                    grid-template-columns: 1fr 1fr;
                    // border-top: 1px solid var(--color--gray-light);
                    // border-bottom: 1px solid var(--color--gray-light);
                }
                
                .shop-item-controls :global(h3) {
                    line-height: 1;
                }

                .product-title {
                    // height: 100%;
                }

                @media (pointer: coarse), (max-width: 769px) {
                    div {
                        display: block;
                        height: 100px;
                    }
                }
            `}</style>
        </div>
    )
}

const ProductTitle = ({data}) => {
    return (
        <div className="product-title flex--center">
            <h3 className="text-s">{data.title}</h3>

            <style jsx>{`
                div {
                    height: 50px;
                    background: var(--color--gray-light);
                }
            `}</style>
        </div>
    )
}


export default ShopItemControls