import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../hooks/useStore'
import SearchBar from './SearchBar'
import SearchFilters from './SearchFilters'

const SearchConsole = observer(() => {
    const {uiStore: {search__searchBarActive}} = useStore();

    return (
        <div className='search-console'>
            <SearchBar/>
            <SearchFilters/>
            <style jsx>{`
                div {
                    height: 100px;
                    width: 100%;
                }
            `}</style>
        </div>
    )
})

export default SearchConsole