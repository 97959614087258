const routes = [
	{name: "home", path: "/"},
	{name: "about", path: "/info"},
	{name: "shop", path: "/shop"},
	// {name: "cart", path: "/cart"},
	{name: "article", path: "/article", children: [
		{name: "view", path: "/:id"}
	]},

	{name: "articleOld", path: "/allposts", children: [
		{name: "view", path: "/:id"}
	]},
	{name: "404", path: "/404"}
];

export default routes