const NAMESPACE = "HII-MAG__CHECKOUT_ID";

export function setCheckoutIDLocalStorage(id) {
    window.localStorage.setItem(NAMESPACE, id);
}

export function clearCheckoutIDLocalStorage() {
    window.localStorage.removeItem(NAMESPACE);
}

export function getCheckoutIDLocalStorage() {
    return window.localStorage.getItem(NAMESPACE);
} 


