import React from 'react'
import { useState } from 'react'

const ShopItemImageCarouselDots = ({images, activeIdx, goToIndex}) => {
    

    const allDots = images.concat(["info"]).map((item, idx) => {
        return <Dot onClick={() => goToIndex(idx)}active={idx === activeIdx} isInfo={idx === images.length}/>
    })


    return (
        <div className="carousel-dots flex">
            {allDots}

            <style jsx>{`
                .carousel-dots {
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            `}</style>
        </div>
    )
}

const Dot = React.memo(({active, isInfo, onClick}) => {
    return (
        <div onClick={onClick} className={active ? "active flex--center" : "flex--center"}>
            {isInfo ? <span className="text-xs">i</span> : null}
            <style jsx>{`
                div {
                    height: 14px;
                    width: 14px;
                    border-radius: 50%;
                    border: 1px solid var(--color--active-gray);
                    transition: background .18s ease;
                    margin: 0 4px;
                    cursor: pointer;
                }

                .active {
                    background: var(--color--active-gray);
                }

                span {
                    color: var(--color--active-gray);
                }

                .active span {
                    color: var(--color--gray-lightest);
                }
            `}</style>
        </div>
    )
})

export default ShopItemImageCarouselDots