import { computed, action, makeObservable, observable } from "mobx"
import { clearCheckoutIDLocalStorage, getCheckoutIDLocalStorage, setCheckoutIDLocalStorage } from "../utils/shopify/checkoutLocalStorage";
import { graphqlApiCall } from "../utils/shopify/graphqlApiCall";
import get from 'lodash/get'

class CartStore {
    constructor(rootStore) {
        makeObservable(this);

        this.rootStore = rootStore;

        // this.createOrFetchCheckout()
    }

    @observable checkout = null;
    @observable addingToCart = false;
    @observable showCartContents = false;

    priceAnimationIdx = 0;
    startPriceAnimation = 0;
    @observable subTotalPrice = 0;

    @computed get numCartProducts() {
        const lineItems = get(this.checkout, "lineItems.edges");
        if (!lineItems) return 0;

        let sum = 0;

        lineItems.forEach((lineItem) => {
            sum += lineItem.node.quantity;
        });

        return sum;
    }

    @action.bound initSubTotalPriceAnimation() {
        if (!this.checkout || this.animating) return 
        this.animating = true;
        this.priceAnimationIdx = 0;
        this.startPriceAnimation = this.subTotalPrice;

        requestAnimationFrame(this.updateSubTotalPrice);
    }

    @action.bound updateSubTotalPrice() {
		const time = this.priceAnimationIdx;
		const duration = 45;
		const start = this.startPriceAnimation;
		const end = this.checkout.subtotalPrice - start;

		if (time > duration) {

			// if (this.subTotalPrice === 0) {
			// 	this.rootStore.uiStore.closeCart()
			// }
            this.animating = false;
			return
		};
		
		this.subTotalPrice = this.easeInOutQuad(time, duration, start, end);

		this.priceAnimationIdx++;
		requestAnimationFrame(this.updateSubTotalPrice)
 	}

    easeInOutQuad (time, duration, valueStart, valueEnd) {
        return valueEnd*(time/=duration)*time*time + valueStart;

        // if ((time/=duration/2) < 1) return valueEnd/2*time*time + valueStart;
        // return -valueEnd/2 * ((--time)*(time-2) - 1) + valueStart;
    }


    @action.bound async createOrFetchCheckout() {
        const existingCheckoutID = getCheckoutIDLocalStorage()
   
    
        if (existingCheckoutID) {
            const checkout = await this.fetchCheckout(existingCheckoutID);

            if (!checkout || !!checkout.completedAt) {
                clearCheckoutIDLocalStorage();
                return this.createOrFetchCheckout();
            }

            if (checkout) {
                const shopifyProductIDs = checkout.lineItems.edges.map(edge => {
                    return edge.node.variant.product.id
                });
                
                // this.rootStore.dataStore.fetchCheckoutProducts(shopifyProductIDs);
                this.checkout = checkout;
                this.initSubTotalPriceAnimation();
                return 
            }
        }

        const checkout = await this.createCheckout();

        if (checkout) {
            setCheckoutIDLocalStorage(checkout.id)
        }
    }

    @action async createCheckout() {
        const mutation = `mutation {
            checkoutCreate(input: {}){
                checkout {
                    id
                }
            }
        }`

        const response = await graphqlApiCall(mutation);

        this.checkout = response.data.checkoutCreate.checkout;
        return this.checkout;
    }

    @action async fetchCheckout(id) {
        const query = `
            {
                node(id: "${id}") {
                    ... on Checkout {
                        orderStatusUrl,
                        completedAt,
                        id,
                        webUrl,
                        subtotalPrice,
                        totalTax,
                        totalPrice,
                        lineItems(first: 250) {
                            edges {
                                node {
                                    id,
                                    quantity
                                    variant {
                                        product {
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `;
        const response = await graphqlApiCall(query);
        return response.data.node;
    }

    async fetchProduct(formattedID) {
        const query = `{
            nodes(ids: ["${formattedID}"]) {
                id
                ... on Product {
                title,
                availableForSale,
                id,
                variants(first: 10) {
                    edges {
                        node {
                            id,
                            title,
                            price, 
                            available
                                quantityAvailable
                                compareAtPrice
                        }
                    }
                }
                }
            }
        }`;

        const response = await graphqlApiCall(query);
        return response;
    }

    @action.bound updateAddToCartStatus(newValue) {
        this.addingToCart = newValue;
    }

    @action.bound toggleCart() {
        this.showCartContents = !this.showCartContents;
    }

    @action.bound openCart() {
        this.showCartContents = true;
    }

    @action.bound closeCart() {
        this.showCartContents = false;
    }

    @action.bound async addToCart(variantId, quantity = 1) {
        if (!this.checkout || !variantId) return;
        this.updateAddToCartStatus(true);


        const checkoutId = this.checkout.id;

        const mutation = `mutation {
            checkoutLineItemsAdd(lineItems: [{variantId:"${variantId}", quantity: 1}], checkoutId: "${checkoutId}") {
                checkout {
                    id,
                    webUrl,
                    subtotalPrice,
                    totalTax,
                    totalPrice,
                    lineItems(first: 100) {
                        edges {
                            node {
                                id,
                                title,
                                quantity,
                                
                                variant {
                                    product {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`;

        const response = await graphqlApiCall(mutation);
        // console.log(response)
        this.checkout = response.data.checkoutLineItemsAdd.checkout;
        this.initSubTotalPriceAnimation();
        this.updateAddToCartStatus(false);
        this.openCart();

    }

    @action.bound async removeLineItemFromCart(lineItemId) {
        if (!this.checkout || !lineItemId) return;

        const checkoutId = this.checkout.id;

        const mutation = `
            mutation {
                checkoutLineItemsRemove(
                    lineItemIds: ["${lineItemId}"]
                    checkoutId: "${checkoutId}"
                ) {
                    checkout {
                        id
                        webUrl
                        subtotalPrice
                        totalTax
                        totalPrice
                        lineItems(first: 100) {
                            edges {
                                node {
                                    id,
                                    title,
                                    quantity,
                                
                                    variant {
                                        product {
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }
                    checkoutUserErrors {
                        code
                        field
                        message
                    }
                }
            }
        `;
        const response = await graphqlApiCall(mutation);

        if (response.data.checkoutLineItemsRemove.checkout) {
            this.checkout = response.data.checkoutLineItemsRemove.checkout;
            this.initSubTotalPriceAnimation();
            // console.log(response.data.checkoutLineItemsRemove.checkout.lineItems, response.data.checkoutLineItemsRemove.checkout.lineItems.length)
            if (response.data.checkoutLineItemsRemove.checkout.lineItems.edges.length === 0) {
                this.closeCart();
            }
        }
    }
}

export default CartStore